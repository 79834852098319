import CallView from './CallView';
import CallViewCollapsed from './CallViewCollapsed';
import { DateTime } from 'luxon';
import Card from '../../lib/ui/Card';
import Travel from './Travel';

export default function ShipView({ ship, calls, terminals, time, shipLoad, compactMode, selectedCts, setSelectedCts }) {
	return <div className="ship-view">
		<div className="ship-header" style={{ position: 'sticky', top: '4px', zIndex: 1, marginTop: 'var(--u-16)' }}>
			<Card style={{ background: 'rgba(255, 255, 255, 0.95)', backdropFilter: 'blur(4px)', padding: 'var(--u-8) var(--u-16)', borderBottom: '1px solid var(--col-grey-200)', boxShadow: '' }}>
				<h2 className="title-a">{ship.name}</h2>
			</Card>
		</div>

		{calls.sort((a, b) => DateTime.fromISO(a.plannedArrival).diff(DateTime.fromISO(b.plannedArrival), [ "hours" ])).map((call, idx, calls) => <>

			{/* TODO: TRAVEL INFO INBETWEEN CALLS FOR TEU AND GROSS WEIGHT*/}
			{idx < calls.length && <div>
				<Travel key={call.id} ship={ship} prevCalls={calls.slice(0, Math.max(idx, 0))} nextCall={call} shipLoad={shipLoad} />
			</div>}

			{!compactMode
				? <CallView key={call.id} call={call} terminals={terminals} time={time} selectedCts={selectedCts} setSelectedCts={setSelectedCts} />
				: <CallViewCollapsed key={call.id} call={call} terminals={terminals} time={time} selectedCts={selectedCts} setSelectedCts={setSelectedCts} />}
		</>)}

		{/* TODO: TRAVEL INFO INBETWEEN CALLS FOR TEU AND GROSS WEIGHT*/}
		<div>
			<Travel ship={ship} prevCalls={calls.slice(0, Math.max(calls.length, 0))} nextCall={null} shipLoad={shipLoad} />
		</div>
	</div>;
}