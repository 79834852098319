import ShipView from './ShipView';

export default function DayView({ calls, ships, selectedShips, terminals, time, shipsOrder, shipLoad, compactMode, selectedCts, setSelectedCts }) {
	return <div className="day-view">
		<div className="day-view-content">
			{shipsOrder
				.filter(id => Object.values(selectedShips).map(s => s.toString()).includes(id))
				.map(id => ships[id])
				.filter(ship => ship != null)
				.map(ship => <ShipView
					key={ship.id}
					ship={ship}
					terminals={terminals}
					calls={calls.filter(c => c.shipId == ship.id)}
					shipLoad={shipLoad[ship.id]}
					compactMode={compactMode}
					time={time}
					selectedCts={selectedCts}
					setSelectedCts={setSelectedCts} />)}
		</div>
	</div>;
}