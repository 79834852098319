import React, { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import Modal from '../lib/ui/Modal';
import ButtonArea from '../lib/forms/ButtonArea';
import ValidatedButton from '../lib/forms/ValidatedButton';
import TerminalSelectField from '../lib/selectfields/TerminalSelectField';
import ShipSelectField from '../lib/selectfields/ShipSelectField';
import DateTimeField from '../lib/forms/DateTimeField';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, InputField, WithLabel } from '@atrocit/scl';

export default function AddCallModal({ isOpen, onClose, refetch }) {
	const [ terminalId, setTerminalId ] = useState(null);
	const [ shipId, setShipId ] = useState(null);
	const [ callReference, setCallReference ] = useState(null);
	const [ pta, setPta ] = useState(DateTime.now().startOf('day'));
	const [ ptd, setPtd ] = useState(DateTime.now().plus({ days: 1 }).startOf('day'));
	const [ remark, setRemark ] = useState('');

	const [ createCall, createCallMutation ] = useMutation(gql`mutation Mutation($terminalId: Int!, $shipId: Int, $callReference: String, $pta: Instant!, $ptd: Instant!, $remark: String) {
		createCall(terminalId: $terminalId, shipId: $shipId, callReference: $callReference, pta: $pta, ptd: $ptd, remark: $remark) { id }
	}`);

	const process = useCallback(() => {
		createCall({ variables: { terminalId: terminalId, shipId: shipId, callReference: callReference, pta: pta.toUTC().toISO(), ptd: ptd.toUTC().toISO(), remark } }).finally(() => { refetch(); onClose(); });
	}, [ terminalId, shipId, callReference, pta, ptd, remark ]);

	return <Modal isOpen={isOpen} onClose={onClose} title="Nieuwe call">
		{!createCallMutation.loading && <Form onSubmit={process}>
			<WithLabel label="Terminal">
				<TerminalSelectField onChange={id => setTerminalId(id)} value={[]} />
			</WithLabel>
			<WithLabel label="Schip">
				<ShipSelectField onChange={id => setShipId(id)} value={[]} />
			</WithLabel>
			<WithLabel label="Callreferentie">
				<InputField value={callReference} onChange={setCallReference} />
			</WithLabel>
			<WithLabel label="Geplande aankomst (PTA)">
				<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPta(dt) : null)} value={pta} />
			</WithLabel>
			<WithLabel label="Gepland vertrek (PTD)">
				<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPtd(dt) : null)} value={ptd} />
			</WithLabel>
			<WithLabel label="Opmerkingen">
				<InputField onChange={setRemark} value={remark} />
			</WithLabel>
			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: terminalId != null, error: "Vul een terminal in" },
						{ valid: pta != null, error: "Geplande aankomst (PTA) moet ingevuld zijn" },
						{ valid: ptd != null, error: "Gepland vertrek (PTD) moet ingevuld zijn" },
					]}>
					<span className="fa fa-plus" />&nbsp;
					Toevoegen
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{createCallMutation.loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}