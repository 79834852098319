import Breadcrumbs from "../lib/Breadcrumbs";
import { Form, InputField, WithLabel } from "@atrocit/scl";
import React, { useMemo, useState } from "react";
import ButtonArea from "../lib/forms/ButtonArea";
import ValidatedButton from "../lib/forms/ValidatedButton";
import { useNavigate } from "react-router";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

export default function AddEditUser() {
	const navigate = useNavigate();
	const { id } = useParams();
	const editingMode = id != null;
	const [ email, setEmail ] = useState('');
	const [ firstName, setFirstName ] = useState('');
	const [ fullName, setFullName ] = useState('');

	const { data } = useQuery(gql`
		query Query($id: Int!){
			user(id: $id){
				id,
				email,
				firstName,
				fullName,
			}

		}`, { variables: { id } });

	const [ addUser ] = useMutation(gql`mutation Mutation($email: String!, $firstName: String!, $fullName: String!) {
		addUser(email: $email, firstName: $firstName, fullName: $fullName) { id }
	} `);

	const [ editUser ] = useMutation(gql `mutation Mutation($id: Int!, $email: String!, $firstName: String!, $fullName: String!) {
		editUser(id: $id , email: $email, firstName: $firstName, fullName: $fullName) { id }
	}`);

	useMemo(() => {
		if (data != null) {
			setEmail(data?.user.email);
			setFirstName(data?.user.firstName);
			setFullName(data?.user.fullName);
		}
	}, [ data?.user ]);

	return <div className='page'>
		<div className="app-ui">
			<Breadcrumbs
				segments={[
					{ link: '/users', label: 'Gebruikers' },
					{ link: editingMode ? '' : '', label: editingMode ? data?.user.firstName : 'Nieuwe gebruiker' },
				]} />
			<Form
				style={{ width: 'var(--u-768)', justifyContent: 'center', alignItems: 'center', margin: '20px' }}
				onSubmit={() => {
					if (editingMode) {
						editUser({ variables: { id: data?.user.id, email, firstName, fullName } })
							.then(() => navigate('/masterdata/users'))
							.catch(e => console.error("Something went wrong"));
					} else {
						addUser({ variables: { email, firstName, fullName } })
							.then(() => navigate('/masterdata/users'))
							.catch(e => console.error("Something went wrong"));
					}
				}}>
				<WithLabel label={'E-mail'}>
					<InputField type={'email'} onChange={setEmail} value={email}></InputField>
				</WithLabel>
				<WithLabel label={'Voornaam'}>
					<InputField onChange={setFirstName} value={firstName}></InputField>
				</WithLabel>
				<WithLabel label={'Volledige naam (inclusief voornaam)'}>
					<InputField onChange={setFullName} value={fullName}></InputField>
				</WithLabel>

				<ButtonArea>
					<ValidatedButton
						type="submit"
						level="primary"
						validations={[
							{ valid: email.length > 0, error: "Een email mag niet leeg zijn" },
							{ valid: firstName.length > 0, error: " naam mag niet leeg zijn" },
							{ valid: fullName.length > 0, error: "Een volledige naam mag niet leeg zijn" },
						]}>
						<span className="fa fa-check" />&nbsp; Opslaan
					</ValidatedButton>
				</ButtonArea>
			</Form>
		</div>
	</div>;
}