import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from '../../lib/ui/Modal';
import LoadingOverlay from '../../lib/ui/LoadingOverlay';
import { Button, Form, InputField, WarningBanner, WithLabel } from '@atrocit/scl';
import ButtonArea from '../../lib/forms/ButtonArea';
import SelectField from '../../lib/selectfields/SelectField';
import DateTimeField from '../../lib/forms/DateTimeField';
import ValidatedButton from '../../lib/forms/ValidatedButton';

export default function PickupWindowCommand({ containerTransports, onClose }) {
	const [ earliestChangeType, setEarliestChangeType ] = useState('IGNORE');
	const [ latestChangeType, setLatestChangeType ] = useState('IGNORE');

	const [ earliestPickup, setEarliestPickup ] = useState(DateTime.now().startOf('day'));
	const [ latestPickup, setLatestPickup ] = useState(DateTime.now().plus({ days: 1 }).startOf('day'));

	const earliestPickupsAllEqual = useMemo(() => new Set(containerTransports.filter(ct => ct.earliestPickup != null && ct.earliestPickup.length > 0).map(ct => ct.earliestPickup)).size <= 1, [ containerTransports ]);
	const latestPickupsAllEqual = useMemo(() => new Set(containerTransports.filter(ct => ct.latestPickup != null && ct.latestPickup.length > 0).map(ct => ct.latestPickup)).size <= 1, [ containerTransports ]);
	const earliestPickupAfterLatestPickup = useMemo(() => new Set(containerTransports.filter(ct => ct.earliestPickup != null && DateTime.fromISO(ct.earliestPickup) >= latestPickup)).size > 0, [ containerTransports, latestPickup ]);
	const latestPickupBeforeEarliestPickup = useMemo(() => new Set(containerTransports.filter(ct => ct.latestPickup != null && DateTime.fromISO(ct.latestPickup) <= earliestPickup)).size > 0, [ containerTransports, earliestPickup ]);

	const [ updatePickupWindowMutation, { loading } ] = useMutation(gql`mutation Mutation($containerTransports: [ Int! ]!, $earliestPickup: Instant, $latestPickup: Instant, $earliestChangeType: PickupDropoffWindowChangeType!, $latestChangeType: PickupDropoffWindowChangeType!) {
		updatePickupWindow(containerTransports: $containerTransports, earliestPickup: $earliestPickup, latestPickup: $latestPickup, earliestChangeType: $earliestChangeType, latestChangeType: $latestChangeType) { id }
	}`);

	return <Modal title="Uithaalwindows wijzigen" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<div style={{ width: 'var(--u-512)' }}>
			<Form onSubmit={() => updatePickupWindowMutation({ variables: { containerTransports: containerTransports.map(ct => ct.id), earliestPickup: earliestPickup.toUTC().toISO(), latestPickup: latestPickup.toUTC().toISO(), earliestChangeType, latestChangeType } }).then(onClose)}>
				<div>Wijziging van de uithaalwindows voor <span className="bold">{containerTransports.length}</span> container(s)</div>

				<div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 'var(--u-8)', padding: 'var(--u-12) 0' }}>
					{!earliestPickupsAllEqual && earliestChangeType != 'IGNORE' && <WarningBanner>Er worden meerdere vroegste uithaaldatums overschreven</WarningBanner>}
					{!latestPickupsAllEqual && latestChangeType != 'IGNORE' && <WarningBanner>Er worden meerdere laatste uithaaldatums overschreven</WarningBanner>}
					{latestChangeType != 'IGNORE' && earliestPickupAfterLatestPickup && <WarningBanner>De opgegeven laatste uithaaldatum valt vóór de vroegste uithaaldatum van sommige container(s)</WarningBanner>}
					{earliestChangeType != 'IGNORE' && latestPickupBeforeEarliestPickup && <WarningBanner>De opgegeven vroegste uithaaldatum valt na de laatste uithaaldatum van sommige container(s)</WarningBanner>}
				</div>

				<div className="col-2">
					<div>
						<WithLabel label="Wijziging">
							<SelectField
								options={[
									{ value: 'IGNORE', label: 'Behoud bestaande' },
									{ value: 'REMOVE', label: 'Verwijder bestaande' },
									{ value: 'OVERWRITE', label: 'Overschrijf met...' },
								]}
								onChange={opt => setEarliestChangeType(opt?.value)}
								value={earliestChangeType} />
						</WithLabel>

						<WithLabel label="Vroegste uithaalmoment">
							<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setEarliestPickup(dt) : null)}
							               value={earliestPickup}
							               nullable={earliestChangeType != 'OVERWRITE'} />
						</WithLabel>
					</div>
					<div>
						<WithLabel label="Wijziging">
							<SelectField
								options={[
									{ value: 'IGNORE', label: 'Behoud bestaande' },
									{ value: 'REMOVE', label: 'Verwijder bestaande' },
									{ value: 'OVERWRITE', label: 'Overschrijf met...' },
								]}
								onChange={opt => setLatestChangeType(opt?.value)}
								value={latestChangeType} />
						</WithLabel>

						<WithLabel label="Laatste uithaalmoment">
							<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setLatestPickup(dt) : null)}
							               value={latestPickup}
							               nullable={latestChangeType != 'OVERWRITE'} />
						</WithLabel>
					</div>
				</div>

				<ButtonArea>
					<ValidatedButton level="primary" type="submit" validations={[
						{ valid: earliestChangeType != null, error: "Er moet een wijzigingstype voor vroegste uithaalmoment geselecteerd zijn" },
						{ valid: latestChangeType != null, error: "Er moet een wijzigingstype voor laatste uithaalmoment geselecteerd zijn" },
					]}>
						<span className="fa fa-check" />&nbsp;Wijzigen
					</ValidatedButton>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</div>
	</Modal>;
}