import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import Breadcrumbs from '../lib/Breadcrumbs';
import SearchField from '../lib/forms/SearchField';
import FormattedDateTime from '../lib/formatting/FormattedDateTime';
import { LinkButton, Pagination, WithLabel } from '@atrocit/scl';
import LoadingOverlay from '../lib/ui/LoadingOverlay';
import { Link } from 'react-router-dom';
import LocalDateField from '../lib/forms/simple/LocalDateField';
import DateTimeField from '../lib/forms/DateTimeField';
import DateField from '../lib/forms/DateField';
import UserContext from '../../context/UserContext';
import { useIntl } from 'react-intl';

const PAGE_SIZE = 20;

export default function ConnectionsOverview() {
	const user = useContext(UserContext);
	const intl = useIntl();

	const [ query, setQuery ] = useState('');
	const [ page, setPage ] = useState(1);
	const [ startRange, setStartRange ] = useState(DateTime.now().minus({ days: 1 }).startOf('day'));
	const [ endRange, setEndRange ] = useState(DateTime.now().startOf('day').plus({ days: 60 }));

	useEffect(() => {
		if (page != 1) setPage(1);
	}, [ query, startRange, endRange ]);

	// Fetch connections
	const connectionsQuery = useQuery(gql`query Query($query: String, $start: Instant!, $end: Instant!, $offset: Int!, $limit: Int!) {
		connectionsPageable(query: $query, start: $start, end: $end, offset: $offset, limit: $limit) {
            totalElements,
            content {
                id,
	            voyageNumberInbound,
	            voyageNumberOutbound,
                seaship {
	                id,
	                shipName,
	                imoNumber,
	                flag
                },
	            terminal {
		            id,
		            displayName,
                },
	            latestSnapshot {
		            vesselEta,
		            vesselAta,
		            dataSource,
		            createdAt
                },
	            subscriptions {
		            id,
		            user { id }
		            createdAt,
		            lastSent
                }
            }
		}
	}`, { variables: { query, start: startRange.toUTC().toISO(), end: endRange.toUTC().toISO(), offset: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE } });
	const totalElements = connectionsQuery?.data?.connectionsPageable?.totalElements || 0;
	const connections = connectionsQuery?.data?.connectionsPageable?.content || [];

	return <div className="page">
		<Breadcrumbs
			segments={[
				{ link: '/connections', label: 'Connecties' },
				{ link: '/connections', label: 'Vessel Arrivals' },
			]} />

		<SearchField onChange={setQuery} value={query} placeholder="Zoek op schip, terminal, of reisnummer.." />

		<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 'var(--u-24)' }}>
			<WithLabel label="Vanaf">
				<DateField onChange={setStartRange} date={startRange} />
			</WithLabel>
			<WithLabel label="Tot">
				<DateField onChange={setEndRange} date={endRange} />
			</WithLabel>
		</div>
		<br />

		<table className="table table-fw">
			<colgroup>
				<col width="32" />
				<col width="*" />
				<col width="70" />
				<col width="120" />
				<col width="120" />
				<col width="142" />
				<col width="142" />
				<col width="142" />
				<col width="100" />
			</colgroup>
			<thead>
				<tr>
					<th></th>
					<th>Schip</th>
					<th>Terminal</th>
					<th>Inkomend reis</th>
					<th>Uitgaande reis</th>
					<th>ETA</th>
					<th>ATA</th>
					<th>Laatste update</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{connections.map(c => {
					const subscription = c.subscriptions.find(s => s.user?.id == user.id);
					return <tr key={c.id}>
						<td style={{ textAlign: 'center' }}>
							{subscription ? <span className="fa fa-envelope" title={"Geabonneerd op updates sinds " + intl.formatDate(subscription.createdAt, { day: '2-digit', month: '2-digit', year: 'numeric' }) + ' ' + intl.formatTime(subscription.createdAt)} /> : <></>}
							{subscription == null && c.subscriptions.length > 0 && <span className="fa fa-envelope" style={{ color: 'var(--col-grey-300)' }} title="Je bent zelf niet geabonneerd, maar anderen zijn dat wel" />}
						</td>
						<td>{c.seaship?.shipName}</td>
						<td>{c.terminal?.displayName}</td>
						<td>{c.voyageNumberInbound || '-'}</td>
						<td>{c.voyageNumberOutbound || '-'}</td>
						<td>{c.latestSnapshot?.vesselEta == null ? '-' : <FormattedDateTime value={c.latestSnapshot?.vesselEta} />}</td>
						<td>{c.latestSnapshot?.vesselAta == null ? '-' : <FormattedDateTime value={c.latestSnapshot?.vesselAta} />}</td>
						<td><FormattedDateTime value={c.latestSnapshot?.createdAt} /></td>
						<td><LinkButton to={'/connections/' + c.id} className="scl-button scl-button-secondary">Openen &nbsp;<span className="fa fa-angle-right" /></LinkButton></td>
					</tr>;
				})}
			</tbody>
		</table>

		<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)', gap: 'var(--u-24)', alignItems: 'center' }}>
			<div>
				<span><span>Connectie {(page - 1) * PAGE_SIZE + 1} t/m {Math.min((page * PAGE_SIZE), totalElements)} getoond, van {totalElements} totaal</span></span>
			</div>
			<Pagination page={page} setPage={setPage} pageSize={PAGE_SIZE} total={totalElements} />
		</div>
		{connectionsQuery.loading && <LoadingOverlay />}
	</div>;
}