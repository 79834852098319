import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useSubscription } from '@apollo/client';
import ContainerTransportRow from './ContainerTransportRow';
import { useHotkeys } from 'react-hotkeys-hook';
import BlurredZeroValue from '../lib/tables/BlurredZeroValue';
import CommandModule from '../commands/CommandModule';

const bookingFields = `
id,
customer {
	id,
	displayName,
},
bookingNumber,
customerReference,
internalReference,
transports {
	id,
	positionInBooking,
	containerType {
		isoCode, displayName, teu
    },
	containerNumber,
	full,
	activeReefer,
	reeferTemperature,
	
	pickupTerminal {
		id, displayName,
    },
	earliestPickup,
	latestPickup,
	pickupReference,
	
    dropoffTerminal {
        id, displayName,
    },
	earliestDropoff,
	latestDropoff,
	dropoffReference,
	
	actualPickup,
	actualDropoff,
	
	pickupCall {
		id,
		ship {
			id, name
		},
		pta, ptd
	},
	dropoffCall {
		id,
		ship {
			id, name
		},
		pta, ptd
	},
	remark
}`;

function isEqFloat(a, b) {
	return Math.abs(a - b) < 0.00001;
}

export default function ViewBooking() {
	const { id } = useParams();

	const [ startIdx, setStartIdx ] = useState(null);
	const [ selectedCts, setSelectedCts ] = useState(new Set());
	const [ cmdSearchOpen, setCmdSearchOpen ] = useState(false);
	const [ currentCommand, setCurrentCommand ] = useState('');

	const bookingQuery = useQuery(gql`query Query($id: ID!) {
        bookingById(bookingId: $id) {
            ${bookingFields}
        }
    }`, { variables: { id } });

	useSubscription(gql`subscription Subscription($id: ID!) {
		bookingSubscription(bookingId: $id) {
			${bookingFields}
		}
	}`, { variables: { id } });

	const booking = bookingQuery?.data?.bookingById;

	useHotkeys('ctrl+a,command+a', event => {
		event.preventDefault();
		if (booking == null || booking.transports.length == 0) return;

		setStartIdx(0);
		setSelectedCts(new Set(booking.transports.map((_, idx) => idx)));
	}, [ booking ]);

	useHotkeys('esc', event => {
		event.preventDefault();
		setStartIdx(null);
		setSelectedCts(new Set());
	}, []);

	useEffect(() => {
		if(selectedCts.size == 0) setStartIdx(null);
	}, [ selectedCts ]);

	const selectedContainerTransports = useMemo(() => [ ...selectedCts ].map(idx => booking.transports[idx]), [ selectedCts, booking ]);

	if (booking == null) return null;
	return <div className="layered">
		<CommandModule
			containerTransportIds={selectedContainerTransports.map(ct => ct.id)} />

		<div className="topbar">
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
				<div style={{ overflow: 'hidden' }}>
					{booking.bookingNumber}
				</div>
				<div className="separator-dot" />
				<div style={{ overflow: 'hidden' }}>
					{booking.customer?.displayName}
				</div>
				<div className="separator-dot" />
				<div style={{ maxWidth: '500px', overflow: 'hidden' }}>
					{booking.customerReference}
				</div>
			</div>
		</div>

		<div className="page page-fw">
			<div className="booking-summary" style={{ padding: 'var(--u-16) var(--u-16) var(--u-24) var(--u-48)' }}>
				<table className="table" style={{ width: 'var(--u-768)' }}>
					<thead>
						<tr>
							<th className="tbl-align-center" colSpan={8}>Containergroottes</th>
							<th className="tbl-align-center" colSpan={3}>Specials</th>
							<th className="tbl-align-center" colSpan={3}>Status</th>
						</tr>
						<tr>
							<th className="tbl-align-right">Units</th>
							<th className="tbl-align-center" colSpan={2}>20ft</th>
							<th className="tbl-align-center" colSpan={2}>40ft</th>
							<th className="tbl-align-center" colSpan={2}>??ft</th>
							<th className="tbl-align-right">TEU</th>
							<th className="tbl-align-right"><span className="fa fa-snowflake-o" /></th>
							<th className="tbl-align-right">OOG</th>
							<th className="tbl-align-right">DG</th>
							<th className="tbl-align-right"><span className="fa fa-clock-o" /></th>
							<th className="tbl-align-right"><span className="fa fa-refresh" style={{ color: 'var(--col-orange-500)' }} /></th>
							<th className="tbl-align-right"><span className="fa fa-check" style={{ color: 'var(--col-green-500)' }} /></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.length} /></td>

							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => ct.containerType?.teu == 1 && !ct.full).length} /></td>
							<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={booking.transports.filter(ct => ct.containerType?.teu == 1 && ct.full).length} /></td>
							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => ct.containerType?.teu == 2 && !ct.full).length} /></td>
							<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={booking.transports.filter(ct => ct.containerType?.teu == 2 && ct.full).length} /></td>
							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => ct.containerType?.teu != 1 && ct.containerType?.teu != 2 && !ct.full).length} /></td>
							<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={booking.transports.filter(ct => ct.containerType?.teu != 1 && ct.containerType?.teu != 2 && ct.full).length} /></td>

							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.reduce((tot, ct) => tot + (ct.containerType?.teu ?? 0), 0)} /></td>

							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => ct.activeReefer).length} /></td>
							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => false).length} /></td>
							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => false).length} /></td>

							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => ct.actualPickup == null).length} /></td>
							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => ct.actualPickup != null && ct.actualDropoff == null).length} /></td>
							<td className="tbl-align-right"><BlurredZeroValue value={booking.transports.filter(ct => ct.actualPickup != null && ct.actualDropoff != null).length} /></td>

						</tr>
					</tbody>
				</table>
			</div>

			{booking.transports.sort((a, b) => a.id - b.id).map((ct, idx) => {
				const selected = selectedCts.has(idx);
				return <div key={ct.id || ('idx' + idx)} style={{ display: 'flex', gap: 'var(--u-16)', alignItems: 'center' }}>
					<div style={{ width: 'var(--u-32)', textAlign: 'right', color: selected ? 'var(--col-primary-500)' : 'var(--col-grey-500)', fontSize: 'var(--fs-13)' }}>
						{idx + 1}
					</div>
					<div style={{ flex: 1 }}>
						<ContainerTransportRow
							ct={ct}
							selected={selected}
							onSelect={({ deselect, ctrlCmd, shift }) => {
								if (deselect) {
									setSelectedCts(new Set([ ...[ ...selectedCts ].filter(i => i != idx) ]));
								} else if (ctrlCmd && shift && selectedCts.has(idx)) {
									setSelectedCts(new Set([ ...[ ...selectedCts ].filter(i => i != idx) ]));
								} else if (ctrlCmd && shift) {
									setStartIdx(idx);
									setSelectedCts(new Set([ ...selectedCts, idx ]));
								} else if (shift && startIdx == null) {
									setStartIdx(idx);
									setSelectedCts(new Set([ idx ]));
								} else if (shift && startIdx != null) {
									const range = [];
									for (let i = Math.min(idx, startIdx); i <= Math.max(idx, startIdx); i++) {
										range.push(i);
									}
									setSelectedCts(new Set(range));
								}
							}} />
					</div>
				</div>;
			})}
			<div style={{ height: 'var(--u-64)' }} />{/* Spacer for the toolbar */}
		</div>
	</div>;
}