import { gql, useMutation } from '@apollo/client';
import Modal from '../../lib/ui/Modal';
import { useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { Button, Form } from '@atrocit/scl';

export default function DeleteTerminalModal({ isOpen, onClose, terminalId, refetch }) {
	const [ loading, setLoading ] = useState(false);

	const [ deleteTerminal ] = useMutation(gql`mutation Mutation($terminalId: Int!) {
        deleteTerminal(terminalId: $terminalId)
    }`);

	function process() {
		setLoading(true);
		deleteTerminal({ variables: { terminalId: terminalId } }).finally(() => { refetch(); onClose(); });
	}

	return <Modal isOpen={isOpen} onClose={onClose} title="Terminal verwijderen">
		{!loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<p style={{ marginTop: 0 }}>Wilt u deze terminal verwijderen? Deze actie kan niet ongedaan gemaakt worden.</p>
			<ButtonArea>
				<ValidatedButton type="submit" style={{ background: "var(--col-red)", color: "var(--col-white)", borderColor: 'var(--col-red-600)' }}>
					<span className="fa fa-trash" />&nbsp; Verwijderen
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}