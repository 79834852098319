import Breadcrumbs from '../Breadcrumbs';

export default function PageTop({ breadcrumbSegments, children }) {
	return <>
		<div style={{ paddingTop: 'var(--u-16)', paddingBottom: 'var(--u-16)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			{breadcrumbSegments != null && <Breadcrumbs segments={breadcrumbSegments} />}
			<div>
				{children}
			</div>
		</div>
	</>;
}
