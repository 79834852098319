import { Button, Form, InputField, Toggle } from '@atrocit/scl';
import React, { useState } from 'react';
import Modal from '../../lib/ui/Modal';
import ButtonArea from '../../lib/forms/ButtonArea';
import LoadingOverlay from '../../lib/ui/LoadingOverlay';
import { gql, useMutation } from '@apollo/client';
import ContainerNumberField from '../../lib/domain/ContainerNumberField';

/* TODO: at some point in the future we want to break this out to a separate screen, which locks all the information */
export default function FillContainerDetailsCommand({ containerTransports, onClose }) {
	const [ transports, setTransports ] = useState(containerTransports);

	const [ updateContainerDetails, { loading } ] = useMutation(gql`mutation Mutation($updates: [ ContainerTransportDetailsInput! ]!) {
		updateContainerDetails(updates: $updates) { id }
	}`);

	return <Modal title="Containerdetails aanvullen" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<Form onSubmit={() => {
			const updates = transports.map(ct => ({
				id: ct.id,
				containerNumber: ct.containerNumber,
				full: ct.full,
				activeReefer: ct.activeReefer,
				reeferTemperature: (ct.reeferTemperature == null || ct.reeferTemperature == '') ? null : parseFloat(ct.reeferTemperature),
				pickupReference: ct.pickupReference,
				dropoffReference: ct.dropoffReference,
				remark: ct.remark,
			}));
			updateContainerDetails({ variables: { updates } }).then(onClose);
		}}>
			<table className="table">
				<thead>
					<tr>
						<th>Containernummer</th>
						<th>Vol</th>
						<th>Rf Plug</th>
						<th>Reefer Temp.</th>
						<th>Pickup ref./pin</th>
						<th>Dropoff ref./pin</th>
						<th>Opmerking</th>
					</tr>
				</thead>
				<tbody>
					{transports.map((ct, idx) => {
						const replace = newCt => setTransports(t => t.map((originalCt, mapIdx) => (mapIdx == idx ? newCt : originalCt)));
						return <tr key={ct.id}>
							<td>
								<ContainerNumberField value={ct.containerNumber} onChange={cn => replace({ ...ct, containerNumber: cn })} />
							</td>
							<td>
								<Toggle onChange={f => replace({ ...ct, full: f })} value={ct.full} />
							</td>
							<td>
								<Toggle onChange={ar => replace({ ...ct, activeReefer: ar, reeferTemperature: ar ? ct.reeferTemperature : '' })} value={ct.activeReefer} />
							</td>
							<td>
								<InputField disabled={!ct.activeReefer} onChange={tmp => replace({ ...ct, reeferTemperature: tmp })} value={ct.reeferTemperature} />
							</td>
							<td>
								<InputField onChange={pickupReference => replace({ ...ct, pickupReference })} value={ct.pickupReference || ''} />
							</td>
							<td>
								<InputField onChange={dropoffReference => replace({ ...ct, dropoffReference })} value={ct.pickupReference || ''} />
							</td>
							<td>
								<InputField onChange={remark => replace({ ...ct, remark })} value={ct.remark} />
							</td>
						</tr>;
					})}
				</tbody>
			</table>

			<ButtonArea>
				<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp;Opslaan</Button>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}