import Window from './Window';
import FullEmptyIndicator from '../lib/FullEmptyIndicator';
import Checkbox from '../lib/ui/Checkbox';

export default function ContainerTransportRow({ ct, selected, onSelect }) {
	return <div className={"container-transport-row" + (selected ? ' container-transport-row-selected' : '')} onClick={e => {
		e.preventDefault();
		e.stopPropagation();
		if (e.shiftKey) {
			onSelect({ shift: e.shiftKey, alt: e.altKey, ctrlCmd: e.ctrlKey || e.metaKey });
		}
		return false;
	}} onMouseDown={e => {
		if (e.ctrlKey || e.metaKey || e.shiftKey) e.preventDefault();
	}}>
		<div style={{ width: '32px' }}>
			{selected && <Checkbox onChange={() => onSelect({ deselect: true })} value={true} />}
		</div>
		<div style={{ width: '28px', display: 'flex', alignItems: 'center' }}>
			<FullEmptyIndicator value={ct.full} />
		</div>
		<div style={{ width: '150px' }}>
			{ct.containerNumber || '---'}<br />
			<span className="sub">{ct.containerType?.displayName || '??'}</span>
		</div>
		<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
			<div style={{ paddingRight: 'var(--u-8)' }}>
				<span>{ct.pickupTerminal?.displayName || '??'}</span>
			</div>
			<div>
				<span className="sub">{ct.pickupReference ?? '-'}</span><br />
				<span className="sub"><Window earliest={ct.earliestPickup} latest={ct.latestPickup} /></span>
			</div>
		</div>
		<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
			<div style={{ paddingRight: 'var(--u-8)' }}>
				<span>{ct.dropoffTerminal?.displayName || '??'}</span>
			</div>
			<div>
				<span className="sub">{ct.dropoffReference ?? '-'}</span><br />
				<span className="sub"><Window earliest={ct.earliestDropoff} latest={ct.latestDropoff} /></span>
			</div>
		</div>
		<div>
			<ContainerTransportStatus ct={ct} />
		</div>
	</div>;
}

function ContainerTransportStatus({ ct }) {
	if (ct.actualPickup == null) return <span className="fa fa-clock-o" />;
	if (ct.actualDropoff == null) return <span className="fa fa-refresh" style={{ color: 'var(--col-orange-500)' }} />;
	return <span className="fa fa-check" style={{ color: 'var(--col-green-500)' }} />;
}