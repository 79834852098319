export default function ProgressBar({ processed, total }) {
	const percentage = Math.max(Math.min(processed / total * 100, 100), 0);

	let colorBase = 'var(--col-primary-500)';
	if (processed > 0 && processed < total) {
		colorBase = 'var(--col-orange-500)';
	} else if (processed >= total && total != 0) {
		colorBase = 'var(--col-green-500)';
	}

	return <div style={{ paddingTop: 'var(--u-4)' }}>
		<div style={{ height: '12px', backgroundColor: '#fff', position: 'relative', border: '2px solid ' + colorBase, borderRadius: '6px', overflow: 'hidden' }}>
			<div style={{ transition: 'width 300ms ease', width: (isNaN(percentage) ? 0 : percentage) + '%', backgroundColor: colorBase, position: 'absolute', top: 0, bottom: 0, left: 0 }} />
		</div>
		<div style={{ textAlign: 'center', color: 'var(--col-grey-600)', fontSize: 'var(--fs-10)', paddingTop: 'var(--u-4)' }}>
			<span>{processed} / {total}</span>
		</div>
	</div>;
}