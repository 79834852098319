import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';

export default function ShipSelectField({ onChange, value, placeholder = '', ...props }) {
	const shipsQuery = useQuery(gql`query {
		ships {
			id, name
		}
	}`);

	const options = useMemo(() => {
		return (shipsQuery?.data?.ships ?? []).map(s => ({
			value: s.id,
			label: s.name,
			original: s,
		}));
	}, [ shipsQuery?.data?.ships ]);

	const onChangeCb = useCallback((obj) => {
		onChange(obj?.value ?? null, obj?.original);
	}, [ onChange ]);

	return <Select
		{...props}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={shipsQuery.loading}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		value={options.find(o => o.value == value)}
		styles={{ noOptionsMessage: base => ({ ...base, ...noOptionsMessageStyle }) }}/>;
}

const noOptionsMessageStyle = {
	color: "var(--col-grey-600)",
	borderRadius: "var(--u-2)",
	fontSize: "var(--u-14)",
};