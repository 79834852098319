import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Form, WarningBanner, WithLabel } from '@atrocit/scl';
import LoadingOverlay from '../../lib/ui/LoadingOverlay';
import ButtonArea from '../../lib/forms/ButtonArea';
import Modal from '../../lib/ui/Modal';
import SelectConnectionField from '../../lib/selectfields/SelectConnectionField';


export default function ProcessAttachDropoffConnectionCommand({ containerTransports, onClose }) {
	const numberOfDropoffTerminals = new Set(containerTransports.map(ct => ct.dropoffTerminal?.id)).size;
	const numberOfDropoffConnections = new Set(containerTransports.map(ct => ct.dropoffConnection?.id).filter(id => id != null)).size;
	const numberOfNullDropoffConnections = containerTransports.filter(ct => ct.dropoffConnection == null).length;
	const numberOfNonNullDropoffConnection = containerTransports.filter(ct => ct.dropoffConnection != null).length;
	const shouldShowWarningMixedNullConnections = numberOfNullDropoffConnections > 0 && numberOfNonNullDropoffConnection > 0;

	const [ dropoffConnection, setDropoffConnection ] = useState(containerTransports[0]?.dropoffConnection);
	const terminalIds = new Set(containerTransports.map(ct => ct.dropoffTerminal?.id));

	const [ attachDropoffConnectionMutation, { loading } ] = useMutation(gql`mutation Mutation($containerTransports: [ Int! ]!, $dropoffConnection: Int) {
        processAttachDropoffConnection(containerTransports: $containerTransports, dropoffConnection: $dropoffConnection) { id }
    }`);

	return <Modal title="Exportconnectie koppelen" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<div style={{ width: 'var(--u-384)' }}>
			<Form onSubmit={() => attachDropoffConnectionMutation({ variables: { containerTransports: containerTransports.map(ct => ct.id), dropoffConnection: dropoffConnection?.id } }).then(onClose)}>
				<div>Exportconnectie koppelen voor <span className="bold">{containerTransports.length}</span> container<span>{containerTransports.length > 1 ? 's' : ''}</span></div>

				<div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 'var(--u-8)', padding: 'var(--u-12) 0' }}>
					{numberOfDropoffTerminals > 1 && <WarningBanner>Er wordt een nieuwe connectie geselecteerd, maar de containers worden ingeleverd op verschillende terminals</WarningBanner>}
					{numberOfDropoffConnections > 1 && <WarningBanner>Er worden <span className="bold">{numberOfDropoffConnections}</span> verschillende connecties gewijzigd</WarningBanner>}
					{shouldShowWarningMixedNullConnections && <WarningBanner>Let op: <span className="bold">{numberOfNonNullDropoffConnection}</span> container(s) hebben al een connectie, <span className="bold">{numberOfNullDropoffConnections}</span> nog niet. Alle connecties worden overschreven.</WarningBanner>}
				</div>

				<div className="col-1">
					<div>
						<WithLabel label="Exportconnectie">
							<SelectConnectionField terminalIds={terminalIds} onChange={setDropoffConnection} value={dropoffConnection} inOutBound="OUTBOUND" />
						</WithLabel>
					</div>
				</div>

				<ButtonArea>
					<Button level="primary" type="submit"><span className="fa fa-check"/>&nbsp; Koppelen</Button>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</div>
	</Modal>;
}