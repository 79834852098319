import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMagicLink as processMagicLink } from '../../util/api';

export default function UseMagicLink({ refreshAuth }) {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(false);
	const { token } = useParams();

	useEffect(() => {
		if (token == null) return;
		setLoading(true);
		processMagicLink(token)
			.then(() => {
				console.log('Refreshing auth');
				refreshAuth();
			})
			.catch(() => setError(true))
			.finally(() => setLoading(false));
	}, [ token ]);

	if (token == null) return null;

	if (error) {
		return <div>
			Er is iets misgegaan met het inloggen. Wellicht was deze link al gebruikt. <Link to="/login">Probeer het nog eens.</Link>
		</div>;
	} else {
		return <div style={{ textAlign: 'center' }}><span className="fa fa-spinner fa-pulse" /></div>;
	}
}