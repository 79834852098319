import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

const actions = [
	{ shortkey: 'T', name: 'Traject wijzigen', icon: 'fa fa-map-signs', action: 'change-trajectory' },
	{ shortkey: 'D', name: 'Details aanvullen', icon: 'fa fa-sort-numeric-asc', action: 'fill-container-details' },
	{ shortkey: 'P', name: 'Pin/referenties wijzigen', icon: 'fa fa-lock', action: 'change-references' },
	{ shortkey: 'V', name: 'Verwerk vertrek', icon: 'fa fa-refresh', action: 'process-pickup' },
	{ shortkey: 'A', name: 'Verwerk aankomst', icon: 'fa fa-check', action: 'process-dropoff' },
	{ shortkey: '[', name: 'Uithaalwindows wijzigen', icon: 'fa fa-clock-o', action: 'change-windows-pickup' },
	{ shortkey: ']', name: 'Inleverwindows wijzigen', icon: 'fa fa-clock-o', action: 'change-windows-dropoff' },
	{ shortkey: 'I', name: 'Importconnectie koppelen', icon: 'fa fa-caret-up fa-lg', action: 'process-attach-pickup-connection' },
	{ shortkey: 'E', name: 'Exportconnectie koppelen', icon: 'fa fa-caret-down fa-lg', action: 'process-attach-dropoff-connection' },



	// TODO: implement deleting container transports in bulk
	// TODO: implement copy/cloning container transports in bulk
	// TODO: implement updating container type in bulk
	// TODO: implement updating oversizes in bulk
];

export default function CommandSearch({ loading, containerTransports, open, setOpen, onCommand, onClose }) {
	const [ query, setQuery ] = useState('');
	const [ selected, setSelected ] = useState(0);

	const options = useMemo(() => {
		return actions.filter(a => a.name.toLowerCase().includes(query.toLowerCase()));
	}, [ query, actions, selected ]);

	const upHandler = useCallback(event => {
		event.preventDefault();
		setSelected(Math.max(0, selected - 1));
	}, [ selected ]);

	const downHandler = useCallback((event) => {
		event.preventDefault();
		setSelected(Math.min(options.length - 1, selected + 1));
	}, [ selected ]);

	const returnHandler = useCallback(() => {
		onCommand(options[selected].action, options[selected]);
		onClose();
	}, [ options, selected, onCommand ]);

	const escapeHandler = useCallback(() => {
		onClose();
	}, [ onClose ]);

	useEffect(() => {
		if (containerTransports.length == 0) setOpen(false);
	}, [ containerTransports ]);

	useHotkeys('up', upHandler, [ upHandler ]);
	useHotkeys('down', downHandler, [ downHandler ]);
	useHotkeys('return', returnHandler, [ options, selected ]);
	useHotkeys('Escape', escapeHandler, [ onClose ]);

	if (!open) return null;
	return <div className="cmd-search-overlay">
		<div className="cmd-search">
			<div className="cmd-search-summary">
				{!loading && <span className="tag">{containerTransports.length} containers geselecteerd</span>}
				{loading && <span className="tag">Containers worden geladen</span>}
			</div>
			<input
				type="text"
				autoFocus={true}
				placeholder="Commando zoeken..."
				onChange={e => { setQuery(e.target.value); setSelected(0); }}
				value={query}
				onKeyDown={e => {
					if (e.key == 'ArrowUp') {
						upHandler(e);
					} else if (e.key == 'ArrowDown') {
						downHandler(e);
					} else if (e.key == 'Enter') {
						returnHandler(e);
					} else if (e.key == 'Escape') {
						escapeHandler(e);
					}
				}} />
			<div className="cmd-search-results">
				{options.map((a, idx) => <div
					key={idx}
					className={"cmd-search-result" + (idx == selected ? ' cmd-search-result-selected' : '')}
					onMouseOver={() => selected != idx && setSelected(idx)}
					onClick={() => {
						onCommand(a.action, a);
						onClose();
					}}>
					<div className="cmd-search-result-icon">
						<span className={a.icon} />
					</div>
					<div>{a.name}</div>
					<div style={{ flex: 1 }} />
					<div style={{ minWidth: 'var(--u-16)', textAlign: 'center' }}>{a.shortkey}</div>
				</div>)}
			</div>
		</div>
	</div>;
}