import { gql, useLazyQuery, useQuery } from '@apollo/client';
import ConfigurationContext from '../../context/ConfigurationContext';
import { useContext, useEffect } from 'react';
import UserContext from '../../context/UserContext';

export default function ConfigurationInjector({ children }) {
	const user = useContext(UserContext);

	const [ requestConfig, configurationQuery ] = useLazyQuery(gql`{
		configuration {
			id,
			featureFlags
        }
	}`);

	useEffect(() => requestConfig(), [ user, requestConfig ]);

	return <ConfigurationContext.Provider value={configurationQuery?.data?.configuration}>
		{children}
	</ConfigurationContext.Provider>;
}