import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button, DropdownButton, LinkButton } from '@atrocit/scl';
import DeleteUserModal from './DeleteUserModal';
import PageTop from '../lib/ui/PageTop';

export default function UsersOverview() {
	const [ deleteUserId, setDeleteUserId ] = useState(null);
	const [ showDeleteUserModal, setShowDeleteUserModal ] = useState(false);

	const query = useQuery(gql`{
		users {
			id, fullName, email
		}
    }`);
	const users = query?.data?.users ?? [];

	return <div className="page">
		{showDeleteUserModal && <DeleteUserModal isOpen={showDeleteUserModal} onClose={() => setShowDeleteUserModal(false)} userId={deleteUserId} refetch={() => query.refetch()} />}

		<PageTop
			breadcrumbSegments={[
				{ link: '/masterdata/users', label: 'Gebruikers' },
				{ link: '/masterdata/users', label: 'Overzicht' },
			]}>
			<LinkButton to='/masterdata/users/new'><span className="fa fa-plus" />&nbsp; Nieuwe gebruiker</LinkButton>
		</PageTop>

		<table className="table table-fw">
			<colgroup>
				<col width="*" />
				<col width="*" />
				<col width="200" />
			</colgroup>
			<thead>
				<tr>
					<th>Volledige naam</th>
					<th>E-mail</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{users.map(u => <tr key={u.id}>
					<td>{u.fullName}</td>
					<td>{u.email}</td>
					<td>
						<DropdownButton mainAction={<LinkButton to={'/masterdata/users/' + u.id }><Button><span className="fa fa-pencil" />&nbsp; Bewerken</Button></LinkButton>}>
							<Button className="danger" onClick={() => { setDeleteUserId(u.id); setShowDeleteUserModal(true); }}><span className="fa fa-trash-o" />&nbsp; Verwijderen</Button>
						</DropdownButton>
					</td>
				</tr>)}
				{!query.loading && users.length == 0 && <tr>
					<td colSpan={100}>Geen resultaten</td>
				</tr>}
			</tbody>
		</table>

	</div>;
}