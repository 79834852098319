import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, InputField, Pagination, WithLabel } from '@atrocit/scl';
import BlurredZeroValue from '../../lib/tables/BlurredZeroValue';
import FullEmptyIndicator from '../../lib/FullEmptyIndicator';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';
import Popoutable3 from '../../lib/Popoutable3';
import { FormattedDate } from 'react-intl';
import { gql, useMutation, useQuery } from '@apollo/client';
import TimeShow from '../../lib/TimeShow';
import { useHotkeys } from 'react-hotkeys-hook';
import Checkbox from '../../lib/ui/Checkbox';
import BooleanIndicator from '../../lib/tables/BooleanIndicator';
import { Link } from 'react-router-dom';
import DisplayTemperature from '../../lib/DisplayTemperature';
import { createDragPreview } from 'react-dnd-text-dragpreview';
import CopyToClipboardText from '../../lib/CopyToClipboardText';
import { DateTime } from 'luxon';
import SearchField from '../../lib/forms/SearchField';

const PAGE_SIZE = 20;

export default function UnplannedBookings({ selectedDate, terminals, selectedCts, setSelectedCts }) {
	const [ poppedOut, setPoppedOut ] = useState(false);
	const [ sortCriteria, setSortCriteria ] = useState([]);

	const [ page, setPage ] = useState(1);
	const [ query, setQuery ] = useState('');

	const [ startIdx, setStartIdx ] = useState(null);

	const [ unplanCts ] = useMutation(gql`mutation Mutation($containerTransports: [ Int! ]!) {
		unplanContainerTransports(containerTransports: $containerTransports) { id }
	}`);

	const unplannedBookingsQuery = useQuery(gql`query Query($before: Instant!, $query: String, $sorting: [ SortingCriteria ], $offset: Int!, $limit: Int!) {
		unplannedBookings(before: $before, query: $query, sorting: $sorting, offset: $offset, limit: $limit) {
			number,
			size,
			totalPages,
			totalElements,
			hasNext,
			hasPrevious,
			content {
				customer, bookingNr, bookingId, bookingReference, totalContainers, totalTeu, twentyFtFull,
				twentyFtEmpty, fortyFtFull, fortyFtEmpty, otherFtFull, otherFtEmpty, totalOog, totalDangerous,
				totalReefer, weight, fullEmptyStatus, unloadTerminalId, loadTerminalId, amountLoaded, amountUnloaded,
				earliestPickup, latestPickup, earliestDropoff, latestDropoff, ctIds
            }
		}
	}`, { variables: { before: selectedDate.endOf('day').toUTC().toISO(), query: query, sorting: sortCriteria, offset: (page - 1) * PAGE_SIZE, limit: page * PAGE_SIZE }, fetchPolicy: 'network-only' });
	const totalElements = unplannedBookingsQuery?.data?.unplannedBookings?.totalElements;
	const unplannedBookings = unplannedBookingsQuery?.data?.unplannedBookings?.content ?? [];
	const allCtIds = unplannedBookings.map(bk => bk.ctIds.split(',').map(ctId => Number(ctId))).reduce((tot, list) => tot.concat(list), []);
	const selectedCtsToList = [ ...selectedCts ].reduce((tot, list) => tot.concat(list), []);

	useEffect(() => {
		if (page == 1) return;
		setPage(1);
	}, [ query ]);

	useHotkeys('esc', event => {
		event.preventDefault();
		setStartIdx(null);
		setSelectedCts(new Set());
		// setCmdSearchOpen(false);
	}, []);

	useEffect(() => {
		setSelectedCts(new Set());
	}, [ query, sortCriteria ]);

	useEffect(() => {
		if(selectedCts.size == 0) setStartIdx(null);
	}, [ selectedCts ]);

	function findKeyInSortCriteria(key) {
		return sortCriteria.indexOf(sortCriteria.find(sc => sc.key == key));
	}

	function onClickSort(key) {
		if (findKeyInSortCriteria(key) == -1) {
			setSortCriteria([ ...sortCriteria, { key, direction: 'asc' } ]);
		} else {
			const foundIndex = findKeyInSortCriteria(key);
			if (sortCriteria[foundIndex].direction == 'asc') {
				setSortCriteria(sortCriteria.map((c, idx) => (idx == foundIndex ? { key, direction: 'desc' } : c)));
			} else if (sortCriteria[foundIndex].direction == 'desc') {
				setSortCriteria(sortCriteria.filter((_, idx) => idx != foundIndex));
			}
		}
	}

	const [ { isOver, canDrop }, drop ] = useDrop(() => ({
		accept: 'PLANNED_BOOKING',
		drop: (droppedItem) => {
			unplanCts({ variables: { containerTransports: droppedItem } }).finally(() => unplannedBookingsQuery.refetch());
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	return <Popoutable3 open={poppedOut} onClose={() => setPoppedOut(false)}>

		<div style={{ width: 'fit-content', maxHeight: '100%', overflow: 'auto', paddingTop: 'var(--u-16)', paddingBottom: 'var(--u-16)', boxShadow: isOver ? '0px 0px 0 4px inset var(--col-primary-300)' : '' }} ref={drop}>
			<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 var(--u-32)' }}>
				<Button onClick={() => setPoppedOut(!poppedOut)}>
					{!poppedOut && <><span className="fa fa-object-ungroup" />&nbsp; Open tweede scherm</>}
					{poppedOut && <><span className="fa fa-object-group" />&nbsp; Sluit tweede scherm</>}
				</Button>
			</div>

			<div style={{ maxWidth: 'var(--u-768)', padding: 'var(--u-8) var(--u-32)' }}>
				<SearchField
				             onChange={setQuery}
				             value={query}
				             hasResults={unplannedBookings.length != 0}
				/>
				<div style={{ marginTop: '-8px', marginBottom: 'var(--u-16)' }}>
					<span style={{ fontStyle: 'italic' }}>Alle boekingen t/m <FormattedDate weekday="short" day="numeric" month="short" value={selectedDate} /> worden getoond</span>
				</div>
			</div>

			<table className="table table-single-line" style={{ fontSize: '13px', width: 'fit-content' }}>
				<colgroup>
					<col width="28" />
					<col width="100" />
					<col width="50" />
					<col width="50" />
					<col width="65" />
					<col width="80" />

					<col width="35" />{/* 20ft x2 */}
					{/* <col width="35" />*/}
					<col width="35" />
					{/* <col width="35" />*/}
					<col width="35" />
					{/* <col width="35" />*/}
					<col width="35" />{/* tot. */}
					<col width="35" />

					<col width="50" />
					<col width="25" />

					<col width="30" />
					<col width="30" />
					<col width="30" />

					<col width="100" />
				</colgroup>
				<thead>
					<tr>
						<th className="tbl-center">
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Checkbox
									onChange={() => (selectedCts.size == 0 ? setSelectedCts(new Set(unplannedBookings.map(bk => bk.ctIds.split(',').map(ctId => Number(ctId))).reduce((tot, el) => tot.concat(el), []))) : setSelectedCts(new Set()))}
							        value={selectedCtsToList.length == allCtIds.length}
							        indeterminate={!(selectedCtsToList.length == 0 || selectedCtsToList.length == allCtIds.length)} />
							</div>
						</th>
						<TableHeader label="Pickup window" showSort={findKeyInSortCriteria('earliestPickup') != -1 ? sortCriteria[findKeyInSortCriteria('earliestPickup')].direction : null} onClick={() => onClickSort('earliestPickup')} index={findKeyInSortCriteria('earliestPickup')} />
						<TableHeader label="Van" showSort={findKeyInSortCriteria('pickupTerminal.id') != -1 ? sortCriteria[findKeyInSortCriteria('pickupTerminal.id')].direction : null} onClick={() => onClickSort('pickupTerminal.id')} index={findKeyInSortCriteria('pickupTerminal.id')} />
						<TableHeader label="Naar" showSort={findKeyInSortCriteria('dropoffTerminal.id') != -1 ? sortCriteria[findKeyInSortCriteria('dropoffTerminal.id')].direction : null} onClick={() => onClickSort('dropoffTerminal.id')} index={findKeyInSortCriteria('dropoffTerminal.id')} />
						<TableHeader label="Boek.nr" showSort={findKeyInSortCriteria('b.bookingNumber') != -1 ? sortCriteria[findKeyInSortCriteria('b.bookingNumber')].direction : null} onClick={() => onClickSort('b.bookingNumber')} index={findKeyInSortCriteria('b.bookingNumber')} />
						<TableHeader label="Klant" showSort={findKeyInSortCriteria('r.displayName') != -1 ? sortCriteria[findKeyInSortCriteria('r.displayName')].direction : null} onClick={() => onClickSort('r.displayName')} index={findKeyInSortCriteria('r.displayName')} />
						<th colSpan="2">20ft</th>
						<th colSpan="2">40ft</th>
						<th colSpan="2">? ft</th>
						<th>Tot.</th>
						<th>TEU</th>
						<th>Gew.</th>
						<th className="tbl-center">V/L</th>
						<th>#DG</th>
						<th>#OOG</th>
						<th>#RF</th>
						<TableHeader label="Dropoff window" showSort={findKeyInSortCriteria('latestDropoff') != -1 ? sortCriteria[findKeyInSortCriteria('latestDropoff')].direction : null} onClick={() => onClickSort('latestDropoff')} index={findKeyInSortCriteria('latestDropoff')} />
					</tr>
				</thead>
				<tbody>
					{unplannedBookings.map((bk, idx) => {
						const currentBkCtIds = bk.ctIds.split(',').map(ctId => Number(ctId));
						const selected = currentBkCtIds.every(ctId => selectedCts.has(ctId));
						const currentBkCtIdsSet = new Set(currentBkCtIds);
						return <TableRow
							key={idx}
							onRefresh={() => unplannedBookingsQuery.refetch()}
							booking={bk}
							terminals={terminals}
							selectedDate={selectedDate.toString()}
							selected={selected}
							onSelect={({ deselect, ctrlCmd, shift }) => {
								if (deselect) {
									setSelectedCts(new Set([ ...[ ...selectedCts ].filter(ctId => !currentBkCtIdsSet.has(ctId)) ]));
								} else if (ctrlCmd && shift && currentBkCtIds.every(ctId => selectedCts.has(ctId))) {
									setSelectedCts(new Set([ ...[ ...selectedCts ].filter(ctId => !currentBkCtIdsSet.has(ctId)) ]));
								} else if (ctrlCmd && shift) {
									setStartIdx(idx);
									setSelectedCts(new Set([ ...selectedCts, ...currentBkCtIdsSet ]));
								} else if (shift && startIdx == null) {
									setStartIdx(idx);
									setSelectedCts(new Set([ ...currentBkCtIdsSet ]));
								} else if (shift && startIdx != null) {
									const range = [];
									for (let i = Math.min(idx, startIdx); i <= Math.max(idx, startIdx); i++) {
								 range.push(unplannedBookings[i].ctIds.split(',').map(ctId => Number(ctId)));
									}
									setSelectedCts(new Set(range.reduce((tot, el) => tot.concat(el), [])));
								}
							}}
							selectedCts={selectedCts}
							setSelectedCts={setSelectedCts} />;
					})}
				</tbody>
			</table>

			<div style={{ display: 'flex', justifyContent: 'flex-end', padding: 'var(--u-16) var(--u-32) 0 var(--u-32)', gap: 'var(--u-24)', alignItems: 'center' }}>
				<div>
					<span><span>Boeking {(page - 1) * PAGE_SIZE + 1} t/m {Math.min((page * PAGE_SIZE), totalElements)} getoond, van {totalElements} totaal</span></span>
				</div>
				<Pagination page={page} setPage={setPage} pageSize={PAGE_SIZE} total={totalElements ?? 0} />
			</div>
		</div>
	</Popoutable3>;
}

function TableRow({ booking, terminals, selectedDate, selected, onSelect, selectedCts, setSelectedCts, onRefresh }) {
	const ctIds = booking.ctIds.split(',').map(ctId => Number(ctId));
	const intersect = ctIds.filter(id => selectedCts.has(id));
	const draggable = intersect.length > 0 || selectedCts.size == 0;

	const [ collected, drag, preview ] = useDrag(() => ({
		type: 'CONTAINER_TRANSPORTS',
		canDrag: draggable,
		item: selectedCts.size == 0 ? ctIds : [ ...selectedCts ],
		end: (_, monitor) => {
			const dropResult = monitor.getDropResult();
			if (typeof dropResult?.mutation?.finally == 'function') {
				dropResult.mutation.finally(() => {
					onRefresh();
				});
			} else {
				onRefresh();
			}
			setSelectedCts(new Set());
		},
	}), [ selectedCts, ctIds ]);

	const [ expanded, setExpanded ] = useState(false);
	const indeterminate = !(intersect.length == 0 || intersect.length == ctIds.length);

	preview(createDragPreview(''));

	return <>
		<tr
			ref={drag}
			className={expanded ? 'tbl-row-expanded' : ''}
			onClick={e => {
				if (e.shiftKey) {
					e.preventDefault();
					e.stopPropagation();
					onSelect({ shift: e.shiftKey, alt: e.altKey, ctrlCmd: e.ctrlKey || e.metaKey });
				}
			}}
			onMouseDown={e => {
				if (e.ctrlKey || e.metaKey || e.shiftKey) e.preventDefault();
			}}
			onDoubleClick={e => {
				e.preventDefault();
				e.stopPropagation();
				if (!e.shiftKey) {
					setExpanded(!expanded);
				}
			}}>
			<td className="tbl-center">
				{(selected || indeterminate) && <div style={{ display: 'flex', justifyContent: 'center' }}>
					<Checkbox onChange={() => onSelect({ deselect: true })} value={selected} indeterminate={indeterminate} />
				</div>}
			</td>
			<td><TimeShow value={booking.earliestPickup} time={selectedDate} compressedTime={true} /></td>
			<td>{terminals[booking.loadTerminalId]?.displayName ?? '-'}</td>
			<td>{terminals[booking.unloadTerminalId]?.displayName ?? '-'}</td>
			<td><Link target="_blank" to={'/bookings/' + booking.bookingId}>{booking.bookingNr}</Link></td>
			<td>{booking.customer}</td>
			<td className="tbl-align-right"><BlurredZeroValue value={booking.twentyFtEmpty + booking.twentyFtFull} /></td>
			{/* <td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={booking.twentyFtFull} /></td>*/}
			<td className="tbl-align-right"><BlurredZeroValue value={booking.fortyFtEmpty + booking.fortyFtFull} /></td>
			{/* <td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={booking.fortyFtFull} /></td>*/}
			<td className="tbl-align-right"><BlurredZeroValue value={booking.otherFtEmpty + booking.otherFtFull} /></td>
			{/* <td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={booking.otherFtFull} /></td>*/}
			<td className="tbl-align-right"><BlurredZeroValue value={booking.totalContainers} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={booking.totalTeu} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={booking.weight / 1000} /> t</td>
			<td className="tbl-center"><FullEmptyIndicator value={booking.fullEmptyStatus == 'FULL'} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={booking.totalDangerous} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={booking.totalOog} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={booking.totalReefer} /></td>
			<td><TimeShow value={booking.latestDelivery} time={selectedDate} compressedTime={true} /></td>
		</tr>
		{expanded && <tr className="tbl-row-expansion no-tr-hover">
			<td colSpan="20" style={{ position: 'relative' }}>
				<div className="fake-subtree-indicator" />
				<div style={{ padding: '8px 28px 16px 28px' }}>
					<ContainerTransportsSubTable
						ctIds={ctIds}
						selected={selected}
						selectedCts={selectedCts}
						setSelectedCts={setSelectedCts}
						intersect={intersect} />
				</div>
			</td>
		</tr>}
		{expanded && <tr style={{ height: 0 }}></tr>}{/* Dummy invisible table row to make automatic striped coloring work correctly again */}
	</>;
}

function ContainerTransportsSubTable({ ctIds, selected, selectedCts, setSelectedCts, intersect }) {
	const containerTransportsQuery = useQuery(gql`query Query($ctIds: [ Int! ]!) {
        containerTransportsByIds(ctIds: $ctIds) {
            id, containerNumber,
            booking {
                id, bookingNumber,
            },
            containerType {
                isoCode, isReefer, teu, displayName,
            },
            pickupTerminal {
                displayName,
            },
            dropoffTerminal {
                displayName,
            },
            full, grossWeight, activeReefer, reeferTemperature, remark, isVgm, overLengthBack, overLengthFront, overWidthRight, overWidthLeft, overHeight,
        }
    }`, { variables: { ctIds } });

	const cts = useMemo(() => {
		return containerTransportsQuery?.data?.containerTransportsByIds ?? [];
	}, [ containerTransportsQuery?.data?.containerTransportsByIds ]);

	const ctIdsSet = new Set(ctIds);

	return <table className="table table-fw" style={{ display: 'inline-table' }}>
		<colgroup>
			<col width="30" />

			<col width="100" />
			<col width="90" />
			<col width="50" />
			<col width="*" />

			{/* Van/naar */}
			<col width="50" />
			<col width="50" />

			{/* Weight etc. */}
			<col width="80" />
			<col width="30" />
			<col width="30" />
			<col width="60" />

			{/* Oversizes */}
			<col width="60" />
			<col width="60" />
			<col width="60" />
			<col width="60" />
			<col width="60" />
		</colgroup>
		<thead>
			<tr>
				<th className="tbl-center">
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Checkbox value={selected}
						          onChange={() => (intersect.length == 0 ? setSelectedCts(new Set([ ...selectedCts, ...ctIdsSet ])) : setSelectedCts(new Set([ ...selectedCts ].filter(id => !ctIdsSet.has(id)))))}
						          indeterminate={!(intersect.length == 0 || intersect.length == ctIds.length)} />
					</div>
				</th>{/* Selection */}
				<th>Cont.nr</th>
				<th>Boeking</th>
				<th>Type</th>
				<th>Opmerking</th>
				<th>Van</th>
				<th>Naar</th>
				<th>Gew.</th>
				<th>V/L</th>
				<th>VGM</th>
				<th>Temp</th>
				<th>OL V</th>
				<th>OL A</th>
				<th>OB L</th>
				<th>OB R</th>
				<th>OH</th>
			</tr>
		</thead>
		<tbody>
			{containerTransportsQuery.loading && <tr>
				<td colSpan={16}>
					Containertransporten laden...
				</td>
			</tr>}
			{cts.map((ct, idx) => <ContainerTransportsSubTableRow selectedCts={selectedCts} setSelectedCts={setSelectedCts} ct={ct} cts={cts} idx={idx} />)}
		</tbody>
	</table>;
}

function ContainerTransportsSubTableRow({ selectedCts, setSelectedCts, ct, cts, idx }) {
	const ctId = Number(ct.id);
	const draggable = selectedCts.has(ctId) || selectedCts.size == 0;

	const [ collected, drag, dragpreview ] = useDrag(() => ({
		type: 'CONTAINER_TRANSPORTS',
		canDrag: draggable,
		item: selectedCts.size == 0 ? [ ctId ] : [ ...selectedCts ],
	}), [ selectedCts ]);

	const [ innerStartIdx, setInnerStartIdx ] = useState(null);

	function handleCtSelection({ deselect, ctrlCmd, shift, idx }) {
		if (deselect) {
			setSelectedCts(new Set([ ...[ ...selectedCts ].filter(id => id != ctId) ]));
		} else if (ctrlCmd && shift && selectedCts.has(ctId)) {
			setSelectedCts(new Set([ ...[ ...selectedCts ].filter(id => id != ctId) ]));
		} else if (ctrlCmd && shift) {
			setInnerStartIdx(idx);
			setSelectedCts(new Set([ ...selectedCts, ctId ]));
		} else if (shift && innerStartIdx == null) {
			setInnerStartIdx(idx);
			setSelectedCts(new Set([ ...selectedCts, ctId ]));
		} else if (shift && innerStartIdx != null) {
			const range = [];
			for (let i = Math.min(idx, innerStartIdx); i <= Math.max(idx, innerStartIdx); i++) {
				range.push(Number(cts[i].id));
			}
			setSelectedCts(new Set(range.reduce((tot, el) => tot.concat(el), [])));
		}
	}

	return <tr
		ref={drag}
		key={ct.id}
		onClick={e => {
			if (e.shiftKey) {
				e.preventDefault();
				e.stopPropagation();
				handleCtSelection({ shift: e.shiftKey, alt: e.altKey, ctrlCmd: e.ctrlKey || e.metaKey, idx: idx });
			}
		}}
		onMouseDown={e => {
		   if (e.ctrlKey || e.metaKey || e.shiftKey) e.preventDefault();
		}}>
		<td className="tbl-center">
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				{selectedCts.has(ctId) && <Checkbox onChange={() => handleCtSelection({ deselect: true })} value={selectedCts.has(ctId)} />}
			</div>
		</td>
		<td><CopyToClipboardText value={ct.containerNumber} /></td>
		<td><Link target="_blank" to={'/bookings/' + ct.booking.id}>{ct.booking.bookingNumber}-{idx + 1}</Link></td>
		<td>{ct.containerType?.displayName ?? '-'}</td>
		<td>{ct.remark ?? '-'}</td>
		<td>{ct.pickupTerminal?.displayName ?? '-'}</td>
		<td>{ct.dropoffTerminal?.displayName ?? '-'}</td>
		<td className="tbl-align-right">{ct.grossWeight ?? 0} kgs</td>
		<td className="tbl-center"><FullEmptyIndicator value={ct.full} /></td>
		<td className="tbl-center"><BooleanIndicator value={ct.isVgm} /></td>
		<td className="tbl-align-right">{ct.activeReefer ? <DisplayTemperature value={ct.reeferTemperature} /> : ''}</td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overLengthFront ?? 0} /></td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overLengthBack ?? 0} /></td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overWidthLeft ?? 0} /></td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overWidthRight ?? 0} /></td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overHeight ?? 0} /></td>
	</tr>;
}

function TableHeader({ showSort, onClick, label, index }) {
	return <th onClick={onClick} style={{ userSelect: 'none' }}>
		{showSort == 'asc' && <span className="fa fa-sort-asc" style={{ fontWeight: 'bold' }}>({index + 1})</span>}
		{showSort == 'desc' && <span className="fa fa-sort-desc" style={{ fontWeight: 'bold' }}>({index + 1})</span>}
		{showSort != null && <>&nbsp;&nbsp;</>}
		{label}
	</th>;
}