import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import SearchField from '../../lib/forms/SearchField';
import DeleteRelationModal from './DeleteRelationModal';
import AddRelationModal from './AddRelationModal';
import EditRelationModal from './EditRelationModal';
import { Button, DropdownButton } from '@atrocit/scl';
import PageTop from '../../lib/ui/PageTop';

export default function RelationOverview() {
	const [ query, setQuery ] = useState('');
	const [ showDeleteRelationModal, setShowDeleteRelationModal ] = useState(false);
	const [ showEditRelationModal, setShowEditRelationModal ] = useState(false);
	const [ showAddRelationModal, setShowAddRelationModal ] = useState(false);
	const [ editRelationId, setEditRelationId ] = useState(null);
	const [ deleteRelationId, setDeleteRelationId ] = useState(null);

	const backendQuery = useQuery(gql`{
        relations {
			id,
			displayName,
			fullName,
            city,
            country,
            companyId,
            euTaxId,
		}
    }`);
	const relations = (backendQuery?.data?.relations ?? [])
		.filter(r => r.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1
			|| r.fullName.toLowerCase().indexOf(query.toLowerCase()) > -1
			|| (r.companyId ?? '').toLowerCase().indexOf(query.toLowerCase()) > -1
			|| (r.euTaxId ?? '').toLowerCase().indexOf(query.toLowerCase()) > -1
		);

	return <div className="page">
		{showDeleteRelationModal && <DeleteRelationModal isOpen={showDeleteRelationModal} onClose={() => setShowDeleteRelationModal(false)} relationId={deleteRelationId} refetch={() => backendQuery.refetch()} />}
		{showAddRelationModal && <AddRelationModal isOpen={showAddRelationModal} onClose={() => setShowAddRelationModal(false)} refetch={() => backendQuery.refetch()} />}
		{showEditRelationModal && <EditRelationModal isOpen={showEditRelationModal} onClose={() => setShowEditRelationModal(false)} refetch={() => backendQuery.refetch()} relationId={editRelationId} />}

		<PageTop
			breadcrumbSegments={[
				{ link: '/relations', label: 'Relaties' },
				{ link: '/relations', label: 'Overzicht' },
			]}>
			<Button onClick={() => setShowAddRelationModal(true)}><span className="fa fa-plus" />&nbsp; Nieuwe relatie</Button>
		</PageTop>

		<SearchField
			onChange={setQuery}
			value={query}
			hasResults={relations.length != 0} />

		<table className="table table-fw">
			<colgroup>
				<col width="150" />
				<col width="*" />
				<col width="150" />
				<col width="150" />
				<col width="150" />
				<col width="165" />
			</colgroup>
			<thead className="tbl-sticky-top">
				<tr>
					<th>Weergavenaam</th>
					<th>Volledige naam</th>
					<th>Stad</th>
					<th>Land</th>
					<th>BTW-nummer</th>
					<th />
				</tr>
			</thead>
			<tbody>
				{relations.map(r => <tr key={r.id}>
					<td title={r.displayName}>{r.displayName}</td>
					<td title={r.fullName}>{r.fullName}</td>
					<td title={r.city}>{r.city}</td>
					<td title={r.country}>{r.country}</td>
					<td>{r.euTaxId}</td>
					<td style={{ display: "flex", justifyContent: "space-around", gap: "var(--u-16)" }}>
						<DropdownButton mainAction={<Button onClick={() => { setEditRelationId(r.id); setShowEditRelationModal(true); }}><span className="fa fa-pencil" />&nbsp; Bewerken</Button>}>
							<Button className="danger" onClick={() => { setDeleteRelationId(r.id); setShowDeleteRelationModal(true); }}><span className="fa fa-trash-o" />&nbsp; Verwijderen</Button>
						</DropdownButton>
					</td>
				</tr>)}
				{!backendQuery.loading && relations.length == 0 && <tr>
					<td colSpan={6}>Geen resultaten</td>
				</tr>}
			</tbody>
		</table>
	</div>;
}