import { gql, useQuery, useSubscription } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import usePersistentState from '../../hooks/usePersistentState';
import TopBar from './TopBar';
import DayView from './calls/DayView';
import SelectShipsModal from './SelectShipModal';
import makeMap from '../../util/makeMap';
import UnplannedBookings from './unplanned/UnplannedBookings';
import CommandModule from '../commands/CommandModule';

export default function Dashboard() {
	const [ savedTime, setSavedTime ] = usePersistentState(DateTime.now().startOf('day').toISO(), "bmblb.time");
	const time = useMemo(() => DateTime.fromISO(savedTime), [ savedTime ]);
	const setTime = useCallback(t => setSavedTime(t.toISO()), [ setSavedTime ]);
	const [ selectedShips, setSelectedShips ] = usePersistentState([], "bmblb.selectedShips");
	const [ shipsOrder, setShipsOrder ] = usePersistentState([], "bmblb.shipsOrder");
	const [ showShipSelectionModal, setShowShipSelectionModal ] = useState(false);
	const [ shipLoad, setShipLoad ] = useState({});
	const [ compactMode, setCompactMode ] = usePersistentState(false, 'bmblb.compactMode');
	const [ showUnplanned, setShowUnplanned ] = usePersistentState(false, 'bmblb.showUnplanned');
	const [ selectedPlannedCts, setSelectedPlannedCts ] = useState(new Set());
	const [ selectedUnplannedCts, setSelectedUnplannedCts ] = useState(new Set());
	const selectedCts = new Set([ ...selectedPlannedCts, ...selectedUnplannedCts ]);

	const subscription = useSubscription(gql`subscription Subscription($from: Instant!, $to: Instant!) {
		callSubscription(from: $from, to: $to) {
			calls {
				callId, terminalId, shipId, callReference, remark, plannedArrival, plannedDeparture, 
				loadList {
					callId, customer, bookingNr, bookingId, bookingReference, totalContainers, totalTeu, twentyFtEmpty, twentyFtFull, fortyFtEmpty, fortyFtFull,
					otherFtEmpty, otherFtFull, totalOog, totalDangerous, weight, totalReefer, fullEmptyStatus, loadUnloadStatus, unloadTerminalId, loadTerminalId,
					amountLoaded, amountUnloaded, earliestPickup, latestPickup, earliestDropoff, latestDropoff, ctIds
                }, 
				unloadList {
                    callId, customer, bookingNr, bookingId, bookingReference, totalContainers, totalTeu, twentyFtEmpty, twentyFtFull, fortyFtEmpty, fortyFtFull,
                    otherFtEmpty, otherFtFull, totalOog, totalDangerous, weight, totalReefer, fullEmptyStatus, loadUnloadStatus, unloadTerminalId, loadTerminalId,
					amountLoaded, amountUnloaded, earliestPickup, latestPickup, earliestDropoff, latestDropoff, ctIds
                }
            }
        }
	}`, { variables: { from: time.startOf("day").toUTC().toISO(), to: time.plus({ "days": 1 }).startOf("day").toUTC().toISO() } });

	const shipsQuery = useQuery(gql`{
        ships {
            id,
            name,
            eniIdentifier,
            teuCapacity,
            weightCapacity
        }
    }`);

	const terminalsQuery = useQuery(gql`{
        terminals {
            id,
            displayName,
            fullName,
            portUnloCode,
        }
    }`);

	const calls = subscription?.data?.callSubscription?.calls ?? [];
	const ships = makeMap(shipsQuery?.data?.ships ?? []);
	const terminals = makeMap(terminalsQuery?.data?.terminals ?? []);

	return <div className="dashboard-container">
		{showShipSelectionModal && <SelectShipsModal onClose={() => setShowShipSelectionModal(false)} ships={ships} shipsOrder={shipsOrder} setShipsOrder={setShipsOrder} selectedShips={selectedShips} setSelectedShips={setSelectedShips} />}

		<TopBar
			loading={false}
			time={time}
			setTime={setTime}
			setCompactMode={() => {}}
			setShowUnplanned={setShowUnplanned}
			showUnplanned={showUnplanned}
			showSelectShipModal={() => setShowShipSelectionModal(true)} />

		<div className="dashboard-content">
			<CommandModule
				containerTransportIds={[ ...selectedCts ]}
				enableToolbar={false} />

			<DayView
				calls={calls}
				ships={ships}
				selectedShips={selectedShips}
				terminals={terminals}
				shipsOrder={shipsOrder}
				time={time.toString()}
				shipLoad={shipLoad}
				compactMode={compactMode}
				selectedCts={selectedPlannedCts}
				setSelectedCts={setSelectedPlannedCts} />

			{showUnplanned && <div style={{ borderLeft: '1px solid var(--col-grey-200)', background: 'var(--col-white)', boxShadow: '0 0 4px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.10)' }}>
				<UnplannedBookings selectedDate={time} terminals={terminals} selectedCts={selectedUnplannedCts} setSelectedCts={setSelectedUnplannedCts} />
			</div>}
		</div>
	</div>;

}