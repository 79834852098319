import '../styling/main.css';
import '@atrocit/scl/build/index.css';
import { ApolloInjector } from './ApolloInjector';
import 'font-awesome/css/font-awesome.min.css';
import AuthManager from './auth/AuthManager';
import RoutesInternal from './RoutesInternal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import WindowContext from '../context/WindowContext';
import { IntlProvider } from 'react-intl';
import ConfigurationInjector from './configuration/ConfigurationInjector';

function App() {
	return <ApolloInjector>
		<AuthManager>
			<ConfigurationInjector>
				<DndProvider backend={HTML5Backend}>
					<IntlProvider locale="nl">
						<WindowContext.Provider value={window}>
							<RoutesInternal />
						</WindowContext.Provider>
					</IntlProvider>
				</DndProvider>
			</ConfigurationInjector>
		</AuthManager>
	</ApolloInjector>;
}

export default App;