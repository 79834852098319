import { gql, useMutation } from '@apollo/client';
import Modal from '../../lib/ui/Modal';
import { useCallback, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { Button, Form, InputField, WithLabel } from '@atrocit/scl';

export default function AddShipModal({ isOpen, onClose, refetch }) {
	const [ name, setName ] = useState("");
	const [ eniIdentifier, setEniIdentifier ] = useState("");
	const [ teuCapacity, setTeuCapacity ] = useState(0);
	const [ weightCapacity, setWeightCapacity ] = useState(0);
	const [ loading, setLoading ] = useState(false);

	const [ addShip ] = useMutation(gql`mutation Mutation($ship: ShipCreation!) {
        addShip(ship: $ship) { id }
    }`);

	const process = useCallback(() => {
		setLoading(true);
		const ship = { name: name, eniIdentifier: eniIdentifier, teuCapacity: teuCapacity, weightCapacity: weightCapacity };
		addShip({ variables: { ship: ship } }).finally(() => { refetch(); onClose(); });
	}, [ name, eniIdentifier, teuCapacity, weightCapacity ]);

	return <Modal isOpen={isOpen} onClose={onClose} title="Nieuw schip">
		{!loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<WithLabel label="Naam">
				<InputField value={name} onChange={setName} />
			</WithLabel>
			<WithLabel label="ENI">
				<InputField value={eniIdentifier} onChange={setEniIdentifier} />
			</WithLabel>
			<WithLabel label="TEU capaciteit">
				<InputField value={teuCapacity} onChange={setTeuCapacity} />
			</WithLabel>
			<WithLabel label="Gewichtscapaciteit (ton)">
				<InputField value={weightCapacity} onChange={setWeightCapacity} />
			</WithLabel>
			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: name.length > 0, error: "De naam mag niet leeg zijn" },
						{ valid: name.length < 200, error: "Naam is te lang" },
						{ valid: eniIdentifier.length > 0, error: "ENI mag niet leeg zijn" },
						{ valid: eniIdentifier.length <= 9, error: "ENI is te lang" },
						{ valid: teuCapacity > 0, error: "Capaciteit (TEU) is een positief getal" },
						{ valid: teuCapacity < 9999999, error: "Capaciteit (TEU) is te groot" },
						{ valid: !isNaN(teuCapacity) && teuCapacity.toString().indexOf(".") == -1, error: "De capaciteit (TEU) moet een geheel getal zijn" },
						{ valid: weightCapacity > 0, error: "Capaciteit (Tons) is een positief getal" },
						{ valid: weightCapacity < 9999999, error: "Capaciteit (TEU) is te groot" },
						{ valid: !isNaN(weightCapacity) && weightCapacity.toString().indexOf(".") == -1, error: "De capaciteit (ton) moet een geheel getal zijn" },
					]}>
					<span className="fa fa-plus" />&nbsp; Toevoegen
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}