import { useState } from 'react';
import { DateTime } from 'luxon';
import LoadingOverlay from '../../lib/ui/LoadingOverlay';
import { Button, Form, WarningBanner, WithLabel } from '@atrocit/scl';
import Modal from '../../lib/ui/Modal';
import { gql, useMutation } from '@apollo/client';
import DateTimeField from '../../lib/forms/DateTimeField';
import ButtonArea from '../../lib/forms/ButtonArea';

export function ProcessPickupCommand({ containerTransports, onClose }) {
	const [ pickupDate, setPickupDate ] = useState(DateTime.now());

	const [ processPickupMutation, { loading } ] = useMutation(gql`mutation Mutation ($containerTransports: [ Int! ]!, $pickupDate: Instant!) {
		processPickups(containerTransports: $containerTransports, pickupDate: $pickupDate) { id }
	}`);

	return <Modal title="Verwerk vertrek" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<div style={{ width: 'var(--u-384)' }}>
			<Form onSubmit={() => processPickupMutation({ variables: { containerTransports: containerTransports.map(ct => ct.id), pickupDate: pickupDate.toUTC().toISO() } }).then(onClose)}>
				<div style={{ display: "flex", flexDirection: "column", gap: "var(--u-8)", paddingBottom: "var(--u-8)" }}>
					{containerTransports.filter(ct => ct.pickupCall == null || ct.dropoffCall == null).length > 0 && <WarningBanner>
						<span>Een deel van de containertransporten is niet gepland</span>
					</WarningBanner>}
				</div>

				<WithLabel label="Vertrektijd">
					<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPickupDate(dt) : null)} value={pickupDate} />
				</WithLabel>

				<ButtonArea>
					<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp;Verwerk vertrek</Button>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</div>
	</Modal>;
}


export function ProcessDropoffCommand({ containerTransports, onClose }) {
	const [ dropoffDate, setDropoffDate ] = useState(DateTime.now());

	const [ processDropoffMutation, { loading } ] = useMutation(gql`mutation Mutation ($containerTransports: [ Int! ]!, $dropoffDate: Instant!) {
        processDropoffs(containerTransports: $containerTransports, dropoffDate: $dropoffDate) { id }
    }`);

	return <Modal title="Verwerk aankomst" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<div style={{ width: 'var(--u-384)' }}>
			<Form onSubmit={() => processDropoffMutation({ variables: { containerTransports: containerTransports.map(ct => ct.id), dropoffDate: dropoffDate.toUTC().toISO() } }).then(onClose)}>
				<div style={{ display: "flex", flexDirection: "column", gap: "var(--u-8)", paddingBottom: "var(--u-8)" }}>
					{containerTransports.filter(ct => ct.actualPickup == null).length > 0 && <WarningBanner>
						<span>Er is een aankomsttijd ingevuld zonder dat er een vertrektijd bekend is. Als je doorgaat wordt de vertrektijd automatisch ingevuld.</span>
					</WarningBanner>}
					{containerTransports.filter(ct => ct.pickupCall == null || ct.dropoffCall == null).length > 0 && <WarningBanner>
						<span>Een deel van de containertransporten is niet gepland</span>
					</WarningBanner>}
				</div>

				<WithLabel label="Aankomsttijd">
					<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setDropoffDate(dt) : null)} value={dropoffDate} />
				</WithLabel>

				<ButtonArea>
					<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp;Verwerk aankomst</Button>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</div>
	</Modal>;
}