import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	TimeScale,
} from "chart.js";
import { DateTime } from 'luxon';
import React from 'react';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	TimeScale
);


export default function ConnectionPageChart({ sortedConnections }) {
	const customBorder = {
		id: 'customBorder',
		afterDatasetsDraw(chart, args, pluginOptions) {
			const {
				ctx,
				chartArea: {
					top,
					bottom,
					left,
					right,
				},
			} = chart;


			ctx.save();
			ctx.beginPath();
			ctx.lineWidth = 3;
			ctx.strokeStyle = "#aaa";

			ctx.moveTo(left - 1, top + 3);
			ctx.lineTo(left + 5, top + 10);
			ctx.moveTo(left + 1, top + 3);
			ctx.lineTo(left - 5, top + 10);
			ctx.moveTo(left, top + 5);
			ctx.lineTo(left, bottom);
			ctx.lineTo(right - 5, bottom);
			ctx.moveTo(right - 3, bottom + 1);
			ctx.lineTo(right - 10, bottom - 5);
			ctx.moveTo(right - 3, bottom - 1);
			ctx.lineTo(right - 10, bottom + 5);
			ctx.stroke();
			ctx.closePath();
		},
	};

	const graphData = sortedConnections.map(connection => ({
		receivedAt: DateTime.fromISO(connection?.createdAt),
		timeOfArrival: DateTime.fromISO(connection?.vesselAta ?? connection?.vesselEta),
	}));

	const xMiddlePoint = graphData[0].timeOfArrival;

	const xBoundaries = graphData.map((p) => p.timeOfArrival.diff(xMiddlePoint).toObject().milliseconds)
		.map(v => Math.abs(v))
		.reduce((biggest, element) => Math.max(biggest, element), 0);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: true,
				text: "ETA grafiek",
			},
		},
		indexAxis: "y",
		scales: {
			x: {
				title: {
					display: true,
					text: 'Verwachte aankomsttijd ➜',
				},
				type: "time",
				time: {
					tooltipFormat: "yyyy-MM-dd HH:mm",
					title: {
						display: true,
						text: "ETA",
					},
					unit: xBoundaries < (1000 * 3600 * 24) ? "hour" : 'day',
					displayFormats: {
						day: "yyyy-MM-dd",
						hour: 'MM-dd HH:mm',
					},
				},
				min: xMiddlePoint.minus({ milliseconds: xBoundaries * 1.10 }).toJSDate(),
				max: xMiddlePoint.plus({ milliseconds: xBoundaries * 1.10 }).toJSDate(),
				ticks: {
					stepSize: xBoundaries < (1000 * 3600 * 24) && xBoundaries > (1000 * 3600 * 3) ? 4 : 1,
				},
			},
			y: {
				title: {
					display: true,
					text: 'Ontvangen ➜',
				},
				type: 'time',
				time: {
					tooltipFormat: "yyyy-MM-dd HH:mm",
					title: {
						display: true,
						text: "ETA",
					},
					unit: "day",
					displayFormats: {
						day: "yyyy-MM-dd",
					},
				},
				offset: true,
			},
		},
	};

	const colorList = graphData.map((_, idx) => (sortedConnections[idx].vesselAta != null ? '#34D399' : '#357dc0'));
	const pointRadius = graphData.map((_, idx) => (sortedConnections[idx].vesselAta != null ? 6 : 4));

	const data = {
		labels: graphData.map((p) => DateTime.fromISO(p.receivedAt).toJSDate()),
		datasets: [
			{
				label: "Zeeschip ETA over tijd",
				data: graphData.map((d) => DateTime.fromISO(d.timeOfArrival).toJSDate()),
				borderColor: '#357dc0',
				backgroundColor: colorList,
				pointBackgroundColor: colorList,
				pointBorderColor: colorList,
				pointRadius: pointRadius,
			},
		],
	};

	return <Line options={options} data={data} plugins={[ customBorder ]} />;
}