import { DateTime } from 'luxon';
import { InputField } from '@atrocit/scl';
import React from 'react';

export default function DateField({ onChange, date, disabled }) {
	return <InputField
		type="date"
		onChange={d => onChange(DateTime.fromISO(d + 'T00:00:00'))}
		value={date?.toFormat('yyyy-MM-dd')}
		disabled={disabled} />;
}