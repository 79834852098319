import { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from '../../lib/ui/Modal';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { Button, Form, InputField, WithLabel } from '@atrocit/scl';

export default function AddRelationModal({ isOpen, onClose, refetch }) {
	const [ displayName, setDisplayName ] = useState('');
	const [ fullName, setFullName ] = useState('');
	const [ addressLine1, setAddressLine1 ] = useState(null);
	const [ addressLine2, setAddressLine2 ] = useState(null);
	const [ zipCode, setZipCode ] = useState(null);
	const [ city, setCity ] = useState(null);
	const [ country, setCountry ] = useState(null);
	const [ companyId, setCompanyId ] = useState(null);
	const [ euTaxId, setEuTaxId ] = useState(null);

	const [ addRelation, addRelationMutation ] = useMutation(gql`mutation Mutation($relation: RelationCreation!) {
		addRelation(relation: $relation) { id }
	}`);

	const process = useCallback(() => {
		const relation = { displayName: displayName, fullName: fullName, addressLine1: addressLine1, addressLine2: addressLine2, zipCode: zipCode, city: city, country: country, companyId: companyId, euTaxId: euTaxId };
		addRelation({ variables: { relation: relation } }).finally(() => { refetch(); onClose(); });
	}, [ displayName, fullName, addressLine1, addressLine2, zipCode, city, country, companyId, euTaxId ]);

	return <Modal isOpen={isOpen} onClose={onClose} title="Nieuwe relatie">
		{!addRelationMutation.loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<WithLabel label="Weergavenaam">
				<InputField value={displayName} onChange={setDisplayName} />
			</WithLabel>
			<WithLabel label="Volledige naam">
				<InputField value={fullName} onChange={setFullName} />
			</WithLabel>
			<WithLabel label="Adresregel 1">
				<InputField value={addressLine1} onChange={setAddressLine1} />
			</WithLabel>
			<WithLabel label="Adresregel 2">
				<InputField value={addressLine2} onChange={setAddressLine2} />
			</WithLabel>
			<WithLabel label="Postcode">
				<InputField value={zipCode} onChange={setZipCode} />
			</WithLabel>
			<WithLabel label="Stad">
				<InputField value={city} onChange={setCity} />
			</WithLabel>
			<WithLabel label="Land">
				<InputField value={country} onChange={setCountry} />
			</WithLabel>
			<WithLabel label="KvK">
				<InputField value={companyId} onChange={setCompanyId} />
			</WithLabel>
			<WithLabel label="BTW-nummer">
				<InputField value={euTaxId} onChange={setEuTaxId} />
			</WithLabel>
			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: displayName.length > 0, error: "Weergavenaam mag niet leeg zijn" },
						{ valid: displayName.length < 200, error: "Weergavenaam moet kleiner zijn dan 200 karakters" },
						{ valid: fullName.length > 0, error: "Volledige naam mag niet leeg zijn" },
						{ valid: fullName.length < 200, error: "Volledige  moet kleiner zijn dan 200 karakters" },
						{ valid: (addressLine1 != null ? addressLine1.length < 200 : true) || (addressLine2 != null ? addressLine2 < 200 : true), error: "Adresregel moet kleiner zijn dan 200 karakters" },
						{ valid: zipCode != null ? zipCode.length < 50 : true, error: "Postcode moet kleiner zijn dan 50 karakters" },
						{ valid: city != null ? city.length < 150 : true, error: "Stad moet kleiner zijn dan 150 karakters" },
						{ valid: country != null ? country.length < 150 : true, error: "Land moet kleiner zijn dan 150 karakters" },
						{ valid: companyId != null ? companyId.length < 50 : true, error: "KVK moet kleiner zijn dan 50 karakters" },
						{ valid: euTaxId != null ? euTaxId.length < 50 : true, error: "BTW-Nummer moet kleiner zijn dan 50 karakters" },
					]}>
					<span className="fa fa-plus" />&nbsp; Toevoegen
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{addRelationMutation.loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}