import { gql, useQuery } from '@apollo/client';
import CommandSearch from './CommandSearch';
import React, { useCallback, useState } from 'react';
import ChangeTrajectoryCommand from './commands/ChangeTrajectoryCommand';
import FillContainerDetailsCommand from './commands/FillContainerDetailsCommand';
import PickupDropoffReferenceCommand from './commands/PickupDropoffReferenceCommand';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button } from '@atrocit/scl';
import PickupWindowCommand from './commands/PickupWindowCommand';
import DropoffWindowCommand from './commands/DropoffWindowCommand';
import ProcessPickupDropoffCommand, {
	ProcessDropoffCommand,
	ProcessPickupCommand,
} from './commands/ProcessPickupDropoffCommand';
import ProcessAttachPickupConnectionCommand from './commands/ProcessAttachPickupConnectionCommand';
import ProcessAttachDropoffConnectionCommand from './commands/ProcessAttachDropoffConnectionCommand';

export default function CommandModule({ containerTransportIds, enableToolbar = true, onRefreshNeeded = () => {} }) {
	const [ cmdSearchOpen, setCmdSearchOpen ] = useState(false);
	const [ currentCommand, setCurrentCommand ] = useState('');

	useHotkeys('c', event => {
		if (cmdSearchOpen) return;

		// This is to prevent capture of the 'c' by the autofocus input field
		setTimeout(() => setCmdSearchOpen(true), 0);
	}, [ cmdSearchOpen ]);

	useHotkeys('esc', event => {
		event.preventDefault();
		setCmdSearchOpen(false);
	}, []);

	useHotkeys('t', event => {
		event.preventDefault();
		if (containerTransportIds.length == 0) return;
		setCurrentCommand('change-trajectory');
	}, [ containerTransportIds ]);

	useHotkeys('d', event => {
		event.preventDefault();
		if (containerTransportIds.length == 0) return;
		setCurrentCommand('fill-container-details');
	}, [ containerTransportIds ]);

	useHotkeys('v', event => {
		event.preventDefault();
		if (containerTransportIds.length == 0) return;
		setCurrentCommand('process-pickup');
	}, [ containerTransportIds ]);

	useHotkeys('a', event => {
		event.preventDefault();
		if (containerTransportIds.length == 0) return;
		setCurrentCommand('process-dropoff');
	}, [ containerTransportIds ]);

	useHotkeys('o', event => {
		event.preventDefault();
		if (containerTransportIds.length == 0) return;
		setCurrentCommand('process-attach-pickup-connection');
	}, [ containerTransportIds ]);

	useHotkeys('i', event => {
		event.preventDefault();
		if (containerTransportIds.length == 0) return;
		setCurrentCommand('process-attach-dropoff-connection');
	}, [ containerTransportIds ]);

	useHotkeys('[', event => {
		event.preventDefault();
		if (containerTransportIds.length == 0) return;
		setCurrentCommand('change-windows-pickup');
	}, [ containerTransportIds ]);

	useHotkeys(']', event => {
		event.preventDefault();
		if (containerTransportIds.length == 0) return;
		setCurrentCommand('change-windows-dropoff');
	}, [ containerTransportIds ]);

	const ctQuery = useQuery(gql`query Query($ctIds: [ Int! ]!) {
        containerTransportsByIds(ctIds: $ctIds) {
            id,
            containerType {
                isoCode, displayName, teu
            },
            containerNumber,
            full,
            activeReefer,
            reeferTemperature,

            pickupTerminal {
                id, displayName,
            },
            earliestPickup,
            latestPickup,
            pickupReference,

            dropoffTerminal {
                id, displayName,
            },
            earliestDropoff,
            latestDropoff,
            dropoffReference,

            actualPickup,
            actualDropoff,

            pickupCall {
                id,
                ship {
                    id, name
                },
                pta, ptd
            },
            dropoffCall {
                id,
                ship {
                    id, name
                },
                pta, ptd
            },
            
            pickupConnection {
                id,
                voyageNumberInbound,
                seaship {
                    id, shipName
                },
                terminal {
                    id, displayName
                }
            }

            dropoffConnection {
                id,
                voyageNumberOutbound,
                seaship {
                    id, shipName
                },
                terminal {
                    id, displayName
                }
            }
            
            remark
        }
	}`, { variables: { ctIds: containerTransportIds } });
	const containerTransports = ctQuery?.data?.containerTransportsByIds ?? [];

	const onClose = useCallback(() => {
		setCurrentCommand(null);
		onRefreshNeeded();
	}, [ onRefreshNeeded ]);

	return <>
		<CommandSearch
			key={cmdSearchOpen}
			open={cmdSearchOpen}
			setOpen={setCmdSearchOpen}
			onClose={() => setCmdSearchOpen(false)}
			onCommand={cmd => setCurrentCommand(cmd)}
			loading={ctQuery.loading}
			containerTransports={containerTransports} />

		{currentCommand == 'change-trajectory' && <ChangeTrajectoryCommand containerTransports={containerTransports} onClose={onClose} />}
		{currentCommand == 'fill-container-details' && <FillContainerDetailsCommand containerTransports={containerTransports} onClose={onClose} />}
		{currentCommand == 'change-references' && <PickupDropoffReferenceCommand containerTransports={containerTransports} onClose={onClose} />}
		{currentCommand == 'change-windows-pickup' && <PickupWindowCommand containerTransports={containerTransports} onClose={onClose} />}
		{currentCommand == 'change-windows-dropoff' && <DropoffWindowCommand containerTransports={containerTransports} onClose={onClose} />}
		{currentCommand == 'process-pickup' && <ProcessPickupCommand containerTransports={containerTransports} onClose={onClose} />}
		{currentCommand == 'process-dropoff' && <ProcessDropoffCommand containerTransports={containerTransports} onClose={onClose} />}
		{currentCommand == 'process-attach-pickup-connection' && <ProcessAttachPickupConnectionCommand containerTransports={containerTransports} onClose={onClose}/>}
		{currentCommand == 'process-attach-dropoff-connection' && <ProcessAttachDropoffConnectionCommand containerTransports={containerTransports} onClose={onClose} />}

		{enableToolbar && <div className="toolbar-wrapper">
			<div className={"toolbar" + (containerTransportIds.length == 0 ? ' toolbar-hidden' : ' toolbar-visible')}>
				<div style={{ paddingRight: 'var(--u-32)' }}>
					{containerTransports.length} geselecteerd
				</div>
				<div style={{ display: 'flex', gap: 'var(--u-8)' }}>
					<Button onClick={() => { setCmdSearchOpen(false); setCurrentCommand('change-trajectory'); }}>
						<span className="fa fa-map-signs" /> &nbsp;Traject wijzigen
					</Button>
					<Button onClick={() => { setCmdSearchOpen(false); setCurrentCommand('fill-container-details'); }}>
						<span className="fa fa-sort-numeric-asc" /> &nbsp;Details aanvullen
					</Button>
					<Button onClick={() => setCmdSearchOpen(true)}><span className="fa fa-terminal" /> &nbsp;Commando's</Button>
				</div>
			</div>
		</div>}
	</>;
}