import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';

export default function ContainerTypeSelectField({ onChange, value, placeholder = '', ...props }) {
	const CreateBookingContainerTypeQuery = useQuery(gql`query {
        containerTypes {
            id, displayName
        }
    }`);

	const options = useMemo(() => {
		return (CreateBookingContainerTypeQuery?.data?.containerTypes ?? []).map(s => ({
			value: s.id,
			label: s.displayName,
			original: s,
		}));
	}, [ CreateBookingContainerTypeQuery?.data?.containerTypes ]);

	const onChangeCb = useCallback((obj) => {
		onChange(obj?.value ?? null, obj?.original);
	}, [ onChange ]);

	return <Select
		{...props}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={CreateBookingContainerTypeQuery.loading}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		value={options.find(o => o.value == value)} />;
}