import Modal from '../../lib/ui/Modal';
import { useCallback, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, InputField, Toggle, WithLabel } from '@atrocit/scl';

export default function AddContainerTypeModal({ isOpen, onClose, refetch }) {
	const [ displayName, setDisplayName ] = useState("");
	const [ fullName, setFullName ] = useState("");
	const [ isoCode, setIsoCode ] = useState("");
	const [ teu, setTeu ] = useState(0);
	const [ height, setHeight ] = useState(0);
	const [ length, setLength ] = useState(0);
	const [ weight, setWeight ] = useState(0);
	const [ isReefer, setIsReefer ] = useState(false);
	const [ loading, setLoading ] = useState(false);

	const [ addContainerType, addContainerTypeMutation ] = useMutation(gql`mutation Mutation($containerType: ContainerTypeCreation!) {
		addContainerType(containerType: $containerType) { id }
	}`);

	const process = useCallback(() => {
		setLoading(true);
		const containerType = { displayName: displayName, fullName: fullName, isoCode: isoCode.toUpperCase(), teu: teu, height: height, length: length, weight: weight, isReefer: isReefer };
		addContainerType({ variables: { containerType: containerType } }).finally(() => { refetch(); onClose(); });
	}, [ displayName, fullName, isoCode, teu ]);

	return <Modal isOpen={isOpen} onClose={onClose} title="Nieuw containertype">
		{!addContainerTypeMutation.loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<WithLabel label="Weergavenaam">
				<InputField value={displayName} onChange={setDisplayName} />
			</WithLabel>
			<WithLabel label="Volledige naam">
				<InputField value={fullName} onChange={setFullName} />
			</WithLabel>
			<WithLabel label="ISO-code">
				<InputField value={isoCode} onChange={setIsoCode} />
			</WithLabel>
			<WithLabel label="TEU">
				<InputField value={teu} onChange={setTeu} />
			</WithLabel>
			<WithLabel label="Hoogte (mm)">
				<InputField value={height} onChange={setHeight} />
			</WithLabel>
			<WithLabel label="Lengte (mm)">
				<InputField value={length} onChange={setLength} />
			</WithLabel>
			<WithLabel label="Tarragewicht">
				<InputField value={weight} onChange={setWeight} />
			</WithLabel>
			<WithLabel label="Reefer">
				<Toggle value={isReefer} onChange={(v) => setIsReefer(v)} />
			</WithLabel>

			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: displayName.length > 0, error: "Weergavenaam mag niet leeg zijn" },
						{ valid: displayName.length < 15, error: "Weergavenaam moet kleiner zijn dan 15 karakters" },
						{ valid: fullName.length > 0, error: "Volledige naam mag niet leeg zijn" },
						{ valid: fullName.length < 200, error: "Volledige naam moet kleiner zijn dan 200 karakters" },
						{ valid: !isNaN(teu) && teu > 0, error: "TEU moet een geheel getal zijn" },
						{ valid: teu < 100.0, error: "Aantal TEU moet kleiner zijn dan 100" },
					]}>
					<span className="fa fa-plus" />&nbsp; Toevoegen
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{addContainerTypeMutation.loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}