import React from 'react';

export default function Duration({ value, unit = 'auto', detailed = true }) {

	if (value == null) return "-";

	let minusSignIfNeeded = '';
	if (value < 0) {
		minusSignIfNeeded = '-';
		// eslint-disable-next-line
		value = Math.abs(value);
	}

	if (unit == 's' || (unit == 'auto' && value < 60)) {
		return <span className="duration">{minusSignIfNeeded.length != 0 ? minusSignIfNeeded : '+'}{value}<Unit value="s" /></span>;
	} else if (unit == 'm' || (unit == 'auto' && value < 3600)) {
		if (!detailed || value % 60 == 0) {
			return <span className="duration">{minusSignIfNeeded.length != 0 ? minusSignIfNeeded : '+'}{Math.round(value / 60)}<Unit value="min" /></span>;
		} else {
			return <span className="duration">{minusSignIfNeeded.length != 0 ? minusSignIfNeeded : '+'}{Math.floor(value / 60)}<Unit value="min" /> {Math.floor((value % 60))}<Unit value="s" /></span>;
		}

	} else if (unit == 'h' || (unit == 'auto' && value < 24 * 3600)) {
		if (!detailed || value % 3600 == 0 || (Math.floor(value % 3600 / 60) == 0)) {
			return <span className="duration">{minusSignIfNeeded.length != 0 ? minusSignIfNeeded : '+'}{Math.round(value / 3600)}<Unit value="uur" /></span>;
		} else {
			return <span className="duration">{minusSignIfNeeded.length != 0 ? minusSignIfNeeded : '+'}{Math.floor(value / 3600)}<Unit value="uur" /> {Math.floor((value % 3600) / 60)}<Unit value="min" /></span>;
		}

	} else {
		// If the number of hours is 24, then push the day count up and remove hours; this prevents the 1d 24hours UI bug
		if (!detailed || value % 86400 == 0 || (Math.floor(value % 86400 / 3600) == 0)) {
			return <span className="duration">{minusSignIfNeeded.length != 0 ? minusSignIfNeeded : '+'}{Math.round(value / 86400)}<Unit value="d" /></span>;
		} else {
			return <span className="duration">{minusSignIfNeeded.length != 0 ? minusSignIfNeeded : '+'}{Math.floor(value / 86400)}<Unit value="d" /> {Math.floor((value % 86400) / 3600)}<Unit value="uur" /></span>;
		}
	}
}

export function Unit({ value }) {
	return <span style={{ color: 'var(--col-grey-500)', fontSize: 'var(--fs-10)' }}>{value}</span>;
}
