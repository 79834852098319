import React from 'react';
import { FormattedDate } from 'react-intl';

function RelevantDate({ date, relativeTo = new Date() }) {
	if (date == null) return '-';
	const jsDate = typeof date == 'string' ? Date.parse(date) : date;
	const diffDays = Math.abs((jsDate - relativeTo) / 1000 / 60 / 60 / 24);

	// Use long formatting if more than 90 days from now (or relative date), use shortened format otherwise
	if (diffDays > 90) {
		return <FormattedDate value={jsDate} year='numeric' month='2-digit' day='2-digit' />;
	} else {
		return <FormattedDate value={jsDate} month='2-digit' day='2-digit' weekday='short' />;
	}
}

export default function Window({ earliest, latest }) {
	if (earliest == null && latest == null) {
		return <span className="window">-</span>;
	} else if (earliest != null) {
		return <span className="window"><RelevantDate date={earliest} /> &rsaquo;</span>;
	} else if (latest != null) {
		return <span className="window">&lsaquo; <RelevantDate date={latest} /></span>;
	} else {
		return <span className="window"><RelevantDate date={earliest} /> t/m <RelevantDate date={latest} /></span>;
	}
}