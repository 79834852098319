import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Modal from '../lib/ui/Modal';
import ButtonArea from '../lib/forms/ButtonArea';
import ValidatedButton from '../lib/forms/ValidatedButton';
import TerminalSelectField from '../lib/selectfields/TerminalSelectField';
import ShipSelectField from '../lib/selectfields/ShipSelectField';
import DateTimeField from '../lib/forms/DateTimeField';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Form, InputField, WithLabel } from '@atrocit/scl';

export default function EditCallModal({ isOpen, onClose, refetch, callId }) {
	const [ terminalId, setTerminalId ] = useState(null);
	const [ shipId, setShipId ] = useState(null);
	const [ callReference, setCallReference ] = useState(null);
	const [ pta, setPta ] = useState(DateTime.now().startOf('day'));
	const [ ptd, setPtd ] = useState(DateTime.now().plus({ days: 1 }).startOf('day'));
	const [ remark, setRemark ] = useState('');
	const [ isNotEmptyCall, setIsNotEmptyCall ] = useState(false);

	const callQuery = useQuery(gql`query Query($callId: Int!) {
		callById(callId: $callId) {
			id, ship {
				id,
            }, 
			terminal {
				id,
            }, 
			callReference, pta, ptd, remark
		}
	}`, { variables: { callId }, onCompleted: (c) => {
		setTerminalId(c.callById.terminal.id);
		setShipId(c.callById.ship != null ? c.callById.ship.id : null);
		setCallReference(c.callById.callReference);
		setPta(fromIso(c.callById.pta));
		setPtd(fromIso(c.callById.ptd));
		setRemark(c.callById.remark);
	} });

	const checkForBookings = useQuery(gql`query Query($callId: Int!) {
		checkForBookingsOnCall(callId: $callId)
	}`, { variables: { callId }, onCompleted: (c => {
		setIsNotEmptyCall(c.checkForBookingsOnCall);
	}) });

	const [ editCall, editCallMutation ] = useMutation(gql`mutation Mutation($callId: Int!, $call: CallPartialUpdate!) {
        editCall(callId: $callId, call: $call) { id }
    }`);

	const process = useCallback(() => {
		const call = { callId: callId, terminalId: terminalId, shipId: shipId, callReference: callReference, pta: pta.toUTC().toISO(), ptd: ptd.toUTC().toISO(), remark };
		editCall({ variables: { callId: callId, call: call } }).finally(() => { refetch(); onClose(); });
	}, [ terminalId, shipId, callReference, pta, ptd, remark ]);

	return <Modal isOpen={isOpen} onClose={onClose} title="Call bewerken">
		{!editCallMutation.loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<WithLabel label="Terminal">
				<TerminalSelectField onChange={id => setTerminalId(id)} value={terminalId != null ? terminalId : []} isDisabled={isNotEmptyCall} />
			</WithLabel>
			<WithLabel label="Schip">
				<ShipSelectField onChange={id => setShipId(id)} value={shipId != null ? shipId : []} isDisabled={isNotEmptyCall} />
			</WithLabel>
			<WithLabel label="Callreferentie">
				<InputField value={callReference} onChange={setCallReference} />
			</WithLabel>
			<WithLabel label="Geplande aankomst (PTA)">
				<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPta(dt) : null)} value={pta} />
			</WithLabel>
			<WithLabel label="Gepland vertrek (PTD)">
				<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setPtd(dt) : null)} value={ptd} />
			</WithLabel>
			<WithLabel label="Opmerkingen">
				<InputField onChange={setRemark} value={remark} />
			</WithLabel>
			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: terminalId != null, error: "Vul een terminal in" },
						{ valid: pta != null, error: "Geplande aankomst (PTA) moet ingevuld zijn" },
						{ valid: ptd != null, error: "Gepland vertrek (PTD) moet ingevuld zijn" },
					]}>
					<span className="fa fa-check" />&nbsp; Opslaan
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{editCallMutation.loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}

export function fromIso(iso) {
	return iso == null ? null : DateTime.fromISO(iso);
}

export function fromIsoFast(isoString) {
	if (typeof isoString != 'string') return isoString;
	return DateTime.fromMillis(+Date.parse(isoString));
}