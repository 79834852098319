import { useIntl } from 'react-intl';

export default function NumberValue({ value, digits = 2, ...props }) {
	const intl = useIntl();
	const parts = intl.formatNumberToParts(value, { ...props });
	const isInteger = Math.round(value) == value;

	return <span className={"number-value" + (isInteger ? ' number-value-int' : '')}>
		{parts.map((p, idx) => <span key={idx} className={"number-value-part" + " number-value-part-" + p.type}>{p.value}</span>)}
	</span>;
}