import React, { useState } from 'react';
import Modal from '../../lib/ui/Modal';
import TerminalSelectField from '../../lib/selectfields/TerminalSelectField';
import ButtonArea from '../../lib/forms/ButtonArea';
import { gql, useMutation } from '@apollo/client';
import LoadingOverlay from '../../lib/ui/LoadingOverlay';
import { Button, Form, WarningBanner, WithLabel } from '@atrocit/scl';

export default function ChangeTrajectoryCommand({ containerTransports, onClose }) {
	const numberOfPickupTerminals = new Set(containerTransports.map(ct => ct.pickupTerminal?.id)).size;
	const numberOfDropoffTerminals = new Set(containerTransports.map(ct => ct.dropoffTerminal?.id)).size;

	const [ pickupTerminal, setPickupTerminal ] = useState(numberOfPickupTerminals == 1 ? containerTransports[0].pickupTerminal?.id : null);
	const [ dropoffTerminal, setDropoffTerminal ] = useState(numberOfDropoffTerminals == 1 ? containerTransports[0].dropoffTerminal?.id : null);

	const [ changeTrajectoryMutation, { loading } ] = useMutation(gql`mutation Mutation($containerTransports: [ Int! ]!, $pickupTerminal: Int!, $dropoffTerminal: Int!) {
		changeTrajectory(containerTransports: $containerTransports, pickupTerminal: $pickupTerminal, dropoffTerminal: $dropoffTerminal) { id }
	}`);

	return <Modal title="Traject wijzigen" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<div style={{ width: 'var(--u-384)' }}>
			<Form onSubmit={() => changeTrajectoryMutation({ variables: { containerTransports: containerTransports.map(ct => ct.id), pickupTerminal, dropoffTerminal } }).then(onClose)}>
				<div>Wijziging van het traject voor <span className="bold">{containerTransports.length}</span> container(s)</div>

				<div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 'var(--u-8)', padding: 'var(--u-12) 0' }}>
					{numberOfPickupTerminals > 1 && <WarningBanner>Er worden <span className="bold">{numberOfPickupTerminals}</span> verschillende vanaf-terminals gewijzigd</WarningBanner>}
					{numberOfDropoffTerminals > 1 && <WarningBanner>Er worden <span className="bold">{numberOfDropoffTerminals}</span> verschillende naar-terminals gewijzigd</WarningBanner>}
				</div>

				<div className="col-2">
					<div>
						<WithLabel label="Van">
							<TerminalSelectField onChange={setPickupTerminal} value={pickupTerminal} />
						</WithLabel>
					</div>
					<div>
						<WithLabel label="Naar">
							<TerminalSelectField onChange={setDropoffTerminal} value={dropoffTerminal} />
						</WithLabel>
					</div>
				</div>

				<ButtonArea>
					<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp;Wijzigen</Button>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</div>
	</Modal>;
}