import { useEffect, useState } from 'react';

export default function CopyToClipboardText({ value }) {
	const [ copyAnimation, setCopyAnimation ] = useState(false);

	useEffect(() => {
		if (copyAnimation) {
			const t = setTimeout(() => setCopyAnimation(false), 300);
			return () => clearTimeout(t);
		}
	}, [ copyAnimation ]);

	return <span
		className={'copy-to-clipboard' + (copyAnimation ? ' copy-to-clipboard-animating' : ' ')}
		onClick={() => {
			try {
				if (navigator == null || navigator.clipboard == null || navigator.clipboard.writeText == null) return;
				navigator.clipboard.writeText(value);
				setCopyAnimation(true);
			} catch (e) { console.error(e); }
		}}>
		{value}
	</span>;
}