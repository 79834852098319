import { gql, useMutation } from '@apollo/client';
import Modal from '../../lib/ui/Modal';
import { useCallback, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { Button, Form, InputField, WithLabel } from '@atrocit/scl';

export default function AddTerminalModal({ isOpen, onClose, refetch }) {
	const [ displayName, setDisplayName ] = useState("");
	const [ fullName, setFullName ] = useState("");
	const [ portUnloCode, setPortUnloCode ] = useState("");
	const [ loading, setLoading ] = useState(false);

	const [ addTerminal ] = useMutation(gql`mutation Mutation($terminal: TerminalCreation!) {
        addTerminal(terminal: $terminal) { id }
    }`);

	const process = useCallback(() => {
		setLoading(true);
		const terminal = { displayName: displayName, fullName: fullName, portUnloCode: portUnloCode };
		addTerminal({ variables: { terminal: terminal } }).finally(() => { refetch(); onClose(); });
	}, [ displayName, fullName, portUnloCode ]);

	return <Modal isOpen={isOpen} onClose={onClose} title="Nieuw terminal">
		{!loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<WithLabel label="Weergavenaam">
				<InputField value={displayName} onChange={setDisplayName} />
			</WithLabel>
			<WithLabel label="Volledige naam">
				<InputField value={fullName} onChange={setFullName} />
			</WithLabel>
			<WithLabel label="UN-locatiecode">
				<InputField value={portUnloCode} onChange={setPortUnloCode} placeholder="BEANR" />
			</WithLabel>
			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: displayName.length > 0, error: "Weergavenaam mag niet leeg zijn" },
						{ valid: displayName.length < 200, error: "Weergavenaam is te lang" },
						{ valid: fullName.length > 0, error: "De volledige naam mag niet leeg zijn" },
						{ valid: fullName.length < 200, error: "De volledige naam is te lang" },
						{ valid: portUnloCode.length > 0, error: "Unlo mag niet leeg zijn" },
						{ valid: portUnloCode.length <= 5, error: "Unlo is te lang" },
					]}>
					<span className="fa fa-plus" />&nbsp; Toevoegen
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}