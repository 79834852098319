import React from 'react';
import { DateTime } from 'luxon';

export function parseValue(value) {
	if (typeof value == 'string') { // assume ISO-format: YYYY-MM-DDTHH:MM:SSZ
		return DateTime.fromJSDate(new Date(Date.parse(value)));
	} else if (typeof value == 'number') { // assume timestamp in ms
		return DateTime.fromJSDate(new Date(value));
	} else if (value instanceof Date) { // assume it's a Date object already
		return DateTime.fromJSDate(value);
	} else if (value == null) {
		return value;
	} else {
		throw new Error('Unknown date format');
	}
}

export default function TimeShow({ value, disableTime = false, disableDate = false, compressedTime = false, time = null }) {
	const d = parseValue(value);
	const t = parseValue(time);
	if (d == null) return <span>-</span>;

	const difference = t == null ? { days: 0 } : d.diff(t, [ 'days' ]).toObject();

	// Shows DD-MM-YYYY
	const datePart = Math.abs(difference.days) <= 90 ? d.toFormat('EEE dd-MM HH\'u\'') : d.toFormat('dd-MM-yyyy');
	// Shows HH:MM
	// const timePart = (d.getHours() + '').padStart(2, '0') + (!compressedTime ? (':' + (d.getMinutes() + '').padStart(2, '0')) : "u");
	return <span>{datePart}</span>;
}

export function TimeOnlyShow({ value, relativeTo }) {
	const d = parseValue(value);
	const r = parseValue(relativeTo);

	const daysDiff = diffInDays(r, d);

	return <span className="time-only">{d.toFormat('HH:mm')}<span className="time-only-day-diff">{daysDiff == 0 ? '' : (daysDiff > 0 ? ('+' + daysDiff) : daysDiff)}</span></span>;
}

function diffInDays(a, b) {
	const ad = a.startOf('day');
	const bd = b.startOf('day');
	return bd.diff(ad, 'days').toObject().days;
}