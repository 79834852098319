import { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Modal from '../lib/ui/Modal';
import ButtonArea from '../lib/forms/ButtonArea';
import { Button, Form } from '@atrocit/scl';

function move(list, fromIdx, toIdx) {
	const element = list[fromIdx];
	const removedList = [ ...list.slice(0, fromIdx), ...list.slice(fromIdx + 1, list.length) ]; // make a copy of list without the element
	return [ ...removedList.slice(0, toIdx), element, ...removedList.slice(toIdx, removedList.length) ]; // return a new list with the element in the toIdx position
}

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	padding: '4px 0',
	margin: `0 0 var(--u-2) 0`,
	borderRadius: '4px',
	top: '0',
	left: '0',
	overflow: 'hidden',
	background: 'var(--col-grey-100)',
	color: 'var(--col-grey-700)',
	boxShadow: '0 0 1px inset rgba(0, 0, 0, 0.3)',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = isDraggingOver => ({
	// if you want to have the background change colors while something is being dragged
	// background: isDraggingOver ? "lightblue" : "white",
	width: '100%',
	maxHeight: 'calc(100vh - 300px)',
	overflow: 'auto',
});

export default function SelectShipsModal({ onClose, ships, shipsOrder, setShipsOrder, selectedShips, setSelectedShips }) {
	const [ selection, setSelection ] = useState(selectedShips);
	const [ order, setOrder ] = useState((shipsOrder.length != 0 && shipsOrder.length == Object.keys(ships).length) ? shipsOrder : Object.keys(ships).sort((a, b) => (ships[a].name < ships[b].name ? -1 : 1)));

	const onDragEnd = useCallback(result => {
		if (!result.destination) return;
		setOrder(move(order, result.source.index, result.destination.index));
	}, [ order, setOrder ]);

	return <Modal onClose={onClose} title="Zichtbare schepen">
		<Form onSubmit={() => {
			setSelectedShips(selection);
			setShipsOrder(order);
			onClose();
		}}>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{order.map((id, index) => (
								<Draggable key={id} draggableId={id} index={index}>
									{(provided, snapshot) => {
										if (snapshot.isDragging) {
											provided.draggableProps.style.left = provided.draggableProps.style.offsetLeft;
											provided.draggableProps.style.top = provided.draggableProps.style.offsetTop;
										}
										return <div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}>
											<div style={{
												display: 'grid',
												gridTemplateColumns: '12px 24px 1fr 32px',
												gap: 'var(--u-12) var(--u-16)',
											}}>
												<div>
													{ships[id].fleet_colors?.split('::').map(c => <div key={c} style={{ width: '4px', height: 'calc(100% + 8px)', margin: '-4px 0', background: c }} />)}
												</div>
												<div style={{ textAlign: 'center' }}>
													<input type="checkbox" id="ship" name="ship"
													       checked={selection.includes(ships[id].id)}
													       onChange={() => setSelection(selection.includes(ships[id].id) ? selection.filter(x => x != ships[id].id) : [ ...selection, ships[id].id ])}/>
												</div>
												<div>
													{ships[id].name}
												</div>
												<div style={{ textAlign: 'center' }}>
													<span className="fa fa-sort" style={{ color: 'var(--col-grey-500)' }} />
												</div>
											</div>
										</div>;
									}}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<ButtonArea>
				<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp; Opslaan</Button>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}