import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { sum } from './BookingView';

export default function Travel({ ship, prevCalls, nextCall, shipLoad }) {
	let duration;
	if (prevCalls.length > 0 && nextCall != null) {
		const prevCall = prevCalls[prevCalls.length - 1];
		const departure = DateTime.fromISO(prevCall?.std);
		const arrival = DateTime.fromISO(nextCall?.sta);
		duration = arrival.diff(departure, [ 'hours', 'minutes' ]).toObject();
	}

	const initialTeu = shipLoad?.current_load_teu || 0;
	const initialWeight = shipLoad?.current_load_weight || 0;

	const { totalTeu, totalWeight } = useMemo(() => {
		let totalTeu = initialTeu;
		prevCalls.forEach(call => {
			totalTeu += sum((call.loadList ?? []).map(d => d.total_teu));
			totalTeu -= sum((call.unloadList ?? []).map(d => d.total_teu));
		});

		let totalWeight = initialWeight;
		prevCalls.forEach(call => {
			totalWeight += sum((call.loadList ?? []).map(d => d.weight));
			totalWeight -= sum((call.unloadList ?? []).map(d => d.weight));
		});

		return { totalTeu, totalWeight };
	}, [ prevCalls, initialTeu ]);

	const overCapacityTeu = ship.teucapacity < totalTeu;
	const overCapacityWeight = ship.weightcapacity < totalWeight;

	return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'var(--col-grey-500)', gap: 'var(--u-8)', paddingTop: 'var(--u-8)', paddingBottom: 'var(--u-8)' }}>
		<div style={{ width: '75px', borderTop: '1px solid var(--col-grey-500)' }} />
		{/* {duration != null && <>*/}
		{/*	<div>*/}
		{/*		{Math.round(duration.hours)} u {Math.round(duration.minutes)} vaartijd*/}
		{/*	</div>*/}
		{/*	<div style={{ width: '10px', borderTop: '1px solid var(--col-grey-500)' }} />*/}
		{/* </>}*/}
		<div style={{ padding: '4px', background: overCapacityTeu ? '#e43' : undefined, color: overCapacityTeu ? '#fff' : undefined, borderRadius: '4px' }}>
			{overCapacityTeu && <span><span className="fa fa-warning" />&nbsp;</span>}
			{Math.round(totalTeu) || 0} teu
		</div>
		<div style={{ width: '10px', borderTop: '1px solid var(--col-grey-500)' }} />
		<div style={{ padding: '4px', background: overCapacityWeight ? '#e43' : undefined, color: overCapacityWeight ? '#fff' : undefined, borderRadius: '4px' }}>
			{overCapacityWeight && <span><span className="fa fa-warning" />&nbsp;</span>}
			{Math.round(totalWeight / 1000)} ton
		</div>
		<div style={{ width: '75px', borderTop: '1px solid var(--col-grey-500)' }} />
	</div>;
}