import Card from '../../lib/ui/Card';
import CardPadding from '../../lib/ui/CardPadding';
import BookingView, { computeTextColor, sum } from './BookingView';
import TimeShow from '../../lib/TimeShow';
import { DateTime } from 'luxon';
import LabelContent from '../../lib/forms/LabelContent';
import ProgressBar from '../../lib/ui/ProgressBar';
import { useMemo } from 'react';
import { TrafficLight } from './CallView';
import { useDrop } from 'react-dnd';
import { gql, useMutation } from '@apollo/client';
import { WithLabel } from '@atrocit/scl';

export default function CallViewCollapsed({ call, terminals, time }) {

	const [ planCtsAutomatic ] = useMutation(gql`mutation Mutation($containerTransports: [ Int! ]!, $call: Int!) {
        planContainerTransportsAutomatic(containerTransports: $containerTransports, call: $call) { id }
    }`);

	const [ { isOver, canDrop }, drop ] = useDrop(() => ({
		accept: 'CONTAINER_TRANSPORTS',
		drop: (droppedItem) => {
			planCtsAutomatic({ variables: { containerTransports: droppedItem, call: call.callId } })
				.finally(result => console.log(result)).catch(err => console.log(err));
		},
	}));

	const { totalProcessed, totalContainers } = useMemo(() => {
		const ld = call.loadList != null ? 0 : 0;
		const uld = call.unloadList != null ? 0 : 0;
		const totLd = call.loadList != null ? sum(call.loadList.map(d => d.totalContainers)) : 0;
		const totUld = call.unloadList != null ? sum(call.unloadList.map(d => d.totalContainers)) : 0;
		return {
			totalContainers: totLd + totUld,
			totalProcessed: ld + uld,
		};
	}, [ call ]);

	return <Card style={{ position: 'relative', width: '25%' }} ref={drop}>
		<div style={{ display: 'flex', flex: '1 0 max-content', flexDirection: 'column', alignItems: 'stretch' }}>
			<div className="card-ribbons" style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
				{(call.colors ?? '').split('::').map(color => <div className="card-left-ribbon" style={{ background: color, width: 'var(--u-4)', height: '100%' }} />)}
			</div>
			<div style={{ flex: '1' }}>
				<CardPadding>
					<div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 'var(--u-12) var(--u-16)' }}>
						<div>
							{/* TODO: COLOR HEX FOR TERMINALS*/}
							<div style={{ fontSize: '1.5em', background: terminals[call.terminalId]?.colorhex ?? '', color: computeTextColor(terminals[call.terminalId]?.colorhex ?? null), display: 'inline-block', padding: '4px', borderRadius: '4px', boxShadow: '0 0 2px inset rgba(0, 0, 0, 0.3)' }}>
								{terminals[call.terminalId]?.displayName ?? '-'}
							</div>
							<div style={{ padding: '4px ' }}>
								<div>
									<span style={{ fontWeight: 'bold' }}>Geplande aankomst:</span> <TimeShow value={call.plannedArrival} disableDate={true} />
								</div>
								<div>
									<span style={{ fontWeight: 'bold' }}>Gepland vertrek:</span> <TimeShow value={call.plannedDeparture} disableDate={true} />
								</div>
								<div>
									{DateTime.fromISO(call.plannedArrival).toLocal().day} {DateTime.fromISO(call.plannedArrival).toLocal().monthShort}
								</div>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateRows: '1fr', gap: 'var(--u-12) var(--u-16)' }}>
							<div>
								<WithLabel label="Termijnen referentie">
									<LabelContent>{call.callReference || '-'}</LabelContent>
								</WithLabel>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateRows: '1fr', gap: 'var(--u-12) var(--u-16)' }}>
							<div>
								<WithLabel label="Opmerkingen">
									<LabelContent>{call.remark || '-'}</LabelContent>
								</WithLabel>
							</div>
						</div>
						<div style={{ display: 'grid', gridTemplateRows: '1fr', gap: 'var(--u-12) var(--u-16)' }}>
							<div>
								<WithLabel label="Status">
									<LabelContent>
										<div style={{ display: 'flex', alignItems: 'start', gap: 'var(--u-4)' }}>
											<div style={{ paddingTop: 'var(--u-4)' }}>
												<TrafficLight call={call} />
											</div>
											<div style={{ flex: 1 }}>
												<ProgressBar processed={totalProcessed} total={totalContainers} />
											</div>
										</div>
									</LabelContent>
								</WithLabel>
							</div>
						</div>
						<div style={{ display: 'flex', gridTemplateRows: '1fr 1fr', gap: 'var(--u-12) var(--u-16)' }}>
							<div>
								<span style={{ fontWeight: 'bold' }}>Unload</span>
								{call.unloadList != null && <div>
									<span>Tot. containers: {sum(call.unloadList.map(d => d.totalContainers))}</span>
									<br/>
									<span>Tot. TEU: {sum(call.unloadList.map(d => d.totalTeu))}</span>
								</div>}
							</div>
							<div>
								<span style={{ fontWeight: 'bold' }}>Load</span>
								{call.loadList != null && <div>
									<span>Tot. containers: {sum(call.loadList.map(d => d.totalContainers))}</span>
									<br/>
									<span>Tot. TEU: {sum(call.loadList.map(d => d.totalTeu))}</span>
								</div>}
							</div>
						</div>
					</div>
				</CardPadding>
			</div>
		</div>
	</Card>;
}