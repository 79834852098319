import { gql, useQuery } from '@apollo/client';
import Breadcrumbs from '../../lib/Breadcrumbs';
import LoadingRow from '../../lib/tables/LoadingRow';
import { useState } from 'react';
import AddContainerTypeModal from './AddContainerTypeModal';
import EditContainerTypeModal from './EditContainerTypeModal';
import BooleanIndicator from '../../lib/tables/BooleanIndicator';
import { Button } from '@atrocit/scl';
import NumberValue from '../../lib/tables/NumberValue';
import PageTop from '../../lib/ui/PageTop';
import SearchField from '../../lib/forms/SearchField';


export default function ContainerTypeOverview() {
	const [ query, setQuery ] = useState('');
	const [ showAddContainerTypeModal, setShowAddContainerTypeModal ] = useState(false);
	const [ showEditContainerTypeModal, setShowEditContainerTypeModal ] = useState(false);
	const [ editContainerTypeId, setEditContainerTypeId ] = useState(null);
	const backendQuery = useQuery(gql`{
        containerTypes {
            id,
            displayName,
            fullName,
            isoCode,
            teu,
	        height,
	        length,
	        weight,
	        isReefer,
        }
    }`);
	const containerTypes = (backendQuery?.data?.containerTypes ?? []);
	const filteredContainerTypes = containerTypes.filter(ct => ct.displayName.toLowerCase().includes(query.toLowerCase()) || ct.fullName.toLowerCase().includes(query.toLowerCase()) || ct.isoCode.toLowerCase().includes(query.toLowerCase()));

	return <div className="page">
		{showAddContainerTypeModal && <AddContainerTypeModal isOpen={showAddContainerTypeModal} onClose={() => setShowAddContainerTypeModal(false)} refetch={() => backendQuery.refetch()} />}
		{showEditContainerTypeModal && <EditContainerTypeModal isOpen={showEditContainerTypeModal} onClose={() => setShowEditContainerTypeModal(false)} refetch={() => backendQuery.refetch()} containerTypeId={editContainerTypeId} />}

		<PageTop
			breadcrumbSegments={[
				{ link: '/containertypes', label: 'Containertypes' },
				{ link: '/containertypes', label: 'Overzicht' },
			]}>
			<Button onClick={() => setShowAddContainerTypeModal(true)}><span className="fa fa-plus" />&nbsp; Nieuw containertype</Button>
		</PageTop>

		<SearchField onChange={setQuery} value={query} hasResults={filteredContainerTypes.length} />

		<table className="table table-fw">
			<colgroup>
				<col width="100" />
				<col width="*" />
				<col width="60" />
				<col width="50" />
				<col width="90" />
				<col width="90" />
				<col width="90" />
				<col width="50" />
				<col width="*" />
			</colgroup>
			<thead>
				<tr>
					<th>Weergavenaam</th>
					<th>Volledige naam</th>
					<th>ISO-code</th>
					<th className="tbl-align-right" width="45">TEU</th>
					<th className="tbl-align-right">Hoogte</th>
					<th className="tbl-align-right">Lengte</th>
					<th className="tbl-align-right">Tarra</th>
					<th className="tbl-center">Reefer</th>
					<th width="120" />
				</tr>
			</thead>
			<tbody>
				{backendQuery.loading && <LoadingRow />}
				{filteredContainerTypes.map(ct => <tr key={ct.id}>
					<td title={ct.displayName}>{ct.displayName}</td>
					<td title={ct.fullName}>{ct.fullName}</td>
					<td title={ct.isoCode}>{ct.isoCode}</td>
					<td className="tbl-align-right"><NumberValue value={ct.teu} minimumFractionDigits={2} maximumFractionDigits={2} /></td>
					<td className="tbl-align-right">{ct.height != null && <NumberValue value={ct.height} unit="millimeter" unitDisplay="short" style="unit" />}</td>
					<td className="tbl-align-right">{ct.length != null && <NumberValue value={ct.length} unit="millimeter" unitDisplay="short" style="unit" />}</td>
					<td className="tbl-align-right">{ct.weight != null && <NumberValue value={ct.weight} unit="kilogram" unitDisplay="short" style="unit" />}</td>
					<td className="tbl-center"><BooleanIndicator value={ct.isReefer} /></td>
					<td style={{ display: "flex", justifyContent: "center", gap: "var(--u-16)" }}>
						<Button onClick={() => { setEditContainerTypeId(ct.id); setShowEditContainerTypeModal(true); }}><span className="fa fa-pencil" />&nbsp; Bewerken</Button>
					</td>
				</tr>)}
				{!backendQuery.loading && containerTypes.length == 0 && <tr>
					<td colSpan={100}>Geen resultaten</td>
				</tr>}
			</tbody>
		</table>
	</div>;
}
