import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Modal from '../../lib/ui/Modal';
import LoadingOverlay from '../../lib/ui/LoadingOverlay';
import { Button, Form, InputField, Toggle, WarningBanner, WithLabel } from '@atrocit/scl';
import ButtonArea from '../../lib/forms/ButtonArea';
import DateTimeField from '../../lib/forms/DateTimeField';
import SelectField from '../../lib/selectfields/SelectField';
import ValidatedButton from '../../lib/forms/ValidatedButton';

export default function DropoffWindowCommand({ containerTransports, onClose }) {
	const [ earliestChangeType, setEarliestChangeType ] = useState('IGNORE');
	const [ latestChangeType, setLatestChangeType ] = useState('IGNORE');

	const [ earliestDropoff, setEarliestDropoff ] = useState(DateTime.now().startOf('day'));
	const [ latestDropoff, setLatestDropoff ] = useState(DateTime.now().plus({ days: 1 }).startOf('day'));

	const earliestDropoffsAllEqual = useMemo(() => new Set(containerTransports.filter(ct => ct.earliestDropoff != null && ct.earliestDropoff.length > 0).map(ct => ct.earliestDropoff)).size <= 1, [ containerTransports ]);
	const latestDropoffsAllEqual = useMemo(() => new Set(containerTransports.filter(ct => ct.latestDropoff != null && ct.latestDropoff.length > 0).map(ct => ct.latestDropoff)).size <= 1, [ containerTransports ]);
	const earliestDropoffAfterLatestDropoff = useMemo(() => new Set(containerTransports.filter(ct => ct.earliestDropoff != null && DateTime.fromISO(ct.earliestDropoff) >= latestDropoff)).size > 0, [ containerTransports, latestDropoff ]);
	const latestDropoffBeforeEarliestDropoff = useMemo(() => new Set(containerTransports.filter(ct => ct.latestDropoff != null && DateTime.fromISO(ct.latestDropoff) <= earliestDropoff)).size > 0, [ containerTransports, earliestDropoff ]);

	const [ updateDropoffWindowMutation, { loading } ] = useMutation(gql`mutation Mutation($containerTransports: [ Int! ]!, $earliestDropoff: Instant, $latestDropoff: Instant, $earliestChangeType: PickupDropoffWindowChangeType!, $latestChangeType: PickupDropoffWindowChangeType!) {
        updateDropoffWindow(containerTransports: $containerTransports, earliestDropoff: $earliestDropoff, latestDropoff: $latestDropoff, earliestChangeType: $earliestChangeType, latestChangeType: $latestChangeType) { id }
    }`);

	return <Modal title="Inleverwindows wijzigen" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<div style={{ width: 'var(--u-512)' }}>
			<Form onSubmit={() => updateDropoffWindowMutation({ variables: { containerTransports: containerTransports.map(ct => ct.id), earliestDropoff: earliestDropoff.toUTC().toISO(), latestDropoff: latestDropoff.toUTC().toISO(), earliestChangeType, latestChangeType } }).then(onClose)}>
				<div>Wijziging van de inleverwindows voor <span className="bold">{containerTransports.length}</span> container(s)</div>

				<div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 'var(--u-8)', padding: 'var(--u-12) 0' }}>
					{!earliestDropoffsAllEqual && earliestChangeType != 'IGNORE' && <WarningBanner>Er worden meerdere verschillende vroegste inleverdatums overschreven</WarningBanner>}
					{!latestDropoffsAllEqual && latestChangeType != 'IGNORE' && <WarningBanner>Er worden meerdere verschillende laatste inleverdatums overschreven</WarningBanner>}
					{latestChangeType != 'IGNORE' && earliestDropoffAfterLatestDropoff && <WarningBanner>De opgegeven laatste inleverdatum valt vóór de vroegste inleverdatum van sommige container(s)</WarningBanner>}
					{earliestChangeType != 'IGNORE' && latestDropoffBeforeEarliestDropoff && <WarningBanner>De opgegeven vroegste inleverdatum valt na de laatste inleverdatum van sommige container(s)</WarningBanner>}
				</div>

				<div className="col-2">
					<div>
						<WithLabel label="Wijziging">
							<SelectField
								options={[
									{ value: 'IGNORE', label: 'Behoud bestaande' },
									{ value: 'REMOVE', label: 'Verwijder bestaande' },
									{ value: 'OVERWRITE', label: 'Overschrijf met...' },
								]}
								onChange={opt => setEarliestChangeType(opt?.value)}
								value={earliestChangeType} />
						</WithLabel>

						<WithLabel label="Vroegste inlevermoment">
							<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setEarliestDropoff(dt) : null)}
							                                                    value={earliestDropoff}
							                                                    nullable={earliestChangeType != 'OVERWRITE'} />
						</WithLabel>
					</div>
					<div>
						<WithLabel label="Wijziging">
							<SelectField
								options={[
									{ value: 'IGNORE', label: 'Behoud bestaande' },
									{ value: 'REMOVE', label: 'Verwijder bestaande' },
									{ value: 'OVERWRITE', label: 'Overschrijf met...' },
								]}
								onChange={opt => setLatestChangeType(opt?.value)}
								value={latestChangeType} />
						</WithLabel>

						<WithLabel label="Laatste inlevermoment">
							<DateTimeField onChange={dt => (new DateTime(dt).isValid ? setLatestDropoff(dt) : null)}
							                                                  value={latestDropoff}
							                                                  nullable={latestChangeType != 'OVERWRITE'} />
						</WithLabel>
					</div>
				</div>

				<ButtonArea>
					<ValidatedButton level="primary" type="submit" validations={[
						{ valid: earliestChangeType != null, error: "Er moet een wijzingstype voor vroegste inlevermoment geselecteerd zijn" },
						{ valid: latestChangeType != null, error: "Er moet een wijzigingstype voor laatste inlevermoment geselecteerd zijn" },
					]}>
						<span className="fa fa-check" />&nbsp;Wijzigen
					</ValidatedButton>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</div>
	</Modal>;
}