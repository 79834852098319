import { DateTime } from 'luxon';
import LocalDateField from '../lib/forms/simple/LocalDateField';
import { Button, Toggle } from '@atrocit/scl';

const daysOfWeek = [ { 'id': 1, 'label': 'Ma' }, { 'id': 2, 'label': 'Di' }, { 'id': 3, 'label': 'Wo' }, { 'id': 4, 'label': 'Do' }, { 'id': 5, 'label': 'Vr' }, { 'id': 6, 'label': 'Za' }, { 'id': 7, 'label': 'Zo' } ];

export default function TopBar({ loading, showSelectShipModal, time, setTime, setShowUnplanned, showUnplanned, setCompactMode }) {

	function calculateWeekdayToDate(currentWeekday, targetWeekday) {
		if (targetWeekday - currentWeekday == 0) return;
		if (targetWeekday - currentWeekday > 0) {
			setTime(time.plus({ day: targetWeekday - currentWeekday }));
		} else {
			if (targetWeekday - currentWeekday < 0) {
				setTime(time.minus({ day: Math.abs(targetWeekday - currentWeekday) }));
			}
		}
	}

	return <div className='topbar' style={{ position: 'relative', zIndex: 1000 }}>
		<div className="button-bar">
			<Button onClick={showSelectShipModal}><span className="fa fa-ship" />&nbsp; Schepen</Button>
		</div>
		<div className="week-selector">
			<Button onClick={() => setTime(time.minus({ day: 7 }))}>-1</Button>
			<div style={{ padding: '0 var(--u-4)' }}>
				<span>Week {time.weekNumber}</span>
			</div>
			<Button onClick={() => setTime(time.plus({ day: 7 }))}>+1</Button>
		</div>
		<div className="day-selector">
			{daysOfWeek.map((day) => {
				return <Button onClick={() => calculateWeekdayToDate(time.weekday, day.id)} level={time.weekday == day.id ? 'primary' : 'secondary'} active={time.weekday == day.id} style={{ fontWeight: 'bold', fontSize: 'var(--fs-9)' }}>
					{day.label}<br />{time.startOf('week').plus({ day: day.id - 1 }).day} {time.startOf('week').plus({ day: day.id - 1 }).monthShort}
				</Button>;
			})}
			<div style={{ width: 'var(--u-8)' }} />
			<Button onClick={() => setTime(DateTime.now())}>Vandaag</Button>
			<LocalDateField onChange={t => setTime(DateTime.fromFormat(t.toString(), 'yyyy-MM-dd'))} time={time} style={{ height: 'var(--u-36)', borderRadius: '4px', padding: '0 var(--u-4)' }} />
			<Button onClick={() => setTime(time.plus({ day: 1 }))}>+1</Button>
			<Button onClick={() => setTime(time.plus({ day: 2 }))}>+2</Button>
		</div>
		<div style={{ display: 'flex', alignItems: 'center', paddingLeft: 'var(--u-16)' }}>
			<Toggle
				value={showUnplanned}
				onChange={() => {
					setShowUnplanned(!showUnplanned);
					setCompactMode(!showUnplanned);
				}} />&nbsp;
			Planmodus
		</div>
		{/* <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 'var(--u-16)' }}>*/}
		{/*	<Toggle*/}
		{/*		value={showUnplanned}*/}
		{/*		onChange={() => {*/}
		{/*			setShowUnplanned(!showUnplanned);*/}
		{/*			setCompactMode(!showUnplanned);*/}
		{/*		}} />&nbsp;*/}
		{/*	Compact*/}
		{/* </div>*/}

		<div style={{ flex: 1 }} />

		<div></div>

		<div style={{ width: '48px' }}>
			{loading && <span style={{ fontSize: '1.5em', color: 'var(--col-primary-500)' }} className="fa fa-circle-o-notch fa-spin" />}
		</div>
	</div>;
}