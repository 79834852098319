import React, { useEffect, useState } from 'react';
import SideBarLink from './SideBarLink';
import { Button } from '@atrocit/scl';
import { useHotkeys } from 'react-hotkeys-hook';
import usePersistentState from '../../hooks/usePersistentState';
import atrocitLogoWhite from '../../assets/atrocit-logo-4096-white.png';
import FeatureFlagBarrier from '../configuration/FeatureFlagBarrier';

export default function SideBar() {
	const [ displayName, setDisplayName ] = useState("");
	const [ collapsed, setCollapsed ] = usePersistentState(false, 'bmblb.navSidebarCollapse');

	useHotkeys('m', () => setCollapsed(c => !c));

	useEffect(() => {
		fetch('/api/whoami', { method: 'get' }).then(r => r.json()).then(data => setDisplayName(data.fullName));
	}, []);

	return <nav className={"sidebar " + (!collapsed ? "" : "sidebar-collapsed")}>
		<div className="sidebar-transform-layer">
			<div className="sidebar-header">
				<img src={atrocitLogoWhite} alt="Atrocit Logo" style={{ maxWidth: 'calc(100% - 12px)' }} />
			</div>

			<div className="sidebar-content">
				<FeatureFlagBarrier flag={'BOOKINGS_MODULE'}>
					<SideBarLink to="/bookings" label="Boekingen" icon="book" />
				</FeatureFlagBarrier>

				<FeatureFlagBarrier flag={'CALLS_MODULE'}>
					<SideBarLink to="/calls" label="Calls" icon="calendar-o" />
				</FeatureFlagBarrier>

				<FeatureFlagBarrier flag={'PLANNING_MODULE'}>
					<SideBarLink to="/dashboard" label="Planning" icon="calendar" />
				</FeatureFlagBarrier>

				<FeatureFlagBarrier flag={'CONNECTIONS_MODULE'}>
					<SideBarLink to="/connections" label="Connecties" icon="anchor" />
				</FeatureFlagBarrier>

				<SideBarLink to="/masterdata" label="Stamdata" icon="database" itemExpanded={true}>
					<FeatureFlagBarrier flag={'BOOKINGS_MODULE'}>
						<SideBarLink to="/masterdata/relations" label="Relaties" icon="address-book" />
					</FeatureFlagBarrier>
					<FeatureFlagBarrier flag={'CALLS_MODULE'}>
						<SideBarLink to="/masterdata/ships" label="Schepen" icon="ship" />
					</FeatureFlagBarrier>
					<SideBarLink to="/masterdata/terminals" label="Terminals" icon="map-marker" />
					<FeatureFlagBarrier flag={'BOOKINGS_MODULE'}>
						<SideBarLink to="/masterdata/containertypes" label="Containertypes" icon="cube" />
					</FeatureFlagBarrier>
					<FeatureFlagBarrier flag={'USERS_MODULE'}>
						<SideBarLink to="/masterdata/users" label="Gebruikers" icon="user" />
					</FeatureFlagBarrier>
				</SideBarLink>
			</div>

			<div style={{ width: 'var(--u-64)', textAlign: 'center', display: 'flex', justifyContent: 'center', paddingLeft: 'var(--u-4)' }}>
				<Button level="tertiary" onClick={() => setCollapsed(!collapsed)}>
					<span className={'fa ' + (collapsed ? 'fa-expand' : 'fa-compress')} style={{ transform: 'rotate(45deg)' }} aria-hidden="true" />
				</Button>
			</div>

			{/* {!collapsed ? <div className="login-info">*/}
			{/*	<span className="login-info-user">{displayName}</span><br /><br />*/}

			{/*	<Button onClick={() => {}}><span className="fa fa-sign-out" />&nbsp; {!collapsed ? " Uitloggen" : ""}</Button>*/}
			{/* </div> :*/}
			{/*	<div className="login-info-collapsed">*/}
			{/*		<Button onClick={() => {}} style={{ marginBottom: 'var(--u-16)' }}><span className="fa fa-sign-out" /></Button>*/}
			{/*	</div>}*/}
		</div>
	</nav>;
}