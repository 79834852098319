import { gql, useQuery } from '@apollo/client';
import { sum } from './BookingView';
import { Button, Form } from '@atrocit/scl';
import ButtonArea from '../../lib/forms/ButtonArea';

export default function ManifestExcelExport({ callId, isDropoff, terminal, date, children }) {

	const query = useQuery(gql`query Query($callId: Int!) {
		containerTransportsForCall(callId: $callId) {
			id, containerType {
				isoCode, length, teu
            },
			containerNumber, booking {
				customer {
					displayName
                },
            },
			pickupTerminal {
				displayName	
            },
			pickupReference,
			dropoffTerminal {
				displayName
            },
			dropoffReference,
			grossWeight,
			full,
			pickupCall {
				id
            },
			dropoffCall {
				id
            },
			overLengthFront, overLengthBack, overHeight, overWidthLeft, overWidthRight, dangerousGoods,
		}
	}`, { variables: { callId } });

	return <Form onSubmit={() => {

		const containerList = query.data.containerTransportsForCall.filter(c => (isDropoff ? c.pickupCall != null : c.dropoffCall != null));
		// const ship = isDropoff ? containerList[0].seashipIncoming : containerList[0].seashipOutgoing;
		const ship = ''; // TODO: implement this

		const t = "MANIFEST " + (isDropoff ? "INBOUND JOURNEY " : "OUTBOUND JOURNEY ") + terminal + ' / ' + ship + ' / ' + date;

		const title = [ { title: t } ];

		const header = [
			{ barge: ship },
			{ reis: date },
			{ next_stop: "TERMINAL" },
			{ total_containers: containerList.length },
			{ total_teu: sum(containerList.map(c => c.containerType.teu ?? 0)) },
			{ total_kgs: sum(containerList.map(c => c.grossWeight ?? 0)) },
		];

		const totals = [
			{ twentyftvol: containerList.filter(c => c.full != null && c.full == true && c.containerType != null && c.containerType.length == 20).length },
			{ thirtyftvol: containerList.filter(c => c.full != null && c.full == true && c.containerType != null && c.containerType.length == 30).length },
			{ fortyftvol: containerList.filter(c => c.full != null && c.full == true && c.containerType != null && c.containerType.length == 40).length },
			{ fortyfiveftvol: containerList.filter(c => c.full != null && c.full == true && c.containerType != null && c.containerType.length == 45).length },
			{ twentyftleeg: containerList.filter(c => c.full != null && c.full == false && c.containerType != null && c.containerType.length == 20).length },
			{ thirtyftleeg: containerList.filter(c => c.full != null && c.full == false && c.containerType != null && c.containerType.length == 30).length },
			{ fortyftleeg: containerList.filter(c => c.full != null && c.full == false && c.containerType != null && c.containerType.length == 40).length },
			{ fortyfiveftleeg: containerList.filter(c => c.full != null && c.full == false && c.containerType != null && c.containerType.length == 45).length },
		];

		const columns = [
			{ name: '#', width: 20 },
			{ name: 'Container', width: 11 },
			{ name: 'Type', width: 11 },
			{ name: 'V/L', width: 11 },
			{ name: 'Bruto (kg)', width: 11 },
			{ name: 'Rederij', width: 15 },
			{ name: 'Uithaalterminal', width: 15 },
			{ name: 'Inleverterminal', width: 15 },
			{ name: !isDropoff ? 'Inleverreferentie' : 'Uithaalreferentie', width: 15 },
			{ name: 'Naar zeeschip', width: 15 },
			{ name: 'Bestemming', width: 10 },
			{ name: 'Gevaargoed', width: 10 },
			{ name: 'Lading', width: 10 },
			{ name: 'Zegel', width: 10 },
			{ name: 'overHeight', width: 7 },
			{ name: 'overWidthLeft', width: 7 },
			{ name: 'overWidthRight', width: 7 },
			{ name: 'overLengthBack', width: 7 },
			{ name: 'overLengthFront', width: 7 },
		];

		const excelRows = containerList.map((row, i) => {
			return {
				[columns[0].name]: i + 1,
				[columns[1].name]: row.containerNumber,
				[columns[2].name]: row.containerType.isoCode,
				[columns[3].name]: row.full ? 'V' : 'L',
				[columns[4].name]: row.grossWeight,
				[columns[5].name]: row.pickupTerminal.displayName,
				[columns[6].name]: row.dropoffTerminal.displayName,
				[columns[7].name]: !isDropoff ? row.pickupReference : row.dropoffReference,
				[columns[8].name]: row.seashipOutgoing,
				[columns[9].name]: "TO BE IMPLEMENTED",
				[columns[10].name]: row.dangerousGoods,
				[columns[11].name]: "TO BE IMPLEMENTED",
				[columns[12].name]: "TO BE IMPLEMENTED",
				[columns[13].name]: "TO BE IMPLEMENTED",
				[columns[14].name]: row.overHeight != null ? row.overHeight : 0,
				[columns[15].name]: row.overWidthLeft != null ? row.overWidthLeft : 0,
				[columns[16].name]: row.overWidthRight != null ? row.overWidthRight : 0,
				[columns[17].name]: row.overLengthBack != null ? row.overLengthBack : 0,
				[columns[18].name]: row.overLengthFront != null ? row.overLengthFront : 0,
			};
		});

		import('../../../util/exportToExcel.js').then(({ default: exportToExcel }) => exportToExcel(columns, excelRows, title, header, totals));
	}}>
		<ButtonArea>
			<Button type="submit">
				{children}
			</Button>
		</ButtonArea>
	</Form>;
}