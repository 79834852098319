import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';

function formatValue(value) {
	if (value == null) return '';
	return value.split('-').reverse().join('-');
}

function lpz(str, len) {
	let output = '' + str;
	while (output.length < len) {
		output = '0' + output;
	}
	return output;
}

export default function LocalDateField({ onChange, time, placeholder, style }) {
	const inputRef = useRef();
	const ignoreNextBlur = useRef(false);
	const value = time.toLocaleString();

	const [ textValue, setTextValue ] = useState(null);
	const [ editSinceFocus, setEditSinceFocus ] = useState(false);
	const [ monthOffset, setMonthOffset ] = useState(moment().month() + 1 - time.month + (moment().year() - time.year) * 12);
	const [ showPicker, setShowPicker ] = useState(false);

	useEffect(() => {
		// Need to compute the offset of months, without overflowing on a new year.
		setMonthOffset(moment().month() + 1 - time.month + (moment().year() - time.year) * 12);
	}, [ value ]);

	const viewingDate = moment();
	viewingDate.subtract(monthOffset, 'months');
	const displayValue = ((editSinceFocus || textValue) ? textValue : formatValue(value)) || '';

	return <div className="date-picker">
		<div>
			<input
				style={style}
				type="text"
				className="scl-form-element form-element date-picker-field"
				ref={inputRef}
				onBlur={e => {
					if (ignoreNextBlur.current) {
						e.preventDefault();
						if (inputRef.current) setTimeout(() => inputRef.current.focus(), 0);
						ignoreNextBlur.current = false;
					} else {
						// Try and match typed date
						const txt = (textValue || '').replace(/\//g, '-');
						let date = '';
						if (txt.match(/^[0-9]{4}$/)) {
							date = txt.slice(0, 2) + '-' + txt.slice(2, 4) + '-' + moment().year();
						} else if (txt.match(/^[0-9]{6}$/)) {
							date = txt.slice(0, 2) + '-' + txt.slice(2, 4) + '-' + ((parseInt(txt.slice(4, 6), 10) >= 70) ? '19' : '20') + txt.slice(4, 6);
						} else if (txt.match(/^[0-9]{8}$/)) {
							date = txt.slice(0, 2) + '-' + txt.slice(2, 4) + '-' + txt.slice(4, 8);
						} else if (txt.match(/^[0-9]{1,2}-[0-9]{1,2}$/)) {
							date = lpz(txt.split('-')[0], 2) + '-' + lpz(txt.split('-')[1], 2) + '-' + moment().year();
						} else if (txt.match(/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{2}$/)) {
							const parts = txt.split('-');
							date = lpz(parts[0], 2) + '-' + lpz(parts[1], 2) + '-' + ((parseInt(parts[2], 10) >= 70) ? '19' : '20') + parts[2];
						} else if (txt.match(/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/)) {
							const parts = txt.split('-');
							date = lpz(parts[0], 2) + '-' + lpz(parts[1], 2) + '-' + parts[2];
						} else if (txt.match(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/)) {
							const parts = txt.split('-');
							date = lpz(parts[2], 2) + '-' + lpz(parts[1], 2) + '-' + parts[0];
						}

						// Emit update to parent
						if (date != '' && moment(date, 'DD-MM-YYYY').isValid()) {
							onChange(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
						} else if (editSinceFocus) {
							onChange(null);
						}

						// Reset
						setShowPicker(false);
						setEditSinceFocus(false);
						setTextValue(null);
					}
				}}
				onFocus={() => setShowPicker(true)}
				onChange={e => {
					setEditSinceFocus(true);
					setTextValue(e.target.value);
				}}
				value={displayValue}
				placeholder={placeholder} />
		</div>
		<button className="btn btn-flat" type="button" onClick={() => inputRef.current && inputRef.current.focus()}>
			<span className="fa fa-calendar" />
		</button>
		<div className="date-picker-dropdown" style={{ display: showPicker ? 'block' : 'none' }} onMouseDown={() => { ignoreNextBlur.current = true; }}>
			<div className="date-picker-header">
				<button type="button" onClick={() => setMonthOffset(monthOffset + 1)} className="btn btn-flat">
					<i className="fa fa-chevron-left" />
				</button>
				<span>{viewingDate.format('MMMM YYYY')}</span>
				<button type="button" onClick={() => setMonthOffset(monthOffset - 1)} className="btn btn-flat">
					<i className="fa fa-chevron-right" />
				</button>
			</div>
			<table>
				<thead>
					<tr>
						<td>Ma</td>
						<td>Di</td>
						<td>Wo</td>
						<td>Do</td>
						<td>Vr</td>
						<td>Za</td>
						<td>Zo</td>
					</tr>
				</thead>
				<tbody>
					<RenderDropDownButtons
						onSelect={date => {
							setEditSinceFocus(false);
							setTextValue(null);
							onChange(date);
						}}
						viewingDate={viewingDate}
						value={value == null ? null : moment(value, 'DD-MM-YYYY')} />
				</tbody>
			</table>
		</div>
	</div>;
}

function RenderDropDownButtons({ value, viewingDate, onSelect }) {
	const date = moment(viewingDate);
	date.date(1);
	date.isoWeekday(1);
	const output = [];
	for (let week = 0; (viewingDate.month() >= date.month() && viewingDate.year() >= date.year()) || viewingDate.year() > date.year(); week++) {
		const cells = [];
		for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
			const classes = [];
			if (date.month() != viewingDate.month()) classes.push('greyed');
			if (value != null && date.year() == value.year() && date.month() == value.month() && date.date() == value.date()) classes.push('selected');
			cells.push(<td className={classes.join(' ')} key={week + '-' + dayOfWeek} onClick={onSelect.bind(null, moment(date).format('YYYY-MM-DD'))}>
				{date.format('D')}
			</td>);
			date.add(1, 'days');
		}
		output.push(<tr key={week}>{cells}</tr>);
	}
	return output;
}

