import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import Breadcrumbs from '../../lib/Breadcrumbs';
import SearchField from '../../lib/forms/SearchField';
import LoadingRow from '../../lib/tables/LoadingRow';
import AddTerminalModal from './AddTerminalModal';
import EditTerminalModal from './EditTerminalModal';
import DeleteTerminalModal from './DeleteTerminalModal';
import { Button, DropdownButton } from '@atrocit/scl';
import PageTop from '../../lib/ui/PageTop';

export default function TerminalOverview() {
	const [ query, setQuery ] = useState('');
	const [ showDeleteTerminalModal, setShowDeleteTerminalModal ] = useState(false);
	const [ deleteTerminalId, setDeleteTerminalId ] = useState(null);
	const [ showAddTerminalModal, setShowAddTerminalModal ] = useState(false);
	const [ showEditTerminalModal, setShowEditTerminalModal ] = useState(false);
	const [ editTerminalId, setEditTerminalId ] = useState(null);
	const backendQuery = useQuery(gql`{
        terminals {
            id,
            displayName,
            fullName,
            portUnloCode,
        }
    }`);
	const terminals = (backendQuery?.data?.terminals ?? []);

	const results = useMemo(() => {
		if (query == '') return terminals;

		return terminals.filter(t => t.displayName.toLowerCase().includes(query.toLowerCase()) || t.fullName.toLowerCase().includes(query.toLowerCase()) || t.portUnloCode.toLowerCase().includes(query.toLowerCase()));
	}, [ query, terminals ]);

	return <div className="page">
		{showDeleteTerminalModal && <DeleteTerminalModal isOpen={showDeleteTerminalModal} onClose={() => setShowDeleteTerminalModal(false)} terminalId={deleteTerminalId} refetch={() => backendQuery.refetch()} />}
		{showAddTerminalModal && <AddTerminalModal isOpen={showAddTerminalModal} onClose={() => setShowAddTerminalModal(false)} refetch={() => backendQuery.refetch()} />}
		{showEditTerminalModal && <EditTerminalModal isOpen={showEditTerminalModal} onClose={() => setShowEditTerminalModal(false)} refetch={() => backendQuery.refetch()} terminalId={editTerminalId} />}

		<PageTop
			breadcrumbSegments={[
				{ link: '/terminals', label: 'Terminals' },
				{ link: '/terminals', label: 'Overzicht' },
			]}>
			<Button onClick={() => setShowAddTerminalModal(true)}><span className="fa fa-plus" />&nbsp; Nieuwe terminal</Button>
		</PageTop>

		<SearchField onChange={setQuery} value={query} hasResults={results.length != 0} />

		<table className="table table-fw">
			<thead>
				<tr>
					<th width="120">Weergavenaam</th>
					<th>Volledige naam</th>
					<th width="70">UN-loc.</th>
					<th width="165" />
				</tr>
			</thead>
			<tbody>
				{backendQuery.loading && <LoadingRow />}
				{(results || []).map(t => <tr key={t.id}>
					<td title={t.displayName}>{t.displayName}</td>
					<td title={t.fullName}>{t.fullName}</td>
					<td>{t.portUnloCode}</td>
					<td style={{ display: "flex", justifyContent: "space-around", gap: "var(--u-16)" }}>
						<DropdownButton mainAction={<Button onClick={() => { setEditTerminalId(t.id); setShowEditTerminalModal(true); }}><span className="fa fa-pencil" />&nbsp; Bewerken</Button>}>
							<Button className="danger" onClick={() => { setDeleteTerminalId(t.id); setShowDeleteTerminalModal(true); }}><span className="fa fa-trash" />&nbsp; Verwijderen</Button>
						</DropdownButton>
					</td>
				</tr>)}
				{!backendQuery.loading && results.length == 0 && <tr>
					<td colSpan={4}>Geen resultaten</td>
				</tr>}
			</tbody>
		</table>
	</div>;
}