import React from 'react';
import { DateTime } from 'luxon';
import { InputField } from '@atrocit/scl';

export default function DateTimeField({ onChange, value, disabled = false, nullable = false }) {
	const date = value.toFormat('yyyy-MM-dd');
	const time = value.toFormat('HH:mm');

	return <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 'var(--u-16)' }}>
		{!nullable ?
			<>
				<div>
					<InputField type="date" onChange={d => onChange(DateTime.fromISO(d + 'T' + time))} value={date} disabled={disabled} />
				</div>
				<div>
					<InputField type="time" onChange={t => onChange(DateTime.fromISO(date + 'T' + t))} value={time} disabled={disabled} />
				</div>
			</>
			:
			<>
				<div>
					<InputField type="date" value={''} disabled={true} />
				</div>
				<div>
					<InputField type="time" value={''} disabled={true} />
				</div>
			</>}
	</div>;
}