import BookingView, { computeTextColor, sum } from './BookingView';
import React, { useMemo, useState } from 'react';
import Card from '../../lib/ui/Card';
import { TimeOnlyShow } from '../../lib/TimeShow';
import LabelContent from '../../lib/forms/LabelContent';
import ProgressBar from '../../lib/ui/ProgressBar';
import { Button, WithLabel } from '@atrocit/scl';
import ManifestExcelExport from './ManifestExcelExport';
import { useDrop } from 'react-dnd';
import { gql, useMutation } from '@apollo/client';
import EditCallModal from '../../calls/EditCallModal';

export default function CallView({ call, terminals, time, selectedCts, setSelectedCts }) {
	const [ editCallId, setEditCallId ] = useState(null);
	const [ showEditCallModal, setShowEditCallModal ] = useState(false);

	const [ planCtsAutomatic ] = useMutation(gql`mutation Mutation($containerTransports: [ Int! ]!, $call: Int!) {
		planContainerTransportsAutomatic(containerTransports: $containerTransports, call: $call) { id }
	}`);

	const [ { isOver, canDrop }, drop ] = useDrop(() => ({
		accept: 'CONTAINER_TRANSPORTS',
		drop: (droppedItem) => {
			return {
				mutation: planCtsAutomatic({ variables: { containerTransports: droppedItem, call: call.callId } }).finally(result => console.log(result)).catch(err => console.log(err)),
			};
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	}));
	const { totalProcessed, totalContainers } = useMemo(() => {
		const ld = call.loadList != null ? sum(call.loadList.map(d => d.amountLoaded)) : 0;
		const uld = call.unloadList != null ? sum(call.unloadList.map(d => d.amountUnloaded)) : 0;
		const totLd = call.loadList != null ? sum(call.loadList.map(d => d.totalContainers)) : 0;
		const totUld = call.unloadList != null ? sum(call.unloadList.map(d => d.totalContainers)) : 0;
		return {
			totalContainers: totLd + totUld,
			totalProcessed: ld + uld,
		};
	}, [ call ]);

	return <Card className="call-view-card" style={{ position: 'relative', boxShadow: isOver ? '0 0 0 4px inset var(--col-primary-300)' : '' }} ref={drop}>
		{showEditCallModal && <EditCallModal isOpen={showEditCallModal} onClose={() => setShowEditCallModal(false)} refetch={() => {}} callId={editCallId} />}

		<div style={{ display: 'flex', flex: '1 0 max-content', flexDirection: 'row', alignItems: 'stretch' }}>
			<div style={{ flex: '1' }}>
				<div style={{ display: 'grid', gridTemplateColumns: '0.4fr 1fr 1fr min-content', gap: '0 var(--u-48)', borderBottom: '1px solid var(--col-grey-100)', background: 'var(--col-primary-50)', padding: 'var(--u-8) var(--u-16)' }}>
					<div style={{ paddingTop: 'var(--u-4)' }}>
						{/* TODO: COLOR HEX FOR TERMINALS*/}
						<div style={{ display: 'flex', alignItems: 'center', gap: 'var(--u-4)' }}>
							<div style={{ fontSize: '1.5em', color: computeTextColor(terminals[call.terminalId]?.colorhex ?? null), display: 'inline-block' }}>
								{terminals[call.terminalId]?.displayName ?? '-'}
							</div>
							<div style={{ color: 'var(--col-grey-500)' }}>
									&rsaquo;
							</div>
							<div style={{ color: 'var(--col-grey-500)' }}>
								{call.callReference || '---'}
							</div>
						</div>
						<div style={{ paddingTop: 'var(--u-4)', color: 'var(--col-grey-500)' }}>
							<TimeOnlyShow value={call.plannedArrival} relativeTo={time} /> - <TimeOnlyShow value={call.plannedDeparture} relativeTo={time} />
						</div>
					</div>


					<div style={{ display: 'grid', gridTemplateRows: '1fr', gap: 'var(--u-12) var(--u-16)' }}>
						<div>
							<WithLabel isFake={true} label="Opmerkingen">
								<LabelContent>{call.remark || '-'}</LabelContent>
							</WithLabel>
						</div>
					</div>

					<div style={{ display: 'grid', gridTemplateRows: '1fr', gap: 'var(--u-12) var(--u-16)', paddingTop: 'var(--u-4)' }}>
						<div>
							{/* <WithLabel isFake={true} label="Status">*/}
							{/*	<LabelContent>*/}
							<div style={{ display: 'flex', alignItems: 'start', gap: 'var(--u-4)' }}>
								<div style={{ paddingTop: 'var(--u-4)' }}>
									<TrafficLight call={call} />
								</div>
								<div style={{ flex: 1 }}>
									<ProgressBar processed={totalProcessed} total={totalContainers} />
								</div>
							</div>
							{/*	</LabelContent>*/}
							{/* </WithLabel>*/}
						</div>
					</div>


					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ display: 'flex', justifyContent: 'right' }}>
							<Button onClick={() => { setEditCallId(call.callId); setShowEditCallModal(true); }}><span className="fa fa-pencil" /></Button>
						</div>
					</div>
				</div>
				<div style={{ marginTop: 'var(--u-16)' }} className="call-view-unload">
					{call.unloadList != null && call.unloadList.length > 0 && <div>
						<WithLabel isFake={true} label="Dossiers te lossen">
							<div style={{ paddingTop: 'var(--u-4)' }}>
								<BookingView call={call} terminals={terminals} isLoad={false} time={time} selectedCts={selectedCts} setSelectedCts={setSelectedCts} />
							</div>
							<div style={{ textAlign: 'right', padding: '0 var(--u-16)' }}>
								<ManifestExcelExport callId={call.callId} isDropoff={false} terminal={terminals[call.terminalId]?.displayName ?? '-'} date={call.plannedArrival}>
									<span className="fa fa-file-excel-o" />&nbsp; Losmanifest
								</ManifestExcelExport>
							</div>
						</WithLabel>
					</div>}
				</div>
				<div style={{ marginTop: 'var(--u-16)', marginBottom: 'var(--u-16)' }} className="call-view-load">
					{call.loadList != null && call.loadList.length > 0 && <div>
						<WithLabel isFake={true} label="Dossiers te laden">
							<div style={{ paddingTop: 'var(--u-4)' }}>
								<BookingView call={call} terminals={terminals} isLoad={true} time={time} selectedCts={selectedCts} setSelectedCts={setSelectedCts} />
							</div>
							<div style={{ textAlign: 'right', padding: '0 var(--u-16)' }}>
								<ManifestExcelExport callId={call.callId} isDropoff={true} terminal={terminals[call.terminalId]?.displayName ?? '-'} date={call.plannedArrival}>
									<span className="fa fa-file-excel-o" />&nbsp; Laadmanifest
								</ManifestExcelExport>
							</div>
						</WithLabel>
					</div>}
				</div>
				{/* </CardPadding>*/}
			</div>
		</div>
	</Card>;
}

export function TrafficLight({ call }) {
	const color = {
		'NOT_STARTED': '#FF0300',
		'DATA_SENT': '#FF9001',
		'IN_PROGRESS': '#FCFF00',
		'DONE': '#018000',
	}[call.call_status] || 'white';

	const nextStatus = {
		'NOT_STARTED': 'DATA_SENT',
		'DATA_SENT': 'IN_PROGRESS',
		'IN_PROGRESS': 'DONE',
		'DONE': 'NOT_STARTED',
	}[call.call_status] || 'NOT_STARTED';

	// The PUT method here won't work unless the page is served from the same domain as STACK itself due to CORS
	return <div onClick={() => fetch('https://webarge.cofanostack.com/api/bargeLoadingSequences/part/' + call.id + '?status=' + nextStatus, { method: 'PUT' })} style={{ width: '12px', height: '12px', borderRadius: '50%', background: color, boxShadow: '0 0 2px inset rgba(0, 0, 0, 0.3)' }} />;
}