import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import FormattedDateTime from '../lib/formatting/FormattedDateTime';
import Breadcrumbs from '../lib/Breadcrumbs';
import { fromIsoFast } from '../calls/EditCallModal';
import Duration from '../lib/ui/Duration';
import VesselEta from '../lib/domain/VesselEta';
import VesselAta from '../lib/domain/VesselAta';
import { DateTime } from 'luxon';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Toggle, WithLabel } from '@atrocit/scl';
import UserContext from '../../context/UserContext';
import FeatureFlagBarrier from '../configuration/FeatureFlagBarrier';
import ConnectionPageChart from './ConnectionPageChart';

export default function ViewConnection() {
	const { id } = useParams();
	const user = useContext(UserContext);
	const [ timelineStart, setTimelineStart ] = useState(DateTime.now().minus({ 'days': 7 }));
	const [ timelineEnd, setTimelineEnd ] = useState(DateTime.now().plus({ 'days': 7 }));

	const connectionQuery = useQuery(gql`query Query($id: Int!) {
		connectionById(connectionId: $id) {
			id,
			seaship {
				id,
				imoNumber,
				shipName,
            },
			terminal {
				id,
				displayName,
				fullName,
            },
			pickups {
				id,
            },
			dropoffs {
				id,
            },
			voyageNumberInbound,
			voyageNumberOutbound,
			snapshots {
				id,
				vesselEta,
				vesselAta,
				dataSource,
				createdAt
            },
			latestSnapshot {
				vesselEta,
				vesselAta,
				createdAt,
            },
			subscriptions {
				id,
				user { id, fullName }
            }
		}
	}`, { variables: { id } });
	const connection = connectionQuery?.data?.connectionById;
	const sortedConnections = connection != null ? connection.snapshots.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)) : [];

	const callsForConnectionQuery = useQuery(gql`query Query($id: Int!) {
		callsForSeashipConnection(connectionId: $id) {
			id,
			pta,
			ptd,
			terminal {
				id, displayName, fullName,
            }
			ship {
				id, name
            },
			unloadList {
				id,
            },
			loadList {
				id,
            },
		}
	}`, { variables: { id } });
	const calls = callsForConnectionQuery?.data?.callsForSeashipConnection;
	// collects all the unique barges from the calls
	const barges = calls != null ? new Set(calls.map(c => c.ship)) : [];

	const [ subscribeToEtaUpdates ] = useMutation(gql`mutation Mutation($id: Int!, $margin: Duration!) {
		subscribeToConnection(connectionId: $id, margin: $margin) { id }
	}`);

	const [ unsubscribeToEtaUpdates ] = useMutation(gql`mutation Mutation($id: Int!) {
        removeSubscription(connectionId: $id)
    }`);

	useEffect(() => {
		if (connection != null) {
			setTimelineStart(calculateTimelineEdges(connection, true, calls));
			setTimelineEnd(calculateTimelineEdges(connection, false, calls));
		}
	}, [ connection, calls ]);

	function calculateTimelineEdges(connection, isStart, calls) {
		if (calls != null && calls.length > 0) {

			const connPickupSet = new Set(connection.pickups.map(conn => conn.id));
			const connDropoffSet = new Set(connection.dropoffs.map(conn => conn.id));

			// gets the earliest call based on pta (only out of the calls that have relevant containers for the seaship)
			const filteredCalls = calls.filter(c => c.unloadList.filter(ct => (connDropoffSet.has(ct.id))).length > 0 || (c.loadList.filter(ct => connPickupSet.has(ct.id)).length > 0));
			const earliestCallPta = DateTime.min.apply(null, filteredCalls.map(c => fromIsoFast(c.pta)));
			// gets the latest call based on ptd
			const latestCallPtd = DateTime.max.apply(null, filteredCalls.map(c => fromIsoFast(c.ptd)));

			let connectionArrivalDate = DateTime.now();
			if (connection.latestSnapshot.vesselAta != null) {
				connectionArrivalDate = connection.latestSnapshot.vesselAta;
			}
			if (connection.latestSnapshot.vesselEta != null && connection.latestSnapshot.vesselAta == null) {
				connectionArrivalDate = connection.latestSnapshot.vesselEta;
			}

			// the earliest date is the minimum between the connection arrival date minus 14 days and the earliest call pta
			const earliestDate = DateTime.fromSeconds(Math.min(fromIsoFast(connectionArrivalDate).minus({ 'days': 7 }).toSeconds(), earliestCallPta.toSeconds()));
			// the latest date is the maximum between the connection arrival date plus 14 days and the latest call ptd
			const latestDate = DateTime.fromSeconds(Math.max(fromIsoFast(connectionArrivalDate).plus({ 'days': 7 }).toSeconds(), latestCallPtd.toSeconds()));

			return isStart ? earliestDate : latestDate;
		} else {
			// in case there are no calls associated with this connection, make the timeline edges the connection arrival date plus/minus 14 days
			if (connection.latestSnapshot.vesselAta != null) {
				return isStart ? fromIsoFast(connection.latestSnapshot.vesselAta).minus({ 'days': 7 }) : fromIsoFast(connection.latestSnapshot.vesselAta).plus({ 'days': 7 });
			}
			if (connection.latestSnapshot.vesselEta != null && connection.latestSnapshot.vesselAta == null) {
				return isStart ? fromIsoFast(connection.latestSnapshot.vesselEta).minus({ 'days': 7 }) : fromIsoFast(connection.latestSnapshot.vesselEta).plus({ 'days': 7 });
			}
		}
	}

	function calculateStartPosition(startTime) {
		// the total timeframe represents the amount of seconds from the start of the timeline to the end of the timeline
		// 0% is the start of the timeline while 100% is the end of the timeline
		// so we know that the value of totalTimeframe represents 100% from the timeline
		const totalTimeframe = fromIsoFast(timelineEnd).toSeconds() - fromIsoFast(timelineStart).toSeconds();
		// so to find what % is the distance from the start of the timeline to a certain point on it, we apply the following formula
		return ((fromIsoFast(startTime).toSeconds() - fromIsoFast(timelineStart).toSeconds()) / totalTimeframe) * 100;
	}

	function calculateCallWidth(callPta, callPtd) {
		// same deal as calculateStartPosition(), but this time we calculate the start percentage and end percentage of a call (pta, ptd)
		const totalTimeframe = fromIsoFast(timelineEnd).toSeconds() - fromIsoFast(timelineStart).toSeconds();
		const callStartPercentage = ((fromIsoFast(callPta).toSeconds() - fromIsoFast(timelineStart).toSeconds()) / totalTimeframe);
		const callEndPercentage = ((fromIsoFast(callPtd).toSeconds() - fromIsoFast(timelineStart).toSeconds()) / totalTimeframe);
		// the width of the call represents the percentage difference from end and start
		return (callEndPercentage - callStartPercentage) * 100;
	}

	function calculateTimeDifference(cs, idx) {
		if (idx == 0) return null;
		const nextSnapshot = sortedConnections[idx - 1];
		if (nextSnapshot.vesselAta == null && nextSnapshot.vesselEta != null) {
			if (cs.vesselAta == null && cs.vesselEta != null) {
				return fromIsoFast(nextSnapshot.vesselEta).diff(fromIsoFast(cs.vesselEta), [ 'seconds' ]).toObject().seconds;
			}
			if (cs.vesselAta != null) {
				return fromIsoFast(nextSnapshot.vesselEta).diff(fromIsoFast(cs.vesselAta), [ 'seconds' ]).toObject().seconds;
			}
		}
		if (nextSnapshot.vesselAta != null) {
			if (cs.vesselAta == null && cs.vesselEta != null) {
				return fromIsoFast(nextSnapshot.vesselAta).diff(fromIsoFast(cs.vesselEta), [ 'seconds' ]).toObject().seconds;
			}
			if (cs.vesselAta != null) {
				return fromIsoFast(nextSnapshot.vesselAta).diff(fromIsoFast(cs.vesselAta), [ 'seconds' ]).toObject().seconds;
			}
		}
	}

	const otherSubscriptions = connection?.subscriptions?.filter(s => s?.user?.id != user.id) ?? [];

	return <div className="page">
		<Breadcrumbs
			segments={[
				{ link: '/connections', label: 'Connecties' },
				{ link: '/connections', label: 'Vessel Arrivals' },
				{ link: '/connections/' + id, label: connection?.seaship?.shipName + ' @ ' + connection?.terminal?.displayName },
			]} />

		{connection != null && <div>
			<div style={{ display: 'grid', gridTemplateColumns: '1fr 200px', padding: 'var(--u-24) 0', borderBottom: '1px solid var(--col-grey-200)' }}>
				<div>
					<h2 style={{ marginTop: 0 }}>{connection.seaship?.shipName} @ {connection.terminal?.displayName}</h2>
					<div style={{ paddingBottom: 'var(--u-4)' }}>
						{connection.voyageNumberInbound == connection.voyageNumberOutbound
							? <>Reisnummer {connection.voyageNumberInbound}</>
							: <>Reisnummers {connection.voyageNumberInbound} / {connection.voyageNumberOutbound}</>}
					</div>
					<div>
						{connection.latestSnapshot.vesselAta != null && <VesselAta value={connection.latestSnapshot.vesselAta} />}
						{connection.latestSnapshot.vesselEta != null && connection.latestSnapshot.vesselAta == null && <VesselEta value={connection.latestSnapshot.vesselEta} />}
					</div>
				</div>
				<div>
					<WithLabel label="Abonneer op updates">
						<Toggle
							onChange={newValue => {
								if (newValue) {
									subscribeToEtaUpdates({ variables: { id, margin: 60 * 60 * 4 } })
										.finally(() => connectionQuery.refetch());
								} else {
									unsubscribeToEtaUpdates({ variables: { id } })
										.finally(() => connectionQuery.refetch());
								}
							}}
							value={(connection?.subscriptions?.filter(s => s.user.id == user.id) ?? []).length > 0} />
						<p className="small">Je ontvangt een e-mail als het schip meer dan 4 uur verschuift van ETA.</p>
						{otherSubscriptions.length > 0 && <p className="small">{otherSubscriptions.map(s => s.user.firstName).join(', ')} ontvangen ook updates.</p>}
					</WithLabel>
				</div>
			</div>
			<br />
			<br />

			{connection.latestSnapshot != null && <ConnectionPageChart sortedConnections={sortedConnections} />}
			<br />
			<br />

			<FeatureFlagBarrier flag={'CALLS_MODULE'}>
				<div style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr' }}>
					<div />
					<div style={{ position: 'relative', height: '40px', zIndex: '10' }}>
						<div className="timeline-date-positioning" style={{ left: '0%' }}>
							<div className="timeline-date">
								<FormattedDateTime value={timelineStart} />
							</div>
						</div>

						{connection.latestSnapshot.vesselAta != null && <div className="timeline-date-positioning" style={{ left: calculateStartPosition(connection.latestSnapshot.vesselAta) + '%' }}>
							<VesselAta value={connection.latestSnapshot.vesselAta} />
						</div>}
						{connection.latestSnapshot.vesselEta != null && connection.latestSnapshot.vesselAta == null && <div className="timeline-date-positioning" style={{ left: calculateStartPosition(connection.latestSnapshot.vesselEta) + '%' }}>
							<VesselEta value={connection.latestSnapshot.vesselEta} />
						</div>}

						{connection.latestSnapshot.vesselAta != null && <div className="timeline-vessel-arrival-line" style={{ height: 'calc(40px * ' + barges.size + ')', left: calculateStartPosition(connection.latestSnapshot.vesselAta) + '%' }} />}
						{connection.latestSnapshot.vesselEta != null && connection.latestSnapshot.vesselAta == null && <div className="timeline-vessel-arrival-line" style={{ height: 'calc(40px * ' + barges.size + ')', left: calculateStartPosition(connection.latestSnapshot.vesselEta) + '%' }} />}

						<div className="timeline-date-positioning" style={{ left: '100%' }}>
							<div className="timeline-date">
								<FormattedDateTime value={timelineEnd} />
							</div>
						</div>
					</div>

					{/* no barges = no calls associated with the connection, so display an empty timeline */}

					{barges.size == 0 && <>
						<div className="timeline-barge-name" />
						<div className="timeline-barge-row" >
							<div className="timeline-call" style={{ left: '35%' }}>&nbsp;Geen termijnen met betrekking tot de verbinding gevonden.&nbsp;</div>
						</div>
					</>}

					{[ ...barges ].map(b => {
						const connPickupSet = new Set(connection.pickups.map(ct => ct.id));
						const connDropoffSet = new Set(connection.dropoffs.map(ct => ct.id));
						const filteredCalls = calls.filter(c => c.ship.id == b.id);

						return <>
							<div className="timeline-barge-name">{b.name}</div>
							<div className="timeline-barge-row">
								{filteredCalls.map(c => {
									const unloadSize = c.unloadList.filter(ct => connDropoffSet.has(ct.id)).length;
									const loadSize = c.loadList.filter(ct => connPickupSet.has(ct.id)).length;
									const startPosition = calculateStartPosition(c.pta);
									const endPosition = calculateCallWidth(c.pta, c.ptd);
									return <>
										{loadSize == 0 && unloadSize > 0 && <div className="timeline-call" style={{ left: startPosition + '%', width: endPosition + '%', minWidth: '3.5%' }}>
											<div>
												<span className="fa fa-arrow-up" />&nbsp;{unloadSize}&times;
											</div>
											<span style={{ fontSize: '10px' }}>
												<FormattedDate value={Date.parse(c.pta)} day="2-digit" month="2-digit" />&nbsp;
												<FormattedTime value={Date.parse(c.pta)} hour="2-digit" minute="2-digit" />
											</span>
										</div>}
										{unloadSize == 0 && loadSize > 0 && <div className="timeline-call" style={{ left: startPosition + '%', width: endPosition + '%', minWidth: '3.5%' }}>
											<div>
												<span className="fa fa-arrow-down" />&nbsp;{loadSize}&times;
											</div>
											<span style={{ fontSize: '10px' }}>
												<FormattedDate value={Date.parse(c.pta)} day="2-digit" month="2-digit" />&nbsp;
												<FormattedTime value={Date.parse(c.pta)} hour="2-digit" minute="2-digit" />
											</span>
										</div>}
										{unloadSize > 0 && loadSize > 0 && <div className="timeline-call" style={{ left: startPosition + '%', width: endPosition + '%', minWidth: '7%' }}>
											<div>
												<span className="fa fa-arrow-up" />&nbsp;{unloadSize}&times;&nbsp;/&nbsp;<span className="fa fa-arrow-down" />&nbsp;{loadSize}&times;
											</div>
											<span style={{ fontSize: '10px' }}>
												<FormattedDate value={Date.parse(c.pta)} day="2-digit" month="2-digit" />&nbsp;
												<FormattedTime value={Date.parse(c.pta)} hour="2-digit" minute="2-digit" />
											</span>
										</div>}
									</>;
								})}
							</div>
						</>;
					})}

				</div>
			</FeatureFlagBarrier>

			<br />
			<br />
			<div>
				<div style={{ display: 'grid', gridTemplateColumns: '0.1fr 1fr', width: 'var(--u-768)', fontWeight: '900' }}>
					<div />
					<div style={{ margin: '0 0 0 15px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', borderRadius: '4px', background: 'var(--col-primary-500)', color: 'var(--col-primary-50)', fontSize: 'var(--fs-15)', padding: '8px 8px' }}>
						<span>STATUS</span>
						<span style={{ marginLeft: '15px' }}>ONTVANGEN</span>
						<span>BRON</span>
					</div>
				</div>
				{sortedConnections.map((cs, idx) => {
					return <div style={{ display: 'grid', gridTemplateColumns: '0.1fr 1fr', width: 'var(--u-768)' }}>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr', alignItems: 'center', justifyItems: 'center' }}>
							<Timeline cs={cs} idx={idx} />
							<Snapshot cs={cs} />
						</div>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr', alignItems: 'center' }}>
							{idx != 0 && <div style={{ marginLeft: '-15px', height: '20px' }}>
								{(idx != 0 && cs.vesselAta == null && cs.vesselEta != null && sortedConnections[idx - 1].vesselAta == null && sortedConnections[idx - 1].vesselEta != null) &&
									<span>ETA <Duration value={calculateTimeDifference(cs, idx)} /></span>}
								{(idx != 0 && cs.vesselAta != null && sortedConnections[idx - 1].vesselAta != null) &&
									<span>ATA <Duration value={calculateTimeDifference(cs, idx)} /></span>}
								{(idx != 0 && cs.vesselEta != null && cs.vesselAta == null && sortedConnections[idx - 1].vesselAta != null) &&
									<span>ETA -> ATA <Duration value={calculateTimeDifference(cs, idx)} /></span>}
							</div>}
							{idx == 0 && <div style={{ margin: '0 0 0 15px', borderRadius: '4px', padding: '8px 8px' }} />}
							<div style={{ marginLeft: '15px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', paddingBottom: '5px', borderBottom: '1px solid' }}>
								{cs.vesselAta != null && <div style={{ display: 'inline-block', borderRadius: '4px', background: 'var(--col-green-200)', color: 'var(--col-grey-800)', padding: '4px 8px', textAlign: 'center', margin: '0 55px 0 0' }}>
									<span className="fa fa-check" />&nbsp;&nbsp;<FormattedDateTime value={cs.vesselAta} />
								</div>}
								{cs.vesselEta != null && cs.vesselAta == null && <div style={{ display: 'inline-block', borderRadius: '4px', background: 'var(--col-primary-200)', color: 'var(--col-grey-800)', padding: '4px 8px', textAlign: 'center', margin: '0 55px 0 0' }}>
									<span className="fa fa-calendar-o" />&nbsp;&nbsp;<FormattedDateTime value={cs.vesselEta} />
								</div>}
								<span style={{ marginLeft: '15px' }}><FormattedDateTime value={cs.createdAt} /> </span>
								<span>{cs.dataSource} </span>
							</div>
						</div>
					</div>;
				})}
			</div>
		</div>}
	</div>;
}

function Snapshot({ cs }) {
	return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
		{cs.vesselAta == null && cs.vesselEta != null && <div className="connection-snapshot" style={{ borderColor: "var(--col-primary-800)" }}>
			<span className="fa fa-calendar-o" />
		</div>}
		{cs.vesselAta != null && <div className="connection-snapshot" style={{ borderColor: "var(--col-green-800)" }}>
			<span className="fa fa-check" />
		</div>}
	</div>;
}

function Timeline({ cs, idx }) {
	return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
		{(idx == 0 && cs.vesselAta == null) && <div className="connection-timeline-dotted" style={{ borderColor: "var(--col-primary-800)" }}/>}
		{(idx == 0 && cs.vesselAta != null) && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center ' }}><div className="connection-end" /><div className="connection-timeline" style={{ borderColor: "var(--col-green-800)" }} /></div>}
		{(idx != 0 && cs.vesselAta == null && cs.vesselEta != null) && <div className="connection-timeline" style={{ borderColor: "var(--col-primary-800)" }} />}
		{(idx != 0 && cs.vesselAta != null) && <div className="connection-timeline" style={{ borderColor: "var(--col-green-800)" }} />}
	</div>;
}