import { useMemo } from 'react';
import { InputField } from '@atrocit/scl';
import HoverBubble from '../ui/HoverBubble';


const alphabetNumerical = {
	'0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9,
	'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19, 'J': 20, 'K': 21, 'L': 23, 'M': 24,
	'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29, 'S': 30, 'T': 31, 'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38,
};

export default function ContainerNumberField({ onChange, value, ...props }) {
	const isValidIso6346 = useMemo(() => {
		const containerNumber = (value || '').replace(/[^a-zA-Z0-9]+/g, '').toUpperCase();
		if (!/[A-Z]{4}[0-9]{7}/.test(containerNumber)) return false;

		let sum = 0;
		for (let i = 0; i < containerNumber.length - 1; i++) {
			sum += alphabetNumerical[containerNumber[i]] * Math.pow(2, i);
		}
		const elevenedSum = Math.floor(sum / 11) * 11;
		const checkDigit = (sum - elevenedSum) % 10;

		return checkDigit == (alphabetNumerical[containerNumber.slice(-1)] ?? -1);
	}, [ value ]);

	return <div className="container-number-field" style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'center' }}>
		<div className="container-number-field-input">
			<InputField {...props} onChange={cn => onChange((cn || '').toUpperCase())} value={(value || '').toUpperCase()} />
		</div>
		<div className="container-number-field-validation" style={{ width: 'var(--u-16)', textAlign: 'center' }}>
			{isValidIso6346 ? <span className="fa fa-check-circle" style={{ color: 'var(--col-green-500)' }} /> : <span className="fa fa-warning" style={{ color: 'var(--col-orange-500)' }} />}
		</div>
	</div>;
}