import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';

export default function CustomerSelectField({ onChange, value, placeholder = '', ...props }) {
	const CreateBookingCustomerFieldQuery = useQuery(gql`query {
        relations {
            id, displayName, fullName
        }
    }`);

	const options = useMemo(() => {
		return (CreateBookingCustomerFieldQuery?.data?.relations ?? []).map(c => ({
			value: c.id,
			label: c.fullName,
			original: c,
		}));
	}, [ CreateBookingCustomerFieldQuery?.data?.relations ]);

	const onChangeCb = useCallback((obj) => {
		onChange(obj?.value ?? null, obj?.original);
	}, [ onChange ]);

	return <Select
		{...props}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={CreateBookingCustomerFieldQuery.loading}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		value={options.find(o => o.value == value)} />;
}