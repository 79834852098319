import React, { useEffect, useRef } from 'react';

export default function Checkbox({ onChange, value, indeterminate = false }) {
	const fieldRef = useRef(null);

	useEffect(() => {
		fieldRef.current.indeterminate = indeterminate;
	}, [ indeterminate, fieldRef.current ]);

	return <label className="checkbox">
		<input type="checkbox" onChange={onChange} checked={value} ref={fieldRef} />
		<span className="checkbox-ui-elem" />
	</label>;
}