import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import Breadcrumbs from '../lib/Breadcrumbs';
import FormattedDateTime from '../lib/formatting/FormattedDateTime';
import ShipSelectField from '../lib/selectfields/ShipSelectField';
import AddCallModal from './AddCallModal';
import EditCallModal from './EditCallModal';
import LoadingOverlay from '../lib/ui/LoadingOverlay';
import { Button, DropdownButton, Pagination, WithLabel } from '@atrocit/scl';
import DeleteCallModal from './DeleteCallModal';
import PageTop from '../lib/ui/PageTop';

const PAGE_SIZE = 20;

export default function CallOverview() {
	const [ page, setPage ] = useState(1);
	const [ selectedShipId, setSelectedShipId ] = useState(null);
	const [ editCallId, setEditCallId ] = useState(null);
	const [ deleteCallId, setDeleteCallId ] = useState(null);
	const [ showAddCallModal, setShowAddCallModal ] = useState(false);
	const [ showEditCallModal, setShowEditCallModal ] = useState(false);
	const [ showDeleteCallModal, setShowDeleteCallModal ] = useState(false);

	// Filter based on ship selected
	const callsQuery = useQuery(gql`query Query($offset: Int!, $limit: Int!, $selectedShipId: Int) {
        callsByShip(offset: $offset, limit: $limit, shipId: $selectedShipId) {
            number,
            size,
            totalPages,
            totalElements,
            hasNext,
            hasPrevious,
            content {
                id,
                callReference,
                ship { name },
                terminal { id, displayName, portUnloCode },
                pta,
                ptd
            }
        }
    }`, { variables: { offset: (page - 1) * PAGE_SIZE, limit: page * PAGE_SIZE, selectedShipId } });
	const totalElements = callsQuery?.data?.callsByShip?.totalElements;
	const calls = callsQuery?.data?.callsByShip?.content ?? [];

	useEffect(() => setPage(1), [ selectedShipId ]);

	return <div className="page">
		{showAddCallModal && <AddCallModal isOpen={showAddCallModal} onClose={() => setShowAddCallModal(false)} refetch={callsQuery.refetch} />}
		{showEditCallModal && <EditCallModal isOpen={showEditCallModal} onClose={() => setShowEditCallModal(false)} refetch={callsQuery.refetch} callId={editCallId} />}
		{showDeleteCallModal && <DeleteCallModal isOpen={showDeleteCallModal} onClose={() => setShowDeleteCallModal(false)} callId={deleteCallId} refetch={() => callsQuery.refetch()} />}

		<PageTop
			breadcrumbSegments={[
				{ link: '/calls', label: 'Calls' },
				{ link: '/calls', label: 'Overzicht' },
			]}>
			<Button onClick={() => setShowAddCallModal(true)}><span className="fa fa-plus" />&nbsp; Nieuwe call</Button>
		</PageTop>

		<WithLabel label="Schip">
			<ShipSelectField
				onChange={id => setSelectedShipId(id)}
			    value={selectedShipId} />
		</WithLabel>

		<table className="table table-fw">
			<colgroup>
				<col width="*" />
				<col width="150" />
				<col width="200" />
				<col width="150" />
				<col width="150" />
				<col width="160" />
			</colgroup>
			<thead>
				<tr>
					<th>Callreferentie</th>
					<th>Terminal</th>
					<th>Schip</th>
					<th>PTA</th>
					<th>PTD</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{calls.map(c => <tr key={c.id}>
					<td title={c.callReference}>{c.callReference}</td>
					<td title={c.terminal?.displayName}>{c.terminal?.displayName}</td>
					<td title={c.ship?.name ?? '-'}>{c.ship?.name ?? '-'}</td>
					<td>
						<FormattedDateTime value={c.pta} />
					</td>
					<td>
						<FormattedDateTime value={c.ptd} />
					</td>
					<td>
						<DropdownButton mainAction={<Button onClick={() => { setEditCallId(c.id); setShowEditCallModal(true); }}><span className="fa fa-pencil" />&nbsp; Bewerken</Button>}>
							<Button className="danger" onClick={() => { setDeleteCallId(c.id); setShowDeleteCallModal(true); }}><span className="fa fa-trash" />&nbsp; Verwijderen</Button>
						</DropdownButton>
					</td>
				</tr>)}
			</tbody>
		</table>

		<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)', gap: 'var(--u-24)', alignItems: 'center' }}>
			<div>
				<span><span>Termijn {(page - 1) * PAGE_SIZE + 1} t/m {Math.min((page * PAGE_SIZE), totalElements)} getoond, van {totalElements} totaal</span></span>
			</div>
			<Pagination page={page} setPage={setPage} pageSize={PAGE_SIZE} total={totalElements} />
		</div>
		{callsQuery.loading && <LoadingOverlay />}

	</div>;
}