import React, { useEffect } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

export const logoutListeners = [];

const httpLink = new HttpLink({ uri: '/graphql' });
const logoutLink = onError(({ networkError }) => {
	// TODO: >400 errors is way too wide, got to change this in the future
	if (networkError.statusCode > 400) logoutListeners.forEach(fn => fn());
});

// Websocket stuff
const host = window.location.host.split(':')[0];
const protocol = window.location.protocol.includes('https') ? 'wss' : 'ws';
const wsLink = new WebSocketLink({
	uri: protocol + '://' + host + (host == 'localhost' ? ':5000' : '') + '/subscriptions',
	options: {
		reconnect: true,
	},
});

// Combined HTTP / websocket stuff
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	logoutLink.concat(httpLink),
);


export const myApolloClient = new ApolloClient({
	uri: '/graphql',
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'network-only',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
	},
	link: logoutLink.concat(splitLink),
});

export function ApolloInjector({ children }) {
	return <ApolloProvider client={myApolloClient}>
		{children}
	</ApolloProvider>;
}
