import Modal from '../../lib/ui/Modal';
import { useCallback, useEffect, useState } from 'react';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Form, InputField, Toggle, WithLabel } from '@atrocit/scl';

export default function EditContainerTypeModal({ isOpen, onClose, containerTypeId, refetch }) {
	const [ displayName, setDisplayName ] = useState("");
	const [ fullName, setFullName ] = useState("");
	const [ isoCode, setIsoCode ] = useState("");
	const [ teu, setTeu ] = useState(0);
	const [ height, setHeight ] = useState(0);
	const [ length, setLength ] = useState(0);
	const [ weight, setWeight ] = useState(0);
	const [ isReefer, setIsReefer ] = useState(false);
	const [ loading, setLoading ] = useState(false);

	const containerTypeQuery = useQuery(gql`query Query($containerTypeId: Int!) {
		containerTypeById(containerTypeId: $containerTypeId) {
			id, displayName, fullName, isoCode, teu, height, weight, length, isReefer
		}
	}`, { variables: { containerTypeId } });
	const ct = containerTypeQuery?.data?.containerTypeById;

	useEffect(() => {
		if (ct != null) {
			setDisplayName(ct.displayName != null ? ct.displayName : "");
			setFullName(ct.fullName != null ? ct.fullName : "");
			setIsoCode(ct.isoCode != null ? ct.isoCode : "");
			setTeu(ct.teu != null ? ct.teu : 0);
			setHeight(ct.height != null ? ct.height : 0);
			setWeight(ct.weight != null ? ct.weight : 0);
			setLength(ct.length != null ? ct.length : 0);
			setIsReefer(ct.isReefer != null ? ct.isReefer : false);
		}
	}, [ ct ]);

	const [ editContainerType, editContainerTypeMutation ] = useMutation(gql`mutation Mutation($containerTypeId: Int!, $containerType: ContainerTypePartialUpdate!) {
        editContainerType(containerTypeId: $containerTypeId, containerType: $containerType) { id }
    }`);

	const process = useCallback(() => {
		setLoading(true);
		const containerType = { containerTypeId: containerTypeId, displayName: displayName, fullName: fullName, isoCode: isoCode, teu: teu, height: height, length: length, weight: weight, isReefer: isReefer };
		editContainerType({ variables: { containerTypeId: containerTypeId, containerType: containerType } }).finally(() => { refetch(); onClose(); });
	}, [ displayName, fullName, isoCode, teu, height, length, weight, isReefer ]);

	return <Modal isOpen={isOpen} onClose={onClose} title="Containertype bewerken">
		{!editContainerTypeMutation.loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<WithLabel label="Weergavenaam">
				<InputField value={displayName} onChange={setDisplayName} />
			</WithLabel>
			<WithLabel label="Volledige naam">
				<InputField value={fullName} onChange={setFullName} />
			</WithLabel>
			<WithLabel label="ISO-code">
				<InputField value={isoCode} onChange={setIsoCode} />
			</WithLabel>
			<WithLabel label="TEU">
				<InputField value={teu} onChange={setTeu} />
			</WithLabel>
			<WithLabel label="Hoogte (mm)">
				<InputField value={height} onChange={setHeight} />
			</WithLabel>
			<WithLabel label="Lengte (mm)">
				<InputField value={length} onChange={setLength} />
			</WithLabel>
			<WithLabel label="Tarragewicht">
				<InputField value={weight} onChange={setWeight} />
			</WithLabel>
			<WithLabel label="Reefer">
				<Toggle value={isReefer} onChange={(v) => setIsReefer(v)} />
			</WithLabel>
			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: displayName.length > 0, error: "Weergavenaam mag niet leeg zijn" },
						{ valid: displayName.length < 15, error: "Weergavenaam moet kleiner zijn dan 15 karakters" },
						{ valid: fullName.length > 0, error: "Volledige naam mag niet leeg zijn" },
						{ valid: fullName.length < 200, error: "Volledige naam moet kleiner zijn dan 200 karakters" },
						{ valid: !isNaN(teu) && teu > 0, error: "TEU moet een geheel getal zijn" },
						{ valid: teu < 100.0, error: "Aantal TEU moet kleiner zijn dan 100" },
					]}>
					<span className="fa fa-check" />&nbsp; Opslaan
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{editContainerTypeMutation.loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}