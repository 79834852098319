import React, { useState } from "react";
import ButtonArea from "../lib/forms/ButtonArea";
import { Form, InputField, WithLabel } from "@atrocit/scl";
import Breadcrumbs from "../lib/Breadcrumbs";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import ValidatedButton from "../lib/forms/ValidatedButton";
import { gql, useMutation } from "@apollo/client";

export default function EditPassword() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [ password, setPassword ] = useState(null);
	const [ currentPassword, setCurrentPassword ] = useState(null);
	const [ repeatedPassword, setRepeatedPassword ] = useState(null);
	const [ passwordsMatch, setPasswordsMatch ] = useState(null);

	const [ editPassword ] = useMutation(gql `mutation Mutation($id: Int!, $currentPassword: String!, $newPassword: String!){
        editPassword(id: $id, currentPassword: $currentPassword, newPassword: $newPassword) { id }
    }`);

	function checkPasswords() {
		if(password != null && repeatedPassword != null) {
			if(password.toLowerCase() != repeatedPassword.toLowerCase()) {
				setPasswordsMatch(false);
			}else{
				setPasswordsMatch(true);
			}
		}
	}

	return <div className={'page'}>
		<div className={"app-ui"}>
			<Breadcrumbs
				segments={[
					{ link: '/users/' + id, label: id },
					{ link: ' ', label: 'Nieuwe Wachtwoord' },
				]} />
			<div>
				<Form style={{ width: 'var(--u-768)', justifyContent: 'center', alignItems: 'center', margin: '20px' }} onSubmit={() => {
					editPassword({
						variables: {
							id: id,
							currentPassword: currentPassword,
							newPassword: password,
						},
					}).then(() => {
						navigate('/users');
					})
						.catch((e) => console.error("Something went wrong ;( "));
				}}>
					<WithLabel label={'Huidige Wachtwoord'}>
						<InputField type={'password'} onChange={setCurrentPassword} value={currentPassword}></InputField>
					</WithLabel>
					<WithLabel label={'Nieuwe Wachtwoord'}>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<InputField
								style={{ paddingRight: 'var(--u-24)', borderColor: !passwordsMatch && passwordsMatch != null ? "var(--col-red-500)" : '' }}
								type={'password'} onChange={setPassword} onBlur={checkPasswords} value={password}></InputField>
							{!passwordsMatch && passwordsMatch != null &&
                        <div style={{ width: '0', position: 'relative', left: '-24px', color: 'var(--col-red-500)' }}>
	                        <span className="fa fa-exclamation-triangle" style={{ marginBlockStart: '-20px' }} />
                        </div>}
						</div>
					</WithLabel>
					<WithLabel label={"Herhaal Nieuwe Wachtwoord"}>
						<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<InputField
								style={{ paddingRight: 'var(--u-24)', borderColor: !passwordsMatch && passwordsMatch != null ? "var(--col-red-500)" : '' }}
								type={'password'} onChange={setRepeatedPassword} onBlur={checkPasswords} value={repeatedPassword}></InputField>
							{!passwordsMatch && passwordsMatch != null && <div style={{ width: '0', position: 'relative', left: '-24px', color: 'var(--col-red-500)' }}>
		                        <span className="fa fa-exclamation-triangle" />
	                        </div>}
						</div>
					</WithLabel>
					{!passwordsMatch && passwordsMatch != null && <p style={{ position: 'relative', bottom: 'var(--u-16)', color: "var(--col-red-500)" }}> Wachtwoorden komen niet overeen. Probeer nog een keer. </p>}
					<ButtonArea>
						<ValidatedButton type={"submit"} validations={[
							{ valid: currentPassword != null, error: "Een huidig wachtwoord is nodig" },
						]} disabled={!passwordsMatch}>
                        Bevestigen
						</ValidatedButton>
					</ButtonArea>
				</Form>
			</div>
		</div>
	</div>;
}
