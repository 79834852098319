import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import Breadcrumbs from '../lib/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Button, DropdownButton, LinkButton, Pagination } from '@atrocit/scl';
import LoadingOverlay from '../lib/ui/LoadingOverlay';
import SearchField from '../lib/forms/SearchField';
import BlurredZeroValue from '../lib/tables/BlurredZeroValue';
import PageTop from '../lib/ui/PageTop';

const PAGE_SIZE = 20;

export default function BookingOverview() {
	const [ page, setPage ] = useState(1);
	const [ query, setQuery ] = useState('');

	useEffect(() => {
		if (page != 1) setPage(1);
	}, [ query ]);

	// Fetch bookings
	const bookingsQuery = useQuery(gql`query Query($query: String, $offset: Int!, $limit: Int!) {
		bookingsPageable(query: $query, offset: $offset, limit: $limit) {
			totalElements,
			content {
                id,
                customer {
                    id, displayName
                },
                customerReference,
                internalReference,
				bookingNumber,
                transports {
                    id,
                    containerType {
                        id, displayName, teu
                    },
	                activeReefer,
                    containerNumber,
	                actualDropoff,
                }
            }
		}
	}`, { variables: { query, offset: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE } });
	const totalElements = bookingsQuery?.data?.bookingsPageable?.totalElements ?? 0;
	const bookings = bookingsQuery?.data?.bookingsPageable?.content ?? [];

	return <div className="page">
		<PageTop
			breadcrumbSegments={[
				{ link: '/bookings', label: 'Boekingen' },
				{ link: '/bookings', label: 'Overzicht' },
			]}>
			<Link to="/bookings/new" style={{ textDecoration: 'none' }}><Button><span className="fa fa-plus" />&nbsp; Nieuwe boeking</Button></Link>
		</PageTop>

		<SearchField
			onChange={setQuery}
			value={query}
			hasResults={bookings.length != 0} />

		<table className="table table-fw">
			<colgroup>
				<col width="32" />
				<col width="100" />
				<col width="120" />
				<col width="140" />
				<col width="*" />
				<col width="50" />
				<col width="50" />
				<col width="50" />
				<col width="120" />
			</colgroup>
			<thead>
				<tr>
					<th></th>
					<th>Boekingsnr</th>
					<th>Interne ref.</th>
					<th>Klant</th>
					<th>Klantreferentie</th>
					<th title="Aantal containers">Cnt.</th>
					<th title="Twenty ft Equivalent Unit">TEU</th>
					<th title="Aantal actieve reefers" style={{ textAlign: 'center' }}><span className="fa fa-snowflake-o" /></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{bookings.map(b => {
					const finished = b.transports.reduce((fn, ct) => (ct.actualDropoff != null ? fn + 1 : fn), 0);
					const totalTeu = b.transports.reduce((teu, ct) => ct?.containerType?.teu + teu, 0);
					const activeReefers = b.transports.reduce((rf, ct) => (ct.activeReefer ? rf + 1 : rf), 0);

					let status = 'UNKNOWN';
					if (finished == 0) {
						status = 'READY';
					} else if (finished < b.transports.length) {
						status = 'IN_PROGRESS';
					} else {
						status = 'FINISHED';
					}

					return <tr key={b.id}>
						<td style={{ textAlign: 'center' }}>
							{status == 'READY' && <span className="fa fa-clock-o" />}
							{status == 'IN_PROGRESS' && <span className="fa fa-refresh" style={{ color: 'var(--col-orange-500)' }} />}
							{status == 'FINISHED' && <span className="fa fa-check" style={{ color: 'var(--col-green-500)' }} />}
						</td>
						<td>{b.bookingNumber}</td>
						<td>{b.internalReference}</td>
						<td>{b.customer?.displayName}</td>
						<td>{b.customerReference}</td>
						<td style={{ textAlign: 'right' }}>
							<BlurredZeroValue value={b.transports.length} />
						</td>
						<td style={{ textAlign: 'right' }}>
							<BlurredZeroValue value={Math.ceil(totalTeu)} />
						</td>
						<td style={{ textAlign: 'right' }}>
							<BlurredZeroValue value={activeReefers} />
						</td>
						<td>
							<LinkButton to={'/bookings/' + b.id} className="scl-button scl-button-secondary">Openen &nbsp;<span className="fa fa-angle-right" /></LinkButton>
						</td>
					</tr>;
				})}
			</tbody>
		</table>

		<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)', gap: 'var(--u-24)', alignItems: 'center' }}>
			<div>
				<span><span>Boeking {(page - 1) * PAGE_SIZE + 1} t/m {Math.min((page * PAGE_SIZE), totalElements)} getoond, van {totalElements} totaal</span></span>
			</div>
			<Pagination page={page} setPage={setPage} pageSize={PAGE_SIZE} total={totalElements} />
		</div>
		{bookingsQuery.loading && <LoadingOverlay />}
	</div>;
}