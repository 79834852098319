import React from 'react';
import ReactModal from 'react-modal';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button } from '@atrocit/scl';

ReactModal.setAppElement('#root');

const modalStyles = {
	content: {
		background: 'transparent',
		inset: '0px',
		border: 'none',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	overlay: {
		background: 'rgba(0, 0, 0, 0.5)',
		zIndex: 100,
	},
};

export default function Modal({ children, onClose, title, ...props }) {
	useHotkeys('esc', () => (onClose ?? (() => {}))(), [ onClose ]);

	return <ReactModal onRequestClose={onClose} isOpen={true} style={modalStyles} {...props}>
		<div className="modal-card">
			<div className="modal-header">
				<div style={{ paddingRight: 'var(--u-24)' }}>
					<span style={{ textTransform: 'uppercase', fontWeight: '500', color: 'var(--col-grey-700)' }}>{title}</span>
				</div>
				{onClose && <div>
					<Button onClick={onClose} style={{ width: '32px', height: '32px', fontSize: '1.2em', color: 'var(--col-grey-500)' }}>&times;</Button>
				</div>}
			</div>
			<div className="modal-content">
				{children}
			</div>
		</div>
	</ReactModal>;
}