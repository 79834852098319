import { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import VesselEta from '../domain/VesselEta';
import VesselAta from '../domain/VesselAta';

export default function SelectConnectionField({ terminalIds, onChange, value, inOutBound }) {
	const selected = value;
	const setSelected = onChange;
	const [ query, setQuery ] = useState('');

	const connectionsQuery = useQuery(gql`query Query($terminalIds: [ Int! ], $startDate: Instant!, $endDate: Instant!) {
		connections(terminalIds: $terminalIds, startDate: $startDate, endDate: $endDate) {
			id,
			voyageNumberInbound,
			voyageNumberOutbound,
			seaship {
				id, shipName
            },
			terminal {
				id, displayName
            },
			latestSnapshot {
				vesselAta,
				vesselEta
            }
		}
	}`, { variables: { terminalIds: [ ...terminalIds ], startDate: DateTime.now().startOf('day').minus({ days: 14 }).toUTC().toISO(), endDate: DateTime.now().startOf('day').plus({ days: 45 }).toUTC().toISO() } });
	const connections = connectionsQuery?.data?.connections;

	const filteredConnections =
		(connections || []).filter((connection) => (connection.seaship.shipName
			.toLowerCase()
			.replace(/\s+/g, '')
			.includes(query.toLowerCase().replace(/\s+/g, ''))) ||
				(connection.terminal.displayName
					.toLowerCase()
					.replace(/\s+/g, '')
					.includes(query.toLowerCase().replace(/\s+/g, ''))) ||
				((inOutBound == 'INBOUND' ? connection.voyageNumberInbound : connection.voyageNumberOutbound)
					.toLowerCase()
					.replace(/\s+/g, '')
					.includes(query.toLowerCase().replace(/\s+/g, '')))
		);

	return <Combobox value={selected} onChange={setSelected}>
		{({ open }) => <div className={"select-connection"}>
			<div className={"select-connection-field" + (open ? ' select-connection-field-focused' : '')}>
				<Combobox.Button className="select-connection-field-button" tabIndex={-1}>
					<Combobox.Input
						className={"select-connection-field-input scl-form-element"}
						displayValue={(connection) => {
							if (connection == null) return '';
							const voyageNumber = (inOutBound == 'INBOUND' ? connection.voyageNumberInbound : connection.voyageNumberOutbound);
							return connection?.seaship?.shipName + ' - ' + connection?.terminal?.displayName + ' - ' + voyageNumber;
						}}
						onChange={(event) => setQuery(event.target.value)}
						onKeyDown={(event) => {
							if ((event.keyCode === 8 || event.keyCode === 46) && event.target.value == '') {
								setSelected(null);
							}
						}}
					/>
					<i className="fa fa-chevron-down select-connection-field-icon"></i>
				</Combobox.Button>
			</div>
			<Combobox.Options className="select-connection-options">
				{(filteredConnections.length === 0 && query !== '') || (typeof connections === 'undefined' || connections.length == 0) ? (
					<div className="select-connection-no-options">
						Geen resultaten
					</div>)
					:

					(filteredConnections.map((c) => (
						<Combobox.Option
							key={c.id}
							value={c}>
							{({ selected, active }) => (
		                        <div className={"selection-connection-option" + (selected ? ' selection-connection-option-selected' : '')}>
			                        <div>
				                      <span>{c.seaship.shipName}</span><br />
				                      <span>{c.terminal.displayName} - <span>{inOutBound == 'INBOUND' ? c.voyageNumberInbound : c.voyageNumberOutbound}</span></span>
								    </div>
			                        <div>
				                      {c.latestSnapshot.vesselAta != null && <VesselAta value={c.latestSnapshot.vesselAta} />}
				                      {c.latestSnapshot.vesselAta == null && <VesselEta value={c.latestSnapshot.vesselEta} />}
								    </div>
								</div>

							)}
						</Combobox.Option>)))
				}
			</Combobox.Options>
		</div>}
	</Combobox>;
}