import React, { useMemo, useState } from 'react';
import Modal from '../../lib/ui/Modal';
import ButtonArea from '../../lib/forms/ButtonArea';
import { gql, useMutation } from '@apollo/client';
import LoadingOverlay from '../../lib/ui/LoadingOverlay';
import { Button, Form, InputField, WarningBanner, WithLabel } from '@atrocit/scl';
import SelectField from '../../lib/selectfields/SelectField';

export default function PickupDropoffReferenceCommand({ containerTransports, onClose }) {
	const pickupRefsAllEqual = useMemo(() => new Set(containerTransports.filter(ct => ct.pickupReference != null && ct.pickupReference.length > 0).map(ct => ct.pickupReference)).size <= 1, [ containerTransports ]);
	const dropoffRefsAllEqual = useMemo(() => new Set(containerTransports.filter(ct => ct.dropoffReference != null && ct.dropoffReference.length > 0).map(ct => ct.dropoffReference)).size <= 1, [ containerTransports ]);

	const pickupRefsAllCtr = useMemo(() => containerTransports.filter(ct => ct.pickupReference != null && ct.pickupReference.length > 0).filter(ct => ct.pickupReference == ct.containerNumber).length == containerTransports.length, [ containerTransports ]);
	const dropoffRefsAllCtr = useMemo(() => containerTransports.filter(ct => ct.dropoffReference != null && ct.dropoffReference.length > 0).filter(ct => ct.dropoffReference == ct.containerNumber).length == containerTransports.length, [ containerTransports ]);

	const [ pickupChangeType, setPickupChangeType ] = useState(pickupRefsAllCtr ? 'CONTAINER_NUMBER' : 'IGNORE');
	const [ dropoffChangeType, setDropoffChangeType ] = useState(dropoffRefsAllCtr ? 'CONTAINER_NUMBER' : 'IGNORE');
	const [ pickupReference, setPickupReference ] = useState('');
	const [ dropoffReference, setDropoffReference ] = useState('');

	const [ updatePickupDropoffReferences, { loading } ] = useMutation(gql`mutation Mutation($containerTransports: [ Int! ]!, $pickupChangeType: RefChangeType!, $dropoffChangeType: RefChangeType!, $pickupReference: String, $dropoffReference: String) {
        updatePickupDropoffReferences(containerTransports: $containerTransports, pickupChangeType: $pickupChangeType, dropoffChangeType: $dropoffChangeType, pickupReference: $pickupReference, dropoffReference: $dropoffReference) { id }
    }`);

	return <Modal title="Pin/referenties wijzigen" onClose={onClose}>
		{loading && <LoadingOverlay />}
		<div style={{ width: 'var(--u-512)' }}>
			<Form onSubmit={() => updatePickupDropoffReferences({ variables: { containerTransports: containerTransports.map(ct => ct.id), pickupChangeType, dropoffChangeType, pickupReference, dropoffReference } }).then(onClose)}>
				<div>Wijziging van pin/referenties voor <span className="bold">{containerTransports.length}</span> container(s)</div>

				<div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 'var(--u-8)', padding: 'var(--u-12) 0' }}>
					{!pickupRefsAllEqual && !pickupRefsAllCtr && pickupChangeType != 'IGNORE' && <WarningBanner>Er worden meerdere verschillende uithaalreferenties overschreven</WarningBanner>}
					{!dropoffRefsAllEqual && !dropoffRefsAllCtr && dropoffChangeType != 'IGNORE' && <WarningBanner>Er worden meerdere verschillende inleverreferenties overschreven</WarningBanner>}
				</div>

				<div className="col-2">
					<div>
						<WithLabel label="Uithaalref wijziging">
							<SelectField
								options={[
									{ value: 'IGNORE', label: 'Behoud bestaande' },
									{ value: 'CONTAINER_NUMBER', label: 'Gebruik containernummer' },
									{ value: 'OVERWRITE', label: 'Overschrijf met...' },
								]}
								onChange={opt => setPickupChangeType(opt?.value)}
								value={pickupChangeType} />
						</WithLabel>

						<WithLabel label="Uithaalref">
							<InputField
								style={{ width: '100%' }}
								disabled={pickupChangeType != 'OVERWRITE'}
								onChange={setPickupReference}
								value={pickupChangeType != 'OVERWRITE' ? '---' : pickupReference} />
						</WithLabel>
					</div>
					<div>
						<WithLabel label="Inleverref wijziging">
							<SelectField
								options={[
									{ value: 'IGNORE', label: 'Behoud bestaande' },
									{ value: 'CONTAINER_NUMBER', label: 'Gebruik containernummer' },
									{ value: 'OVERWRITE', label: 'Overschrijf met...' },
								]}
								onChange={opt => setDropoffChangeType(opt?.value)}
								value={dropoffChangeType} />
						</WithLabel>

						<WithLabel label="Inleverref">
							<InputField
								style={{ width: '100%' }}
								disabled={dropoffChangeType != 'OVERWRITE'}
								onChange={setDropoffReference}
								value={dropoffChangeType != 'OVERWRITE' ? '---' : dropoffReference} />
						</WithLabel>
					</div>
				</div>

				<ButtonArea>
					<Button level="primary" type="submit"><span className="fa fa-check" />&nbsp;Opslaan</Button>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</div>
	</Modal>;
}