import { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { DndContext } from 'react-dnd';
// import WindowContext from '../../../context/WindowContext';
import { StyleSheetManager } from 'styled-components';

function copyStyles(sourceDoc, targetDoc) {
	if (!targetDoc.head) {
		targetDoc.head = sourceDoc.createElement('head');
	}
	Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
		try {
			if (styleSheet.href) { // true for stylesheets loaded from a URL
				const newLinkEl = sourceDoc.createElement('link');
				newLinkEl.rel = 'stylesheet';
				newLinkEl.href = styleSheet.href;
				targetDoc.head.appendChild(newLinkEl);
			} else {
				const rules = styleSheet.rules || styleSheet.cssRules;
				if (rules) { // true for inline styles
					const newStyleEl = sourceDoc.createElement('style');
					Array.from(rules).forEach(cssRule => {
						newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
					});
					targetDoc.head.appendChild(newStyleEl);
				}
			}
		} catch (e) {
			console.warn('Can\'t read css rule: ', styleSheet);
		}
	});
}

export default function Popoutable3({ open, children, onClose }) {
	const [ { externalWindow, externalContainer }, setWindowState ] = useState({});
	const dnd = useContext(DndContext);

	useEffect(() => {
		if (!open && externalWindow) {
			setWindowState({});
			externalWindow.close();
		} else if (open && externalWindow == null && dnd != null) {
			// console.log('Opening external window');
			const externalWindow = window.open('', '_blank', 'location=no,menubar=no,status=no,titlebar=no,toolbar=no');

			// Inject global style
			Array.from(document.head.getElementsByTagName('STYLE'))
				.filter(style => style.innerText.indexOf('\n/* sc-component-id: sc-global') != -1)
				.forEach(style => {
					externalWindow.document.head.appendChild(style.cloneNode(true));
				});

			const externalContainer = externalWindow.document.createElement('div');
			externalWindow.document.body.appendChild(externalContainer);
			externalWindow.addEventListener('beforeunload', () => {
				setWindowState({});
				if (typeof onClose === 'function') onClose();
			});
			window.addEventListener('beforeunload', () => {
				externalWindow.close();
			});
			copyStyles(window.document, externalWindow.document);
			dnd.dragDropManager.getBackend().addEventListeners(externalWindow);
			setWindowState({ externalWindow, externalContainer });
		}
	}, [ open, dnd ]);

	if (!open || !externalWindow || !externalContainer) {
		return <>{children}</>;
	} else {
		// return ReactDOM.createPortal(<WindowContext.Provider value={externalWindow}>
		return ReactDOM.createPortal(<StyleSheetManager target={externalWindow.document.head}>
			<>
				<div id="modal" />
				{children}
			</>
		</StyleSheetManager>, externalContainer);
		// </WindowContext.Provider>, externalContainer);
	}
}
