import { useCallback, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import Modal from '../../lib/ui/Modal';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { Button, Form, InputField, WithLabel } from '@atrocit/scl';

export default function EditRelationModal({ isOpen, onClose, relationId, refetch }) {
	const [ displayName, setDisplayName ] = useState("");
	const [ fullName, setFullName ] = useState("");
	const [ addressLine1, setAddressLine1 ] = useState("");
	const [ addressLine2, setAddressLine2 ] = useState("");
	const [ zipCode, setZipCode ] = useState("");
	const [ city, setCity ] = useState("");
	const [ country, setCountry ] = useState("");
	const [ companyId, setCompanyId ] = useState("");
	const [ euTaxId, setEuTaxId ] = useState("");

	const relationQuery = useQuery(gql`query Query($relationId: Int!) {
		relationById(relationId: $relationId) {
			id, displayName, fullName, addressLine1, addressLine2, zipCode, city, country, companyId, euTaxId,
		}
	}`, { variables: { relationId } });
	const r = relationQuery?.data?.relationById;

	useEffect(() => {
		if (r != null) {
			setDisplayName(r.displayName != null ? r.displayName : "");
			setFullName(r.fullName != null ? r.fullName : "");
			setAddressLine1(r.addressLine1 != null ? r.addressLine1 : "");
			setAddressLine2(r.addressLine2 != null ? r.addressLine2 : "");
			setZipCode(r.zipCode != null ? r.zipCode : "");
			setCity(r.city != null ? r.city : "");
			setCountry(r.country != null ? r.country : "");
			setCompanyId(r.companyId != null ? r.companyId : "");
			setEuTaxId(r.euTaxId != null ? r.euTaxId : "");
		}
	}, [ r ]);

	const [ editRelation, editRelationMutation ] = useMutation(gql`mutation Mutation($relationId: Int!, $relation: RelationPartialUpdate!) {
        editRelation(relationId: $relationId, relation: $relation) { id }
    }`);

	const process = useCallback(() => {
		const relation = { relationId: relationId, displayName: displayName, fullName: fullName, addressLine1: addressLine1, addressLine2: addressLine2, zipCode: zipCode, city: city, country: country, companyId: companyId, euTaxId: euTaxId };
		editRelation({ variables: { relationId: relationId, relation: relation } }).finally(() => { refetch(); onClose(); });
	}, [ displayName, fullName, addressLine1, addressLine2, zipCode, city, country, companyId, euTaxId ]);

	return <Modal isOpen={isOpen} onClose={onClose} title="Relatie bewerken">
		{!editRelationMutation.loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<WithLabel label="Weergavenaam">
				<InputField value={displayName} onChange={setDisplayName} />
			</WithLabel>
			<WithLabel label="Volledige naam">
				<InputField value={fullName} onChange={setFullName} />
			</WithLabel>
			<WithLabel label="Adresregel 1">
				<InputField value={addressLine1} onChange={setAddressLine1} />
			</WithLabel>
			<WithLabel label="Adresregel 2">
				<InputField value={addressLine2} onChange={setAddressLine2} />
			</WithLabel>
			<WithLabel label="Postcode">
				<InputField value={zipCode} onChange={setZipCode} />
			</WithLabel>
			<WithLabel label="Stad">
				<InputField value={city} onChange={setCity} />
			</WithLabel>
			<WithLabel label="Land">
				<InputField value={country} onChange={setCountry} />
			</WithLabel>
			<WithLabel label="KVK">
				<InputField value={companyId} onChange={setCompanyId} />
			</WithLabel>
			<WithLabel label="BTW-Nummer">
				<InputField value={euTaxId} onChange={setEuTaxId} />
			</WithLabel>
			<ButtonArea>
				<ValidatedButton
					type="submit"
					level="primary"
					validations={[
						{ valid: displayName.length > 0, error: "Weergavenaam mag niet leeg zijn" },
						{ valid: displayName.length < 200, error: "Weergavenaam moet kleiner zijn dan 200 karakters" },
						{ valid: fullName.length > 0, error: "Volledige naam mag niet leeg zijn" },
						{ valid: fullName.length < 200, error: "Volledige  moet kleiner zijn dan 200 karakters" },
						{ valid: (addressLine1 != null ? addressLine1.length < 200 : true) || (addressLine2 != null ? addressLine2 < 200 : true), error: "Adresregel moet kleiner zijn dan 200 karakters" },
						{ valid: zipCode != null ? zipCode.length < 50 : true, error: "Postcode moet kleiner zijn dan 50 karakters" },
						{ valid: city != null ? city.length < 150 : true, error: "Stad moet kleiner zijn dan 150 karakters" },
						{ valid: country != null ? country.length < 150 : true, error: "Land moet kleiner zijn dan 150 karakters" },
						{ valid: companyId != null ? companyId.length < 50 : true, error: "KVK moet kleiner zijn dan 50 karakters" },
						{ valid: euTaxId != null ? euTaxId.length < 50 : true, error: "BTW-Nummer moet kleiner zijn dan 50 karakters" },
					]}>
					<span className="fa fa-check" />&nbsp; Opslaan
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{editRelationMutation.loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}