import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import Breadcrumbs from '../../lib/Breadcrumbs';
import SearchField from '../../lib/forms/SearchField';
import LoadingRow from '../../lib/tables/LoadingRow';
import AddShipModal from './AddShipModal';
import EditShipModal from './EditShipModal';
import DeleteShipModal from './DeleteShipModal';
import { Button, DropdownButton } from '@atrocit/scl';
import PageTop from '../../lib/ui/PageTop';

export default function ShipOverview() {
	const [ query, setQuery ] = useState('');
	const [ showDeleteShipModal, setShowDeleteShipModal ] = useState(false);
	const [ deleteShipId, setDeleteShipId ] = useState(null);
	const [ showAddShipModal, setShowAddShipModal ] = useState(false);
	const [ showEditShipModal, setShowEditShipModal ] = useState(false);
	const [ editShipId, setEditShipId ] = useState(null);

	const backendQuery = useQuery(gql`{
        ships {
            id,
	        name,
	        eniIdentifier,
	        teuCapacity,
	        weightCapacity
        }
    }`);
	const ships = (backendQuery?.data?.ships ?? []);

	const results = useMemo(() => {
		if (query == '') return ships;

		return ships.filter(s => s.name.toLowerCase().includes(query.toLowerCase()) || s.eniIdentifier.toLowerCase().includes(query.toLowerCase()) || s.teuCapacity.toString().toLowerCase().includes(query.toLowerCase()) || s.weightCapacity.toString().toLowerCase().includes(query.toLowerCase()));
	}, [ query, ships ]);

	return <div className="page">
		{showDeleteShipModal && <DeleteShipModal isOpen={showDeleteShipModal} onClose={() => setShowDeleteShipModal(false)} shipId={deleteShipId} refetch={() => backendQuery.refetch()} />}
		{showAddShipModal && <AddShipModal isOpen={showAddShipModal} onClose={() => setShowAddShipModal(false)} refetch={() => backendQuery.refetch()} />}
		{showEditShipModal && <EditShipModal isOpen={showEditShipModal} onClose={() => setShowEditShipModal(false)} refetch={() => backendQuery.refetch()} shipId={editShipId} />}

		<PageTop
			breadcrumbSegments={[
				{ link: '/ships', label: 'Schepen' },
				{ link: '/ships', label: 'Overzicht' },
			]}>
			<Button onClick={() => setShowAddShipModal(true)}><span className="fa fa-plus" />&nbsp; Nieuw schip</Button>
		</PageTop>

		<SearchField
			onChange={setQuery}
			value={query}
			hasResults={results.length != 0} />

		<table className="table table-fw">
			<thead>
				<tr>
					<th>Naam</th>
					<th width="100">ENI</th>
					<th className="tbl-align-right" width="125">TEU capaciteit</th>
					<th className="tbl-align-right" width="125">Gew. capaciteit (ton)</th>
					<th width="165" />
				</tr>
			</thead>
			<tbody>
				{backendQuery.loading && <LoadingRow />}
				{(results || []).map(s => <tr key={s.id}>
					<td title={s.name}>{s.name}</td>
					<td>{s.eniIdentifier}</td>
					<td className="tbl-align-right">{s.teuCapacity}</td>
					<td className="tbl-align-right">{s.weightCapacity}</td>
					<td style={{ display: "flex", justifyContent: "space-around", gap: "var(--u-16)" }}>
						<DropdownButton mainAction={<Button onClick={() => { setEditShipId(s.id); setShowEditShipModal(true); }}><span className="fa fa-pencil" />&nbsp; Bewerken</Button>}>
							<Button className="danger" onClick={() => { setDeleteShipId(s.id); setShowDeleteShipModal(true); }}><span className="fa fa-trash-o" />&nbsp; Verwijderen</Button>
						</DropdownButton>
					</td>
				</tr>)}
				{!backendQuery.loading && results.length == 0 && <tr>
					<td colSpan={100}>Geen resultaten</td>
				</tr>}
			</tbody>
		</table>
	</div>;
}