import SideBar from './nav/SideBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CallOverview from './calls/CallOverview';
import UserContext from '../context/UserContext';
import AuthRefresh from '../context/AuthRefresh';
import { useContext } from 'react';
import UseMagicLink from './auth/UseMagicLink';
import Login from './auth/Login';
import atrocitLogo from '../assets/atrocit-logo-1024.png';
// import loginBackgroundPhoto from '../assets/tma.jpg';
import loginBackgroundPhoto from '../assets/westford.jpg';
import Dashboard from './dashboard/Dashboard';
import BookingOverview from './bookings/BookingOverview';
import RelationOverview from './masterdata/relations/RelationOverview';
import ShipOverview from './masterdata/ships/ShipOverview';
import TerminalOverview from './masterdata/terminals/TerminalOverview';
import ContainerTypeOverview from './masterdata/containertypes/ContainerTypeOverview';
import ViewBooking from './bookings/ViewBooking';
import UsersOverview from './users/UsersOverview';
import CreateBooking from './bookings/CreateBooking';
import AddEditUser from "./users/AddEditUser";
import EditPassword from "./users/EditPassword";
import ConnectionsOverview from './connections/ConnectionsOverview';
import ViewConnection from './connections/ViewConnection';

export default function RoutesInternal() {
	const user = useContext(UserContext);
	const refreshAuth = useContext(AuthRefresh);

	return <Router>
		{user == null && <div className="auth" style={{ position: 'relative' }}>
			<div style={{ position: 'fixed', inset: 0, overflow: 'hidden' }}>
				<div className="top-graphic" style={{ filter: 'grayscale(50%)' }}>
					<div style={{ background: 'url(' + loginBackgroundPhoto + ') #C41E3D', width: '100%', minHeight: '100vh', height: '100%', backgroundSize: 'cover', backgroundPosition: 'center center', opacity: .35, filter: 'grayscale(90%) blur(2px)', backgroundBlendMode: 'hard-light' }} />
				</div>

				<div className="bg-graphic" style={{ position: 'absolute', background: '#C41E3D', opacity: 1, height: '100vh', top: '55vh', width: '150vw', zIndex: 0, transform: 'rotate(-7deg)' }} />

				{/* <div className="bg-graphic" style={{ position: 'absolute', background: '#C41E3D', opacity: 1, height: '100vh', top: '80vh', width: '150vw', zIndex: 0, transform: 'rotate(-7deg)', mixBlendMode: 'overlay' }} />*/}
				{/* <div className="bg-graphic" style={{ position: 'absolute', background: '#C41E3D', opacity: 1, height: '40vh', top: '-30vh', left: '-10vw', width: '150vw', zIndex: 0, transform: 'rotate(-7deg)', mixBlendMode: 'overlay' }} />*/}
			</div>

			<div className="login-logo" style={{ paddingRight: '24px', paddingBottom: '8px', position: 'relative', zIndex: 10 }}>
				<img src={atrocitLogo} alt="Atrocit Logo" className="atrocit-logo" />
			</div>
			{/* <h1 style={{ fontWeight: 'normal', padding: 0, margin: 0, color: '#C41E3D', marginTop: '-16px', marginBottom: '16px', zIndex: 10, position: 'relative' }}>Nautilus</h1>*/}
			<div className="login-frame" style={{ position: 'relative', zIndex: 10 }}>
				<Routes>
					<Route element={<UseMagicLink refreshAuth={refreshAuth} />} path="/magiclink/:token" />
					<Route element={<Login />} path="*" />
				</Routes>
			</div>
		</div>}
		{user != null && <div className="app">
			<SideBar />
			<div className="app-content">
				<Routes>
					<Route element={<Dashboard />} path="/dashboard" />
					<Route element={<BookingOverview />} path="/bookings" />
					<Route element={<ViewBooking />} path="/bookings/:id" />
					<Route element={<CreateBooking />} path="/bookings/new" />
					<Route element={<CallOverview />} path="/calls" />
					<Route element={<RelationOverview />} path="/masterdata/relations" />
					<Route element={<ShipOverview />} path="/masterdata/ships" />
					<Route element={<TerminalOverview />} path="/masterdata/terminals" />
					<Route element={<ContainerTypeOverview />} path="/masterdata/containertypes" />
					<Route element={<UsersOverview />} path="/masterdata/users" />
					<Route element={<AddEditUser />} path='/masterdata/users/new'/>
					<Route element={<AddEditUser />} path="/masterdata/users/:id" />
					<Route element={<EditPassword/>} path="/masterdata/users/:id/password"/>
					<Route element={<ConnectionsOverview />} path="/connections" />
					<Route element={<ViewConnection />} path="/connections/:id" />
				</Routes>
			</div>
		</div>}
	</Router>;
}