import Breadcrumbs from '../lib/Breadcrumbs';
import React, { useCallback, useState } from 'react';
import { Form, InputField, WithLabel } from '@atrocit/scl';
import ButtonArea from '../lib/forms/ButtonArea';
import CustomerSelectField from './CustomerSelectField';
import ContainerTypeSelectField from './ContainerTypeSelectField';
import TerminalSelectField from '../lib/selectfields/TerminalSelectField';
import { gql, useMutation } from '@apollo/client';
import ValidatedButton from '../lib/forms/ValidatedButton';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';


export default function CreateBooking() {
	const { pickupTerminal, dropoffTerminal } = useParams();

	const navigate = useNavigate();
	const [ customerId, setCustomerId ] = useState(null);
	const [ referenceNumber, setReferenceNumber ] = useState(null);
	const [ pickupTerminalId, setPickupTerminalId ] = useState(pickupTerminal);
	const [ dropoffTerminalId, setDropoffTerminalId ] = useState(dropoffTerminal);
	const [ containerType, setContainerType ] = useState(null);
	const [ amountOfTransports, setAmountOfTransports ] = useState(0);

	const [ createBooking, createBookingMutation ] = useMutation(gql`mutation Mutation($customerId: Int!, $pickupTerminalId: Int!, $dropoffTerminalId: Int!, $containerId: Int!, $amountOfTransports: Int!, $referenceNumber: String!) {
        createBooking(customerId: $customerId, pickupTerminalId: $pickupTerminalId, dropoffTerminalId: $dropoffTerminalId, containerId: $containerId, amountOfTransports: $amountOfTransports, referenceNumber: $referenceNumber) { id }
    }`);


	const process = useCallback(() => {
		createBooking({ variables: { customerId: customerId, pickupTerminalId: pickupTerminalId, dropoffTerminalId: dropoffTerminalId, containerId: containerType, amountOfTransports: amountOfTransports, referenceNumber: referenceNumber } }).then(r => { navigate('/bookings/' + r.data.createBooking.id); });
	}, [ customerId, pickupTerminalId, dropoffTerminalId, containerType, amountOfTransports, referenceNumber ]);

	return <div className="page">
		<Breadcrumbs
			segments={[
				{ link: '/bookings', label: 'Boekingen' },
				{ link: '/bookings/new', label: 'Nieuwe boeking' },
			]} />

		<h2 className="booking_bold_text" style={{ color: "var(--col-primary-500)", fontWeight: "900" }}>Boeking maken</h2>

		<br/>

		<div style={{ maxWidth: 'var(--u-768)' }}>
			{!createBookingMutation.loading && <Form onSubmit={process}>
				<div className="col-2" style={{ columnGap: "var(--u-48)", rowGap: "var(--u-8)" }}>
					<div style={{ gridColumn: '1/3' }}>
						<WithLabel label="Klant">
							<CustomerSelectField onChange={id => setCustomerId(id)} value={customerId}/>
						</WithLabel>
						<WithLabel label="Referentienummer">
							<InputField onChange={referenceNumber => setReferenceNumber(referenceNumber)} value={referenceNumber}/>
						</WithLabel>
					</div>
					<WithLabel label="Van terminal" style={{ gridColumn: '1/2' }}>
						<TerminalSelectField onChange={id => setPickupTerminalId(id)} value={pickupTerminalId}/>
					</WithLabel>
					<WithLabel label="Naar terminal" style={{ gridColumn: '1/2' }}>
						<TerminalSelectField onChange={id => setDropoffTerminalId(id)} value={dropoffTerminalId}/>
					</WithLabel>
					<WithLabel label="Aantal" style={{ gridColumn: '2/3' }}>
						<input className="scl-form-element"
						       type="number"
						       onChange={event => setAmountOfTransports(event.target.value)}
						       value={amountOfTransports}/>
					</WithLabel>
					<WithLabel label="Type container" style={{ gridColumn: '1/2' }}>
						<ContainerTypeSelectField onChange={id => setContainerType(id)} value={containerType}/>
					</WithLabel>
				</div>

				<ButtonArea>
					<ValidatedButton level="primary" type="submit" validations={[
						{ valid: customerId != null, error: "Een klant is nodig" },
						{ valid: pickupTerminalId != null, error: "Een van terminal is nodig" },
						{ valid: dropoffTerminalId != null, error: "Een naar terminal is nodig" },
						{ valid: containerType != null, error: "Een containertype is nodig" },
						{ valid: amountOfTransports != null && amountOfTransports > 0, error: "Een aantal is nodig" },
						{ valid: referenceNumber != null, error: "Een referentienummer is nodig" },
					]}>
						<span className="fa fa-check"/>&nbsp;Opslaan</ValidatedButton>
				</ButtonArea>
			</Form>}
			{createBookingMutation.loading && <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<span className="fa fa-spinner fa-pulse" style={{ fontSize: "var(--fs-18)" }}/>
			</div>}
		</div>
	</div>;
}