import TimeShow from '../../lib/TimeShow';
import { Link } from 'react-router-dom';
import BlurredZeroValue from '../../lib/tables/BlurredZeroValue';
import FullEmptyIndicator from '../../lib/FullEmptyIndicator';
import { useDrag } from 'react-dnd';
import React, { useEffect, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import Checkbox from '../../lib/ui/Checkbox';
import CopyToClipboardText from '../../lib/CopyToClipboardText';
import BooleanIndicator from '../../lib/tables/BooleanIndicator';
import DisplayTemperature from '../../lib/DisplayTemperature';
import { useHotkeys } from 'react-hotkeys-hook';
import { createDragPreview } from 'react-dnd-text-dragpreview';


export function sum(arr) {
	let s = 0;
	for (let i = 0; i < arr.length; i++) {
		s += arr[i];
	}
	return s;
}

function createCmpDossiers(terminals, isLoad) {
	return (a, b) => {
		const aTerminal = parseInt(terminals[isLoad ? a.unloadTerminalId : a.loadTerminalId]?.displayName.replace(/\D/g, ''), 10) || terminals[isLoad ? a.unloadTerminalId : a.loadTerminalId]?.displayName;
		const bTerminal = parseInt(terminals[isLoad ? b.unloadTerminalId : b.loadTerminalId]?.displayName.replace(/\D/g, ''), 10) || terminals[isLoad ? b.unloadTerminalId : b.loadTerminalId]?.displayName;

		if (typeof aTerminal == 'number' && typeof bTerminal != 'number') {
			return -1;
		} else if (typeof aTerminal != 'number' && typeof bTerminal == 'number') {
			return 1;
		} else if (aTerminal < bTerminal) {
			return -1;
		} else if (aTerminal > bTerminal) {
			return 1;
		} else if (a.customer < b.customer) {
			return -1;
		} else if (a.customer > b.customer) {
			return 1;
		} else if (a.reference < b.reference) {
			return -1;
		} else if (a.reference > b.reference) {
			return 1;
		} else {
			return 0;
		}
	};
}

export default function BookingView({ call, terminals, isLoad = false, time, selectedCts, setSelectedCts }) {
	const dossiers = isLoad ? call.loadList : call.unloadList;
	const [ startIdx, setStartIdx ] = useState(null);

	const allCtIds = dossiers.map(d => d.ctIds.split(',').map(ctId => Number(ctId))).reduce((tot, list) => tot.concat(list), []);
	const selectedCtsToList = [ ...selectedCts ].reduce((tot, list) => tot.concat(list), []);

	useHotkeys('esc', event => {
		event.preventDefault();
		setStartIdx(null);
		setSelectedCts(new Set());
	}, []);

	useEffect(() => {
		if(selectedCts.size == 0) setStartIdx(null);
	}, [ selectedCts ]);

	return <table className="table table-single-line" style={{ minWidth: '1000px', fontSize: '13px' }}>
		<colgroup>
			<col width="28" />
			<col width="50" />
			<col width="40" />
			<col width="60" />

			<col width="30" />{/* 20ft x2 */}
			<col width="30" />
			<col width="30" />
			<col width="30" />
			<col width="30" />
			<col width="30" />
			<col width="30" />{/* tot. */}
			<col width="30" />

			<col width="50" />
			<col width="25" />

			<col width="30" />
			<col width="30" />
			<col width="30" />

			<col width="100" />
			<col width="100" />

			<col width="50" />
		</colgroup>
		<thead>
			<tr>
				<th className="tbl-center">
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Checkbox onChange={() => (selectedCts.size == 0 ? setSelectedCts(new Set(dossiers.map(d => d.ctIds.split(',').map(ctId => Number(ctId))).reduce((tot, el) => tot.concat(el), []))) : setSelectedCts(new Set()))}
						          value={selectedCtsToList.length == allCtIds.length}
						          indeterminate={!(selectedCtsToList.length == 0 || selectedCtsToList.length == allCtIds.length)} />
					</div>
				</th>
				<th>{isLoad ? 'Naar' : 'Van'}</th>
				<th>Boek.nr</th>
				<th>Klant</th>
				<th colSpan="2">20ft</th>
				<th colSpan="2">40ft</th>
				<th colSpan="2">? ft</th>
				<th>Tot.</th>
				<th>TEU</th>
				<th>Gew.</th>
				<th className="tbl-center">V/L</th>
				<th>#DG</th>
				<th>#OOG</th>
				<th>#RF</th>
				<th>Pickup window</th>
				<th>Dropoff window</th>
				<th>Status</th>
			</tr>
		</thead>
		<tbody>
			{isLoad ? call.loadList.sort(createCmpDossiers(terminals, isLoad)).map((c, idx) => {
				const currentBkCtIds = c.ctIds.split(',').map(ctId => Number(ctId));
				const selected = currentBkCtIds.every(ctId => selectedCts.has(ctId));
				const currentBkCtIdsSet = new Set(currentBkCtIds);
				return <BookingRow key={idx} terminals={terminals} time={time} c={c} selected={selected} selectedCts={selectedCts} setSelectedCts={setSelectedCts} loading={isLoad}
					onSelect={({ deselect, ctrlCmd, shift }) => {
						if (deselect) {
							setSelectedCts(new Set([ ...[ ...selectedCts ].filter(ctId => !currentBkCtIdsSet.has(ctId)) ]));
						} else if (ctrlCmd && shift && currentBkCtIds.every(ctId => selectedCts.has(ctId))) {
							setSelectedCts(new Set([ ...[ ...selectedCts ].filter(ctId => !currentBkCtIdsSet.has(ctId)) ]));
						} else if (ctrlCmd && shift) {
							setStartIdx(idx);
							setSelectedCts(new Set([ ...selectedCts, ...currentBkCtIdsSet ]));
						} else if (shift && startIdx == null) {
							setStartIdx(idx);
							setSelectedCts(new Set([ ...currentBkCtIdsSet ]));
						} else if (shift && startIdx != null) {
							const range = [];
							for (let i = Math.min(idx, startIdx); i <= Math.max(idx, startIdx); i++) {
								range.push(dossiers[i].ctIds.split(',').map(ctId => Number(ctId)));
							}
							setSelectedCts(new Set(range.reduce((tot, el) => tot.concat(el), [])));
						}
					}}/>;
			}) : call.unloadList.sort(createCmpDossiers(terminals, isLoad)).map((c, idx) => {
				const currentBkCtIds = c.ctIds.split(',').map(ctId => Number(ctId));
				const selected = currentBkCtIds.every(ctId => selectedCts.has(ctId));
				const currentBkCtIdsSet = new Set(currentBkCtIds);
				return <BookingRow key={idx} terminals={terminals} time={time} c={c} selected={selected} selectedCts={selectedCts} setSelectedCts={setSelectedCts} loading={isLoad}
					onSelect={({ deselect, ctrlCmd, shift }) => {
	                   if (deselect) {
		                   setSelectedCts(new Set([ ...[ ...selectedCts ].filter(ctId => !currentBkCtIdsSet.has(ctId)) ]));
	                   } else if (ctrlCmd && shift && currentBkCtIds.every(ctId => selectedCts.has(ctId))) {
		                   setSelectedCts(new Set([ ...[ ...selectedCts ].filter(ctId => !currentBkCtIdsSet.has(ctId)) ]));
	                   } else if (ctrlCmd && shift) {
		                   setStartIdx(idx);
		                   setSelectedCts(new Set([ ...selectedCts, ...currentBkCtIdsSet ]));
	                   } else if (shift && startIdx == null) {
		                   setStartIdx(idx);
		                   setSelectedCts(new Set([ ...currentBkCtIdsSet ]));
	                   } else if (shift && startIdx != null) {
		                   const range = [];
		                   for (let i = Math.min(idx, startIdx); i <= Math.max(idx, startIdx); i++) {
			                   range.push(dossiers[i].ctIds.split(',').map(ctId => Number(ctId)));
		                   }
		                   setSelectedCts(new Set(range.reduce((tot, el) => tot.concat(el), [])));
	                   }
					}}/>;
			})}
		</tbody>
		<tfoot>
			<tr>
				<td colSpan="4" />
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => d.twentyFtEmpty))} /></td>
				<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={sum(dossiers.map(d => d.twentyFtFull))} /></td>
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => d.fortyFtEmpty))} /></td>
				<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={sum(dossiers.map(d => d.fortyFtFull))} /></td>
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => d.otherFtEmpty))} /></td>
				<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={sum(dossiers.map(d => d.otherFtFull))} /></td>
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => d.totalContainers))} /></td>
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => d.totalTeu))} /></td>
				<td className="tbl-align-right"><BlurredZeroValue value={Math.round(sum(dossiers.map(d => d.weight)) / 1000)} /> t</td>
				<td></td>
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => d.totalDangerous))} /></td>
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => d.totalOog))} /></td>
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => d.totalReefer))} /></td>
				<td colSpan={2} />
				<td className="tbl-align-right"><BlurredZeroValue value={sum(dossiers.map(d => (isLoad ? d.amountLoaded : d.amountUnloaded)))} /> / <BlurredZeroValue value={sum(dossiers.map(d => d.totalContainers))} /></td>
			</tr>
		</tfoot>
	</table>;
}

function CappedTd({ maxWidth, value, coloredBackground }) {
	return <td style={{ background: coloredBackground, width: maxWidth }}>
		<div style={{ overflow: 'clip', width: '100%', maxWidth: maxWidth, textOverflow: 'clip', whiteSpace: 'nowrap', color: computeTextColor(coloredBackground) }}>
			<abbr className="abbr-hidden" title={value} >{value}</abbr>
		</div>
	</td>;
}

export function computeTextColor(coloredBackground) {
	if(coloredBackground == null) return;
	const rgb = hexToRgb(coloredBackground);
	if ((rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) > 150) {
		return "rgba(0, 0, 0, 0.8)";
	} else {
		return "rgba(255, 255, 255, 0.9)";
	}
}

function hexToRgb(hex) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16),
	} : null;
}

function DossierProgressColumn({ dossier, loading }) {
	const d = dossier;
	const status = loading
		? d.amountLoaded > 0 && d.amountLoaded < d.totalContainers ? 'IN_PROGRESS' : (d.amountLoaded == 0 ? 'NOT_STARTED' : 'FINISHED')
		: d.amountUnloaded > 0 && d.amountUnloaded < d.totalContainers ? 'IN_PROGRESS' : (d.amountUnloaded == 0 ? 'NOT_STARTED' : 'FINISHED');

	if (status == 'IN_PROGRESS') {
		return <>
			<div style={{ position: 'absolute', inset: 0, boxShadow: '0 0 4px inset var(--col-orange-500)' }} />
			<BlurredZeroValue value={loading ? d.amountLoaded : d.amountUnloaded}/> / <BlurredZeroValue value={d.totalContainers} />
		</>;
	} else if (status == 'NOT_STARTED') {
		return <span className="fa fa-clock-o" style={{ color: 'var(--col-grey-500)' }}/>;
	} else {
		return <span className="fa fa-check-circle" style={{ color: 'var(--col-green-500)' }}/>;
	}
}

function BookingRow({ terminals, time, c, selectedCts, setSelectedCts, selected, loading, onSelect }) {
	const ctIds = c.ctIds.split(',').map(ctId => Number(ctId));
	const intersect = ctIds.filter(id => selectedCts.has(id));
	const draggable = intersect.length > 0 || selectedCts.size == 0;

	const [ collected, drag, preview ] = useDrag(() => ({
		type: 'PLANNED_BOOKING',
		canDrag: draggable,
		item: selectedCts.size == 0 ? ctIds : [ ...selectedCts ],
	}), [ selectedCts ]);

	const [ expanded, setExpanded ] = useState(false);
	const indeterminate = !(intersect.length == 0 || intersect.length == ctIds.length);

	preview(createDragPreview(''));

	return <>
		<tr
			ref={drag}
			className={expanded ? 'tbl-row-expanded' : ''}
			onClick={e => {
				if (e.shiftKey) {
					e.preventDefault();
					e.stopPropagation();
					onSelect({ shift: e.shiftKey, alt: e.altKey, ctrlCmd: e.ctrlKey || e.metaKey });
				}
			}}
			onMouseDown={e => {
				if (e.ctrlKey || e.metaKey || e.shiftKey) e.preventDefault();
			}}
			onDoubleClick={e => {
				e.preventDefault();
				e.stopPropagation();
				if (!e.shiftKey) {
					setExpanded(!expanded);
				}
			}}>
			<td className="tbl-center">
				{(selected || indeterminate) && <div style={{ display: 'flex', justifyContent: 'center' }}>
					<Checkbox onChange={() => onSelect({ deselect: true })} value={selected} indeterminate={indeterminate} />
				</div>}
			</td>
			<td style={{ background: terminals[c.unloadTerminalId]?.colorhex }}>{loading ? terminals[c.unloadTerminalId]?.displayName : terminals[c.loadTerminalId]?.displayName}</td>
			<td className="tbl-align-right"><Link target="_blank" to={'/bookings/' + c.bookingId}>{c.bookingNr}</Link></td>
			<td>{c.customer}</td>
			<td className="tbl-align-right"><BlurredZeroValue value={c.twentyFtEmpty} /></td>
			<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={c.twentyFtFull} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={c.fortyFtEmpty} /></td>
			<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={c.fortyFtFull} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={c.otherFtEmpty} /></td>
			<td className="tbl-align-right tbl-inverted"><BlurredZeroValue value={c.otherFtFull} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={c.totalContainers} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={c.totalTeu} /></td>
			<td className="tbl-align-right">{Math.round(c.weight / 1000)} t</td>
			<td className="tbl-center tbl-fit"><FullEmptyIndicator value={c.fullEmptyStatus == "FULL"} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={c.totalDangerous} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={c.totalOog} /></td>
			<td className="tbl-align-right"><BlurredZeroValue value={c.totalReefer} /></td>
			<td style={{ verticalAlign: 'bottom' }}><TimeShow value={c.earliestPickup} time={time} compressedTime={true} /></td>
			<td style={{ verticalAlign: 'bottom' }}><TimeShow value={c.latestDelivery} time={time} compressedTime={true} /></td>
			<td className="tbl-align-right" style={{ position: 'relative' }}>
				<DossierProgressColumn dossier={c} loading={loading} />
			</td>
		</tr>
		{expanded && <tr className="tbl-row-expansion no-tr-hover">
			<td colSpan="20" style={{ position: 'relative' }}>
				<div className="fake-subtree-indicator" />
				<div style={{ padding: '8px 28px 16px 28px' }}>
					<BookingSubTable
						ctIds={ctIds}
						selected={selected}
						selectedCts={selectedCts}
						setSelectedCts={setSelectedCts}
						intersect={intersect} />
				</div>
			</td>
		</tr>}
		{expanded && <tr style={{ height: 0 }}></tr>}{/* Dummy invisible table row to make automatic striped coloring work correctly again */}
	</>;
}

function BookingSubTable({ ctIds, selected, selectedCts, setSelectedCts, intersect }) {
	const containerTransportsQuery = useQuery(gql`query Query($ctIds: [ Int! ]!) {
        containerTransportsByIds(ctIds: $ctIds) {
            id, containerNumber,
            booking {
                id, bookingNumber,
            },
            containerType {
                isoCode, isReefer, teu, displayName,
            },
            pickupTerminal {
                displayName,
            },
            dropoffTerminal {
                displayName,
            },
            full, grossWeight, activeReefer, reeferTemperature, remark, isVgm, overLengthBack, overLengthFront, overWidthRight, overWidthLeft, overHeight,
        }
    }`, { variables: { ctIds } });

	const cts = useMemo(() => {
		return containerTransportsQuery?.data?.containerTransportsByIds ?? [];
	}, [ containerTransportsQuery?.data?.containerTransportsByIds ]);

	const ctIdsSet = new Set(ctIds);

	return <table className="table table-fw" style={{ display: 'inline-table' }}>
		<colgroup>
			<col width="30" />

			<col width="100" />
			<col width="90" />
			<col width="50" />
			<col width="*" />

			{/* Van/naar */}
			<col width="50" />
			<col width="50" />

			{/* Weight etc. */}
			<col width="80" />
			<col width="30" />
			<col width="30" />
			<col width="60" />

			{/* Oversizes */}
			<col width="60" />
			<col width="60" />
			<col width="60" />
			<col width="60" />
			<col width="60" />
		</colgroup>
		<thead>
			<tr>
				<th className="tbl-center">
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Checkbox value={selected}
					          onChange={() => (intersect.length == 0 ? setSelectedCts(new Set([ ...selectedCts, ...ctIdsSet ])) : setSelectedCts(new Set([ ...selectedCts ].filter(id => !ctIdsSet.has(id)))))}
					          indeterminate={!(intersect.length == 0 || intersect.length == ctIds.length)} />
					</div>
				</th>{/* Selection */}
				<th>Cont.nr</th>
				<th>Boeking</th>
				<th>Type</th>
				<th>Opmerking</th>
				<th>Van</th>
				<th>Naar</th>
				<th>Gew.</th>
				<th>V/L</th>
				<th>VGM</th>
				<th>Temp</th>
				<th>OL V</th>
				<th>OL A</th>
				<th>OB L</th>
				<th>OB R</th>
				<th>OH</th>
			</tr>
		</thead>
		<tbody>
			{containerTransportsQuery.loading && <tr>
				<td colSpan={16}>
					Containertransporten laden...
				</td>
			</tr>}
			{cts.map((ct, idx) => <BookingSubTableRow ct={ct} cts={cts} idx={idx} selectedCts={selectedCts} setSelectedCts={setSelectedCts} />)}
		</tbody>
	</table>;
}

function BookingSubTableRow({ ct, cts, idx, selectedCts, setSelectedCts }) {
	const ctId = Number(ct.id);
	const draggable = selectedCts.has(ctId) || selectedCts.size == 0;

	const [ collected, drag, dragpreview ] = useDrag(() => ({
		type: 'PLANNED_BOOKING',
		canDrag: draggable,
		item: selectedCts.size == 0 ? [ ctId ] : [ ...selectedCts ],
	}), [ selectedCts ]);

	const [ innerStartIdx, setInnerStartIdx ] = useState(null);

	function handleCtSelection({ deselect, ctrlCmd, shift, idx }) {
		if (deselect) {
			setSelectedCts(new Set([ ...[ ...selectedCts ].filter(id => id != ctId) ]));
		} else if (ctrlCmd && shift && selectedCts.has(ctId)) {
			setSelectedCts(new Set([ ...[ ...selectedCts ].filter(id => id != ctId) ]));
		} else if (ctrlCmd && shift) {
			setInnerStartIdx(idx);
			setSelectedCts(new Set([ ...selectedCts, ctId ]));
		} else if (shift && innerStartIdx == null) {
			setInnerStartIdx(idx);
			setSelectedCts(new Set([ ...selectedCts, ctId ]));
		} else if (shift && innerStartIdx != null) {
			const range = [];
			for (let i = Math.min(idx, innerStartIdx); i <= Math.max(idx, innerStartIdx); i++) {
				range.push(Number(cts[i].id));
			}
			setSelectedCts(new Set(range.reduce((tot, el) => tot.concat(el), [])));
		}
	}

	return <tr
		ref={drag}
		key={ct.id}
		onClick={e => {
			if (e.shiftKey) {
				e.preventDefault();
				e.stopPropagation();
				handleCtSelection({ shift: e.shiftKey, alt: e.altKey, ctrlCmd: e.ctrlKey || e.metaKey, idx: idx });
			}
		}}
		onMouseDown={e => {
			if (e.ctrlKey || e.metaKey || e.shiftKey) e.preventDefault();
		}}>
		<td className="tbl-center">
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				{selectedCts.has(ctId) && <Checkbox onChange={() => handleCtSelection({ deselect: true })} value={selectedCts.has(ctId)} />}
			</div>
		</td>
		<td><CopyToClipboardText value={ct.containerNumber} /></td>
		<td><Link target="_blank" to={'/bookings/' + ct.booking.id}>{ct.booking.bookingNumber}-{idx + 1}</Link></td>
		<td>{ct.containerType?.displayName ?? '-'}</td>
		<td>{ct.remark ?? '-'}</td>
		<td>{ct.pickupTerminal?.displayName ?? '-'}</td>
		<td>{ct.dropoffTerminal?.displayName ?? '-'}</td>
		<td className="tbl-align-right">{ct.grossWeight ?? 0} kgs</td>
		<td className="tbl-center"><FullEmptyIndicator value={ct.full} /></td>
		<td className="tbl-center"><BooleanIndicator value={ct.isVgm} /></td>
		<td className="tbl-align-right">{ct.activeReefer ? <DisplayTemperature value={ct.reeferTemperature} /> : ''}</td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overLengthFront ?? 0} /></td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overLengthBack ?? 0} /></td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overWidthLeft ?? 0} /></td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overWidthRight ?? 0} /></td>
		<td className="tbl-align-right"><BlurredZeroValue value={ct.overHeight ?? 0} /></td>
	</tr>;
}