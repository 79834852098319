import { gql, useQuery } from '@apollo/client';
import Select from 'react-select';
import { useCallback, useMemo } from 'react';

export default function TerminalSelectField({ onChange, value, placeholder = '', ...props }) {
	const terminalsQuery = useQuery(gql`query {
		terminals {
			id, displayName
		}
	}`);

	const options = useMemo(() => {
		return (terminalsQuery?.data?.terminals ?? []).map(t => ({
			value: t.id,
			label: t.displayName,
			original: t,
		}));
	}, [ terminalsQuery?.data?.terminals ]);

	const onChangeCb = useCallback((obj) => {
		onChange(obj?.value ?? null, obj?.original);
	}, [ onChange ]);

	return <Select
		{...props}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={terminalsQuery.loading}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		value={options.find(o => o.value == value)} />;
}